import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Upload, Card } from 'antd';
import PropTypes from 'prop-types';
import { PictureOutlined } from '@ant-design/icons';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useFormationsContext } from './FormationsContext';

const { Dragger } = Upload;
const { TextArea } = Input;

export const ChallengeCard = ({ fieldName, chapterType, challengeIndex }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [fileList, setFileList] = useState([]);
  const { images } = useFormationsContext();

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };
  return (
    <Card title={`Challenge ${challengeIndex}`} className="challenge-card">
      <Form.Item name={[...fieldName, 'title']} label={t('chapter.form.title')}>
        <Input />
      </Form.Item>
      <Form.Item
        name={[...fieldName, 'description']}
        label={t('chapter.form.challenge_description')}
      >
        <TextArea />
      </Form.Item>
      {chapterType !== 'CHALLENGE_RETURN' && (
        <Form.Item
          name={[...fieldName, 'image']}
          label={t('formations.form.image')}
        >
          <Dragger {...draggerProps}>
            {images.challenges ? (
              <img
                src={images.challenges[challengeIndex - 1]}
                alt="imageFormation"
                style={{ width: '60%' }}
              />
            ) : (
              <>
                <p className="ant-upload-drag-icon">
                  <PictureOutlined style={{ color: 'var(--textColor)' }} />
                </p>
                <p className="ant-upload-text">{t('files.create.action')}</p>
              </>
            )}
          </Dragger>
        </Form.Item>
      )}
    </Card>
  );
};

ChallengeCard.propTypes = {
  fieldName: PropTypes.arrayOf('').isRequired,
  chapterType: PropTypes.string.isRequired,
  challengeIndex: PropTypes.number.isRequired
};
