import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const SessionsContext = createContext({ isValid: false });

export const SessionsContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { token, dispatchAPI, user } = useAuthContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [formations, setFormations] = useState([]);
  const [contributors, setContributors] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [userSessions, setUserSessions] = useState([]);
  const [users, setUsers] = useState([]);
  const [traineesFilter, setTraineesFilter] = useState();
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');

  const getPotentialSubscribers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?role=users:TRAINEE'
      });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  };

  const getContributors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?role=users:CONTRIBUTOR'
      });
      setContributors(data);
    } catch (e) {
      message(e);
    }
  };

  const getSessions = async () => {
    try {
      const searchURL = keyword ? `/search/${keyword}` : '';
      const { data } = await dispatchAPI('GET', {
        url: `/sessions${searchURL}?populate=formation,contributor,subscribers.user`
      });
      setSessions(data);
    } catch (e) {
      message(e);
    }
  };

  const getUserSessions = async () => {
    try {
      const searchURL = keyword ? `/search/${keyword}` : '';
      const { data } = await dispatchAPI('GET', {
        url: `/sessions${searchURL}?contributor=${user._id}${
          traineesFilter ? `&subscribers.user=${traineesFilter}` : ''
        }&populate=formation,contributor,subscribers.user`
      });
      setUserSessions(data);
    } catch (e) {
      message(e);
    }
  };

  const getFormations = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/formations' });
      setFormations(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (token) {
      (async () => {
        await getSessions();
        await getPotentialSubscribers();
        await getFormations();
        await getContributors();
      })();
    }
  }, [token, forceRefresh, keyword]);

  useEffect(() => {
    (async () => {
      if (token && user?.role === 'users:CONTRIBUTOR') await getUserSessions();
    })();
  }, [forceRefresh, traineesFilter, keyword]);

  const patchSession = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/sessions/${id}`,
        body: {
          status: 'PUBLISHED'
        }
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const createSessions = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: `sessions`,
        body: {
          ...body,
          contributor: ['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(
            user.role
          )
            ? body.contributor
            : user._id
        }
      });
      setForceRefresh(!forceRefresh);
      setVisible(false);
    } catch (e) {
      if (e.response.data.description === 'DATE_BEFORE_TODAY') {
        message('DATE_BEFORE_TODAY');
      }
      if (e.response.data.description === 'SESSION_ALREADY_PROGRAMMED') {
        message('SESSION_ALREADY_PROGRAMMED');
      } else {
        message(e);
      }
    }
  };

  return (
    <SessionsContext.Provider
      value={{
        createSessions,
        patchSession,
        navigate,
        visible,
        setVisible,
        formations,
        forceRefresh,
        contributors,
        setForceRefresh,
        setTraineesFilter,
        traineesFilter,
        sessions,
        userSessions,
        setFormations,
        getFormations,
        users
      }}
    >
      {children}
    </SessionsContext.Provider>
  );
};

export const useSessionsContext = () => {
  const context = useContext(SessionsContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
