import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Popconfirm, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseOutlined,
  ContainerOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  checkType,
  record,
  index,
  children,
  selectType,
  ...restProps
}) => {
  const inputNode = <Input />;
  const { t } = useTranslation();

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `${t('opco.form.please_fill')} ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const OPCOTable = ({ OPCOs, editOPCO, deleteOPCO }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      label: '',
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      editOPCO(record._id, row);
      setEditingKey('');
    } catch (e) {
      message(e);
    }
  };

  const columns = [
    {
      title: t('opco.form.name'),
      dataIndex: 'title',
      editable: true,
      render: (title) => title,
      width: '25%'
    },
    {
      title: t('opco.form.address_postal_code'),
      dataIndex: ['address', 'postal_code'],
      editable: true,
      width: '10%'
    },
    {
      title: t('opco.form.address_city'),
      dataIndex: ['address', 'city'],
      editable: true,
      width: '15%'
    },
    {
      title: t('opco.form.address_street'),
      dataIndex: ['address', 'street'],
      editable: true,
      width: '25%'
    },

    {
      title: t(''),
      dataIndex: 'operation',
      width: '15%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => save(record)} type="add">
              {`${t('buttons.save')}`}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
              placement="topRight"
            >
              <Button type="link">
                {`${t('buttons.cancel')}`}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Button
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              type="link"
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteOPCO(record)}
              disabled={editingKey !== ''}
              placement="topRight"
            >
              <Button disabled={editingKey !== ''} type="link">
                <ContainerOutlined
                  style={{ color: 'var(--errorColor)' }}
                  type="delete"
                />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered={false}
        dataSource={OPCOs}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 800 }}
      />
    </Form>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  checkType: PropTypes.string.isRequired,
  record: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  setColorPicker: PropTypes.bool.isRequired,
  colorPicker: PropTypes.bool.isRequired,
  selectType: PropTypes.string.isRequired
};

OPCOTable.propTypes = {
  OPCOs: PropTypes.string.isRequired,
  editOPCO: PropTypes.func.isRequired,
  deleteOPCO: PropTypes.func.isRequired
};

export default OPCOTable;
