import {
  BorderlessTableOutlined,
  CalendarOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, Col, Row, Select, Switch } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageHeaderCustom } from '../../components';
import { CalendarView } from './CalendarView';
import { TableView } from './List/TableView';
import { AddEditSessionModal } from './AddEditSessionModal';
import { useSessionsContext } from './SessionsContext';
import { useAuthContext } from '../../contexts/AuthContext';

export const TrainingSessions = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [switchView, setSwitchView] = useState(true);
  const { visible, setVisible, formations, contributors } =
    useSessionsContext();
  const [sessionID, setSessionID] = useState();
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [purpose, setPurpose] = useState('');

  const filteredData = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `&k=${value}`
      });
    } else {
      navigate({
        pathname
      });
    }
  };

  const handleSwitchView = () => {
    setSwitchView(!switchView);
  };

  const openModal = () => {
    setVisible(true);
    setPurpose('add');
  };

  const openEditModal = (id) => {
    setVisible(true);
    setSessionID(id);
    setPurpose('edit');
  };

  const handleCloseModal = () => {
    setVisible(false);
    setEnd(null);
    setStart(null);
  };

  return (
    <div>
      <PageHeaderCustom
        title={t(
          `training_sessions.${
            user.role === 'users:CONTRIBUTOR' ? 'title' : 'admin_title'
          }`
        )}
      />

      <Row
        align="middle"
        gutter={[0, 8]}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 24px',
          paddingTop: 24
        }}
      >
        <Col>
          <Row align="middle" gutter={[8, 8]}>
            <Col>
              <CalendarOutlined style={{ marginRight: 2 }} />
              <span> {t(`training_sessions.tabs.agenda_view`)}</span>
            </Col>
            <Col>
              <Switch onChange={handleSwitchView} />
            </Col>
            <Col>
              <BorderlessTableOutlined style={{ marginRight: 2 }} />
              <span> {t(`training_sessions.tabs.chart_view`)}</span>
            </Col>
          </Row>
        </Col>
        <Row gutter={[8, 8]}>
          <Col>
            <Select
              style={{ width: '12rem' }}
              placeholder={t('training_sessions.tabs.filter_formation')}
              onSelect={(value) => filteredData(value)}
            >
              <Select.Option value="">
                {t('training_sessions.form.all_formations')}
              </Select.Option>
              {(formations || [])
                .filter((userFormation) =>
                  user.role === 'users:CONTRIBUTOR'
                    ? userFormation.trainer === user._id
                    : userFormation
                )
                .map(({ _id, name }) => (
                  <Select.Option key={_id} value={name}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </Col>
          <Col>
            {['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user?.role) && (
              <Select
                style={{ width: '12rem' }}
                placeholder={t('training_sessions.tabs.filter_contributor')}
                onSelect={(value) => filteredData(value)}
              >
                <Select.Option value="">
                  {t('training_sessions.form.all_contributors')}
                </Select.Option>
                {(contributors || []).map(({ _id, first_name, last_name }) => (
                  <Select.Option key={_id} value={last_name}>
                    {first_name} {last_name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
          <Col />
          <Button type="add" onClick={openModal}>
            <PlusOutlined />
            {`${t('training_sessions.buttons.new_session')}`}
            &nbsp;
          </Button>
        </Row>
      </Row>

      <AddEditSessionModal
        handleCloseModal={handleCloseModal}
        visible={visible}
        setVisible={setVisible}
        sessionID={sessionID}
        start={start}
        end={end}
        setStart={setStart}
        setEnd={setEnd}
        purpose={purpose}
      />

      {switchView ? (
        <CalendarView
          visible={visible}
          start={start}
          end={end}
          setStart={setStart}
          setEnd={setEnd}
          setVisible={setVisible}
          switchView={switchView}
          handleCloseModal={handleCloseModal}
        />
      ) : (
        <TableView openEditModal={openEditModal} />
      )}
    </div>
  );
};
