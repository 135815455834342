import { Row, Col, Card, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Document,
  Video,
  LicenseDraft,
  DocumentAttachment,
  Unknown,
  Link as LinkIcon
} from '@carbon/icons-react';
import ChapterInfo from './ChapterInfo';

export const ShowChapters = ({ chapters }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataToModal, setDataToModal] = useState();

  const showModal = (data) => {
    setDataToModal(data);
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    setDataToModal();
  };

  const getIconFromType = (type) => {
    switch (type) {
      case 'TEXT':
        return <Document size={16} />;
      case 'VIDEO':
        return <Video size={16} />;
      case 'QUIZ':
        return <LicenseDraft size={16} />;
      case 'HOMEWORK_DEPOSITS':
        return <DocumentAttachment size={16} />;
      case 'CHALLENGE_SLIDE':
        return <LinkIcon size={16} />;
      default:
        return <Unknown size={16} />;
    }
  };

  return (
    <div>
      <Modal
        title={dataToModal && dataToModal.title}
        visible={isModalVisible}
        destroyOnClose
        onCancel={hideModal}
        footer={null}
        className="chapter-info-modal"
      >
        <ChapterInfo dataToModal={dataToModal} />
      </Modal>
      {(chapters || []).map((chapter) => (
        <Card
          className="chapter-card"
          size="small"
          key={chapter._id}
          header={chapter.title}
          onClick={() => showModal(chapter)}
        >
          <Row align="middle">
            <Col>
              <Row justify="center">
                <Col>
                  <div className="chapter-icon-wrapper">
                    {getIconFromType(chapter.type)}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={23}>
              <span>{chapter.title}</span>
            </Col>
          </Row>
        </Card>
      ))}
    </div>
  );
};

ShowChapters.propTypes = {
  chapters: PropTypes.arrayOf({}).isRequired
};
