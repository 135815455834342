import { useTranslation } from 'react-i18next';

export const listContent = (data) => {
  const { description, type, deadline_deposits } = data;
  const { t } = useTranslation();
  const contents = [
    {
      label: 'chapter.form.type',
      span: 1,
      content: t(`chapter.tags.${type}`)
    },
    {
      label: 'chapter.form.deadline_deposits',
      span: 2,
      content: t(`chapter.tags.${deadline_deposits}`)
    },
    {
      label: 'master-classes.form.description',
      span: 3,
      content: description
    }
  ];

  return contents;
};
