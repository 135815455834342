import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, Button } from 'antd';
import PropTypes from 'prop-types';

const { TextArea } = Input;

export const TextModal = ({ purpose, fieldName }) => {
  const { t } = useTranslation();
  const [visibleText, setVisibleText] = useState(false);

  const openModalText = () => {
    setVisibleText(true);
  };

  const handleCloseTextModal = () => {
    setVisibleText(false);
  };

  return (
    <>
      <Button type="primary" onClick={openModalText}>
        {t(`buttons.add_${purpose}`)}
      </Button>
      <Modal
        title={t(`buttons.add_${purpose}`)}
        visible={visibleText}
        onOk={handleCloseTextModal}
        onCancel={handleCloseTextModal}
      >
        <Form.Item name={[...fieldName, purpose]}>
          <TextArea />
        </Form.Item>
      </Modal>
    </>
  );
};

TextModal.propTypes = {
  purpose: PropTypes.string.isRequired,
  fieldName: PropTypes.arrayOf('').isRequired
};

export default TextModal;
