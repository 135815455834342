import PropTypes from 'prop-types';
import { useNewsletterFields } from './fields';
import { CreateUpdateContainer } from '../../components';

export const CreateUpdateNewsletterEmail = ({ purpose }) => {
  const fields = useNewsletterFields();

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      baseUrl="newsletteremail"
      resource="newsletteremails"
    />
  );
};

CreateUpdateNewsletterEmail.propTypes = {
  purpose: PropTypes.string.isRequired
};
