import { Button, Form, Spin, Card } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useFields from './Fields';
import { useGenerateFormItem } from '../../../utils/GenerateFormItem';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

export const CreateUpdateSkillsProfile = ({ purpose }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [skillProfile, setSkillProfile] = useState({});
  const { fields, educational_objective } = useFields();
  const generateFields = useGenerateFormItem();

  const updateSkillProfile = async () => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `skills_profile/${id}`,
        body: skillProfile
      });
      setIsSubmitting(false);
      navigate(-1);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const createSkillProfile = async () => {
    setIsSubmitting(true);
    try {
      skillProfile.visible = false;
      await dispatchAPI('POST', {
        url: `skills_profile`,
        body: skillProfile
      });
      setIsSubmitting(false);
      navigate(-1);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const getSkillProfiles = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `skills_profile/${id}`
      });
      setSkillProfile(data);
      form.setFieldsValue(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [purpose, id]);

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);
      (async () => {
        await getSkillProfiles();
      })();
    }
  }, [getSkillProfiles]);

  const handleSubmit = async () => {
    if (purpose === 'edit') await updateSkillProfile(id);
    if (purpose === 'create') await createSkillProfile();
  };

  const onChange = (_, values) => {
    setSkillProfile({ ...skillProfile, ...values });
  };

  return (
    <Form
      onValuesChange={onChange}
      onFinish={handleSubmit}
      {...formItemLayout}
      form={form}
    >
      <PageHeaderCustom
        title={t(`skills_profile.form.purpose.${purpose}`)}
        extra={
          <>
            <Button type="add" htmlType="submit" loading={isSubmitting}>
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              onClick={() => navigate(-1)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
          </>
        }
      />
      <ContentCustom>
        <Spin spinning={isLoading}>
          <Card
            className="formation-card"
            title={t('skills_profile.form.infos')}
          >
            {fields.map((field) => generateFields('skills_profile', field))}
          </Card>
          <Card
            className="formation-card"
            title={t('skills_profile.form.educational_objective')}
          >
            {educational_objective.map((field) =>
              generateFields('skills_profile', field)
            )}
          </Card>
        </Spin>
      </ContentCustom>
    </Form>
  );
};

CreateUpdateSkillsProfile.propTypes = {
  purpose: PropTypes.string.isRequired
};
