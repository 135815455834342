import { Col, Row, Tag } from 'antd';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../utils/constants/adminRoutes';
import { ordersStatus } from '../../../utils/constants/tagColors';

export const OrderList = (data = {}) => {
  const { t } = useTranslation();

  const {
    customer,
    billing_adress,
    date,
    current_status,
    total_cost,
    giftedFor
  } = data;

  return [
    {
      label: t(`orders.form.customer`),
      span: 1,
      content:
        (customer && (
          <Link
            to={{ pathname: `${routes.USERS}/show/${customer._id}` }}
          >{`${customer.first_name} ${customer.last_name}`}</Link>
        )) ||
        `${t('errors.message.not_specified')}`
    },
    ...(giftedFor
      ? [
          {
            label: 'orders.form.gifted_for',
            span: 2,
            content: (
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                <Col>{`${giftedFor?.first_name} ${giftedFor?.last_name}`}</Col>
                <Col>{giftedFor?.email}</Col>
              </Row>
            )
          }
        ]
      : []),
    {
      label: t('orders.form.date'),
      span: 1,
      content:
        (date && moment(date).format('DD-MM-YYYY')) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: t('orders.form.status'),
      span: 1,
      content:
        (current_status && (
          <Tag color={ordersStatus[current_status]}>
            {t(`orders.status.${current_status}`)}
          </Tag>
        )) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: t('orders.form.price'),
      span: 1,
      content:
        (total_cost && `${total_cost}€`) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: t('orders.form.adress'),
      span: 1,
      content: billing_adress ? (
        <Col>
          <Row>{billing_adress.street}</Row>
          <Row>{`${billing_adress.postal_code} ${billing_adress.city}`}</Row>
        </Col>
      ) : (
        `${t('errors.message.not_specified')}`
      )
    }
  ];
};
