import { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs, Skeleton, Row, Col, Card } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import newsList from './List/NewsList';
import manageList from './List/ManageList';
import skillList from './List/SkillList';
import learnerProfileList from './List/LeanerProfileList';
import { ShowModules } from './Show/ShowModules';
import { ShowEvaluation } from './Show/ShowEvaluation';
import ShowFormationHeader from '../../../components/layouts/HeaderLayout/CourseHeaderLayout';

const { TabPane } = Tabs;

const ShowFormation = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [formation, setFormation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();

  const getFormation = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/formations/${id}?populate=categories,skills,trainer`
      });
      setFormation(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getFormation();
    })();
  }, [getFormation]);

  return (
    <>
      <PageHeaderCustom
        title={
          <>
            {t('formations.show')}
            {formation?.name}
          </>
        }
        extra={
          <ShowFormationHeader course={formation} courseType="formations" />
        }
      />
      <ContentCustom>
        <Tabs defaultActiveKey="1" className="formation-tabs">
          <TabPane tab="Informations générales" key="1">
            <Row gutter={24}>
              <Col xs={24} md={14} xl={16}>
                <Card
                  className="formation-card"
                  title={t('formations.form.infos')}
                >
                  <Skeleton active loading={isLoading} paragraph={{ rows: 3 }}>
                    <DescriptionList
                      data={newsList(formation || {}, t)}
                      translate
                    />
                  </Skeleton>
                </Card>
              </Col>
              <Col xs={24} md={10} xl={8}>
                <Card
                  className="formation-card"
                  title={t('formations.form.manage')}
                >
                  <Skeleton active loading={isLoading} paragraph={{ rows: 3 }}>
                    <DescriptionList
                      data={manageList(formation || {}, t)}
                      translate
                    />
                  </Skeleton>
                </Card>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Card
                  className="formation-card formation-skill-card"
                  title={t('formations.form.skill')}
                >
                  <Skeleton active loading={isLoading} paragraph={{ rows: 3 }}>
                    <DescriptionList
                      data={skillList(formation || {}, t)}
                      translate
                    />
                  </Skeleton>
                </Card>
              </Col>
              <Col xs={24} md={12}>
                <Card
                  className="formation-card"
                  title={t('formations.form.learner_profile.title')}
                >
                  <Skeleton active loading={isLoading} paragraph={{ rows: 3 }}>
                    <DescriptionList
                      data={learnerProfileList(formation || {}, t)}
                      translate
                    />
                  </Skeleton>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Contenu" key="2">
            <ShowModules modules={formation?.modules} />
          </TabPane>
          <TabPane tab="Parcours d'évaluation" key="3">
            <ShowEvaluation quizzes={formation?.quizzes} />
          </TabPane>
        </Tabs>
      </ContentCustom>
    </>
  );
};

export default ShowFormation;
