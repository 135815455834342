import {
  DownloadOutlined,
  EyeOutlined,
  HourglassOutlined
} from '@ant-design/icons';
import { Card, message } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';

export const EvaluationProcess = () => {
  const { dispatchAPI, user } = useAuthContext();
  const [surveys, setSurveys] = useState([]);

  const getSurveys = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `surveys`
      });
      setSurveys(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getSurveys();
  }, []);

  return surveys.map((survey) => (
    <Card key={survey._id} className="quiz-card">
      {t(`surveys.list.${survey.type}`)}

      <EyeOutlined
        style={{ float: 'right', marginLeft: '1rem', fontSize: 18 }}
      />
      <DownloadOutlined style={{ float: 'right', fontSize: 18 }} />
      {user?.role === 'contributor' && (
        <HourglassOutlined style={{ float: 'right', fontSize: 18 }} />
      )}
    </Card>
  ));
};
