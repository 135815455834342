import { Card, Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { DonutChart } from '../DonutChart';
import { useHomeContext } from '../../../contexts/HomeContext';

export const MasterClasses = () => {
  const { t } = useTranslation();
  const {
    purchasersCount,
    masterclasses,
    trainees,
    setMasterclassFilter,
    traineesFilter,
    masterclassFilter,
    setTraineesFilter,
    sales,
    completionRateMasterClass
  } = useHomeContext();

  return (
    <Card
      className="home-card"
      title={
        <Row justify="space-between" align="middle" gutter={[0, 16]}>
          <p>{`${t('home.titles.masterclasses')}`}</p>
          <Row style={{ gap: 8 }} gutter={[0, 8]}>
            <Select
              style={{ width: 180 }}
              onSelect={(value) => setTraineesFilter(value)}
              placeholder="Tous les apprenants"
              disabled={!!masterclassFilter}
            >
              <Select.Option value="">
                {t('home.form.all_trainees')}
              </Select.Option>
              {trainees?.map((trainee) => (
                <Select.Option value={trainee._id} key={trainee._id}>
                  {masterclassFilter
                    ? `${`${trainee?.user?.first_name} ${trainee?.user?.last_name}`}`
                    : `${`${trainee?.first_name} ${trainee?.last_name}`}`}
                </Select.Option>
              ))}
            </Select>
            <Select
              style={{ width: 200 }}
              onSelect={(value) => setMasterclassFilter(value)}
              placeholder="Toutes les masterclasses"
              disabled={!!traineesFilter}
            >
              <Select.Option value="">
                {t('home.form.all_masterclasses')}
              </Select.Option>
              {(masterclasses || []).map((masterclass) => (
                <Select.Option value={masterclass._id} key={masterclass._id}>
                  {masterclass?.title}
                </Select.Option>
              ))}
            </Select>
          </Row>
        </Row>
      }
    >
      <Row justify="space-between" gutter={[0, 16]} className="home-indicator">
        {!traineesFilter && (
          <>
            <Col>
              <Row>{masterclasses?.length}</Row>
              <Row>{`${t('home.form.published_masterclasses')}`}</Row>
            </Col>

            <Col>
              <Row>{purchasersCount}</Row>
              <Row>{`${t('home.form.trainees')}`}</Row>
            </Col>
          </>
        )}
        {traineesFilter && (
          <Col>
            <Row>{sales.masterClassOrdered}</Row>
            <Row>{`${t('home.form.masterclasses_ordered')}`}</Row>
          </Col>
        )}
        <Col>
          <Row>{`${Math.round(sales?.annualSales)} €`}</Row>
          <Row>{`${t('home.form.annual_sales')}`}</Row>
        </Col>
        <Col>
          <Row>{`${Math.round(sales?.monthAverageSales)} €`}</Row>
          <Row>{`${t('home.form.monthly_sales')}`}</Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Row justify="center">
            <DonutChart donutData={completionRateMasterClass} />
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
