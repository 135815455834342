import { Button, Form, Spin, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useFields from './Fields';
import Extra from './extra';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useFormationsContext } from './FormationsContext';

const { Title } = Typography;

export const CreateUpdateFormation = ({ purpose }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const {
    step,
    previousStep,
    nextStep,
    updateFormation,
    createFormation,
    formation,
    setFormation,
    isSubmitting,
    images,
    setImages,
    surveys
  } = useFormationsContext();
  const { isFieldsLoading } = useFields(images);

  const getFormation = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `formations/${id}` });
      const challenges = {};
      data.modules.forEach((module) => {
        const chapter_choice = module.chapters.find(
          (chapter) => chapter.type === 'CHALLENGE_CHOICE'
        );
        if (chapter_choice) {
          chapter_choice.challenges.forEach((challenge, challenge_index) => {
            if (challenge.image) {
              challenges[challenge_index] = challenge.image;
            }
          });
        }
      });
      const newdata = {
        ...data,
        quizzes: {
          pre_training:
            data.quizzes?.pre_training ||
            surveys.find((el) => el.type === 'pre_training_model'),
          quick_satisfaction:
            data.quizzes?.quick_satisfaction ||
            surveys.find((el) => el.type === 'quick_satisfaction_model'),
          chilled_satisfaction:
            data.quizzes?.chilled_satisfaction ||
            surveys.find((el) => el.type === 'chilled_satisfaction_model')
        }
      };
      setImages({ image: data.image, challenges });
      setFormation(newdata);
      form.setFieldsValue(newdata);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [purpose, id, isFieldsLoading]);

  const addSurveysToNewFormation = () => {
    const newdata = {
      quizzes: {
        pre_training:
          formation.quizzes?.pre_training ||
          surveys.find((el) => el.type === 'pre_training_model'),
        quick_satisfaction:
          formation.quizzes?.quick_satisfaction ||
          surveys.find((el) => el.type === 'quick_satisfaction_model'),
        chilled_satisfaction:
          formation.quizzes?.chilled_satisfaction ||
          surveys.find((el) => el.type === 'chilled_satisfaction_model')
      }
    };
    setFormation(newdata);
  };

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);
      if (!isFieldsLoading)
        (async () => {
          await getFormation();
        })();
    } else {
      addSurveysToNewFormation();
    }
  }, [getFormation]);

  const handleSubmit = async () => {
    if (purpose === 'edit') await updateFormation(id);
    if (purpose === 'create') await createFormation();
  };

  const onChange = (_, values) => {
    setFormation({ ...formation, ...values });
  };

  return (
    <Form
      onValuesChange={onChange}
      onFinish={handleSubmit}
      {...formItemLayout}
      form={form}
    >
      <PageHeaderCustom
        title={t(`formations.form.title.${purpose}`)}
        extra={
          <>
            <Button type="add" htmlType="submit" loading={isSubmitting}>
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              onClick={() => navigate(-1)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
          </>
        }
      />
      <ContentCustom>
        <Title level={3}>{t(`formations.form.step${step}`)}</Title>
        <Spin spinning={isLoading}>
          <Extra
            step={step}
            nextStep={nextStep}
            previousStep={previousStep}
            form={form}
          />
        </Spin>
      </ContentCustom>
    </Form>
  );
};

CreateUpdateFormation.propTypes = {
  purpose: PropTypes.string.isRequired
};
