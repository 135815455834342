import { useCallback, useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Skeleton, Row, Col, Divider } from 'antd';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import newsList from './NewsList';
import AssociatedSkills from './AssociatedSkills';

export const ShowSkillsProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [skillsProfile, setSkillsProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const [refresh, setRefresh] = useState(false);

  const deleteSkillsProfile = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/skills_profiles/${id}`
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  const getSkillsProfile = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/skills_profiles/${id}?populate=categories,skills`
      });
      setSkillsProfile(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getSkillsProfile();
    })();
  }, [getSkillsProfile, refresh]);

  return (
    <>
      <PageHeaderCustom
        title={
          <>
            {`${t('skills_profile.show.title')} `}
            {skillsProfile?.name}
          </>
        }
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.CATALOG}${subRoutes.CATALOG.SKILLS_PROFILES}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={deleteSkillsProfile}
              icon={<WarningOutlined />}
            >
              <Button type="link">
                {`${t('buttons.delete')} `}
                <ContainerOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={24}>
          <Col xs={24} md={14}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
              <DescriptionList
                data={newsList(skillsProfile || {}, refresh, setRefresh)}
                translate
              />
            </Skeleton>
          </Col>
        </Row>
        <Divider />
        <AssociatedSkills
          skillsProfile={skillsProfile}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </ContentCustom>
    </>
  );
};
