import {
  CheckCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  MailOutlined
} from '@ant-design/icons';
import { Avatar, Button, Table } from 'antd';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../../utils/constants/adminRoutes';
import { UserBadges } from '../UserBadges';

export const SessionUserTable = ({
  sessionData,
  sessionID,
  role,
  deleteUser,
  formationID
}) => {
  const navigate = useNavigate();

  const calculateProgress = (progress) => {
    const totalChapters = progress.reduce(
      (total, module) => total + module.chapters.length,
      0
    );

    const completedChapters = progress.reduce(
      (total, module) =>
        total + module.chapters.filter((chapter) => chapter.completed).length,
      0
    );

    return Math.round((completedChapters / totalChapters) * 100);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'user',
      key: 'user.photo',
      width: 80,
      render: (user) =>
        user && (
          <Avatar size={40} src={user && user?.photo ? user?.photo : ``} />
        )
    },
    {
      title: t('training_sessions.chart.last_name'),
      dataIndex: 'user',
      key: 'user.last_name',
      render: (user) => user && user.last_name,
      sorter: (a, b) => a?.user?.last_name.localeCompare(b?.user?.last_name)
    },
    {
      title: t('training_sessions.chart.first_name'),
      dataIndex: 'user',
      key: ' user.first_name',
      render: (user) => user && user.first_name,
      sorter: (a, b) => a?.user?.first_name.localeCompare(b?.user?.first_name)
    },
    {
      title: t('training_sessions.chart.progress'),
      dataIndex: 'progress',
      key: 'progress',
      render: (progress) => {
        const progressValue = calculateProgress(progress);
        return (
          (progress && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 8
              }}
            >
              <div
                style={{
                  width:
                    progressValue !== 100
                      ? `calc(${progressValue}% - 20px)`
                      : '100%',
                  height: 8,
                  borderRadius: 8,
                  backgroundColor: progressValue !== 100 ? '#FFD33B' : '#71D8CC'
                }}
              />
              <div style={{ width: '20px' }}>
                {progressValue !== 100 ? (
                  `${progressValue}%`
                ) : (
                  <CheckCircleOutlined style={{ color: '#71D8CC' }} />
                )}
              </div>
            </div>
          )) ||
          'N/R'
        );
      },
      sorter: (a, b) => a?.user?.progress.localeCompare(b?.user?.progress)
    },
    {
      title: t('training_sessions.chart.badges_obtained'),
      render: (record) => (
        <UserBadges formationID={formationID} userID={record.user._id} />
      )
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'user',
      width: 132,
      align: 'right',
      render: (user) =>
        user && (
          <>
            {role === 'users:CONTRIBUTOR' && (
              <MailOutlined style={{ marginRight: '1rem', fontSize: 18 }} />
            )}
            <Link
              to={`${routes.TRAINING_SESSIONS}/show/${sessionID}/show/subscriber/${user._id}`}
            >
              <EyeOutlined style={{ fontSize: 18 }} />
            </Link>
            <Button type="link" danger onClick={() => deleteUser(user._id)}>
              <DeleteOutlined style={{ fontSize: 18 }} />
            </Button>
          </>
        )
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={sessionData}
      onRow={(record) => ({
        onDoubleClick: () =>
          navigate(
            `${routes.TRAINING_SESSIONS}/show/${sessionID}/show/subscriber/${record.user._id}`
          )
      })}
      scroll={{ x: 1000 }}
    />
  );
};

SessionUserTable.propTypes = {
  sessionData: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.arrayOf(
        PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string
        })
      ),
      progress: PropTypes.number
    })
  ),
  sessionID: PropTypes.string,
  role: PropTypes.string,
  deleteUser: PropTypes.func,
  formationID: PropTypes.string
};

SessionUserTable.defaultProps = {
  sessionData: [],
  sessionID: undefined,
  role: undefined,
  deleteUser: null,
  formationID: undefined
};
