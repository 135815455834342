import { Badge, Button, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useDiscussionsContext } from '../../contexts/DiscussionsContext';

export const SessionButton = ({ session, handleDiscussions }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { forceRefresh } = useDiscussionsContext();
  const { dispatchAPI, user } = useAuthContext();
  const [count, setCount] = useState();

  const getUnreadSessionCommentariesCount = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `commentaries/count?read_by!=${user._id}&session=${session._id}&sender!=${user._id}`
      });
      setCount(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getUnreadSessionCommentariesCount();
    })();
  }, [forceRefresh]);

  return (
    <Button
      key={session._id}
      onClick={() => handleDiscussions(session)}
      className="inbox-session-btn"
      type="link"
    >
      <Row>
        {/* COMMENTAIRE POUR NOEMIE : le offset sert à positionner le badge, le nombre à gauche c'est l'axe horizontal et celui de droite l'axe vertical */}
        <Badge status="warning" count={count} size="small" offset={[-150, 20]}>
          <Row
            style={{
              flexDirection: 'column'
            }}
          >
            <h4>{session?.formation?.name}</h4>
            <h5>
              {`${t('training_sessions.modals.from2')} ${moment(
                session.start_date
              ).format('DD-MM-YYYY')} ${t(
                'training_sessions.modals.to2'
              )} ${moment(session.end_date).format('DD-MM-YYYY')}`}
            </h5>
          </Row>
        </Badge>
      </Row>
    </Button>
  );
};

SessionButton.propTypes = {
  session: PropTypes.shape({
    _id: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    formation: PropTypes.shape({
      name: PropTypes.string
    })
  }),
  handleDiscussions: PropTypes.func
};

SessionButton.defaultProps = {
  session: null,
  handleDiscussions: null
};
