import { useState, useEffect } from 'react';
import { FlagOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import { OverflowMenuVertical, Download, TrashCan } from '@carbon/icons-react';
import { Avatar, Button, Card, Popover, Row, List, Popconfirm } from 'antd';
import { t } from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMessagesContext } from '../../contexts/MessagesContext';
import { ReportModal } from '../Reports/ReportModal';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useDownloadDocument } from '../../utils/downloadDoc';

export const MessageItem = ({ message }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { forceRefresh, setForceRefresh } = useMessagesContext();
  const { message: errorMessage } = useErrorMessage();
  const [visible, setVisible] = useState(false);
  const { downloadDocument } = useDownloadDocument();
  const [attachments, setAttachments] = useState();
  const content = JSON.parse(message?.content);

  const patchMessage = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `messages/patch/${message._id}/read-by`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      errorMessage(e);
    }
  };

  const deleteFile = async (idFile) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/messages/${message._id}/files/${idFile}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const getMessageAttachments = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `messages/${message._id}?populate=attachments`
      });
      setAttachments(data.attachments);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      if (message.sender._id !== user._id) {
        await patchMessage();
      }
      await getMessageAttachments();
    })();
  }, [message]);

  const popoverContent = (
    <div>
      <Button type="link" onClick={() => setVisible(!visible)}>
        <FlagOutlined style={{ marginRight: '0.5rem' }} />
        {t('inbox.form.report_message')}
      </Button>
    </div>
  );

  return (
    <Card
      className={
        message.sender._id !== user._id && !message.read_by
          ? 'message-item non-read'
          : 'message-item'
      }
    >
      <Row justify="center" align="middle">
        <Row style={{ flex: 1, gap: '1rem' }} align="middle" gutter={[0, 8]}>
          <Avatar
            src={message?.sender?.photo ? message?.sender?.photo : ''}
            size="medium"
            icon={message?.sender?.photo ? '' : <UserOutlined />}
          />
          <h3>{`${message?.sender?.first_name} ${message?.sender?.last_name}`}</h3>
          {`Le ${moment(message.created_at).format('LLLL')}`}
        </Row>

        {message.sender._id !== user._id && (
          <Popover trigger="click" content={popoverContent}>
            <Button type="link">
              <OverflowMenuVertical />
            </Button>
          </Popover>
        )}
      </Row>
      <Row>{message.subject}</Row>
      <Row>
        {message?.status !== 'ARCHIVED' ? (
          <Editor initialContentState={content} toolbarHidden readOnly />
        ) : (
          <span style={{ margin: '14px 0px' }}>{message?.isDeleted}</span>
        )}
      </Row>
      {!!attachments?.length && (
        <List
          dataSource={attachments}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  style={{ color: 'green' }}
                  icon={<Download />}
                  onClick={() => downloadDocument(item)}
                />,
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteFile(item?._id)}
                  icon={<WarningOutlined />}
                  placement="topRight"
                >
                  <TrashCan
                    size={18}
                    style={{
                      color: '#FF4D4F'
                    }}
                    type="delete"
                  />
                </Popconfirm>
              ]}
            >
              {item?.metadata?.originalName}
            </List.Item>
          )}
        />
      )}
      <ReportModal
        visible={visible}
        item={message}
        purpose="Message"
        setVisible={setVisible}
      />
    </Card>
  );
};

MessageItem.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.shape([]),
    subject: PropTypes.string,
    created_at: PropTypes.string,
    _id: PropTypes.string,
    sender: PropTypes.shape({
      photo: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      _id: PropTypes.string
    }),
    read_by: PropTypes.string,
    attachments: PropTypes.shape([]),
    status: PropTypes.string,
    isDeleted: PropTypes.string
  })
};

MessageItem.defaultProps = {
  message: {
    content: null,
    created_at: null,
    sender: {
      photo: null
    }
  }
};
