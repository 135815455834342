import { Calendar, Col, Row, Select, Tag } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { VideoCameraOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sessionsStatus } from '../../utils/constants/tagColors';
import { useSessionsContext } from './SessionsContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { routes } from '../../utils/constants/adminRoutes';

const Cell = styled.div`
  list-style: none;
  user-select: none;
  width: 100%;
  height: 100%;
`;

export const CalendarView = ({
  setVisible,
  end,
  start,
  setStart,
  setEnd,
  switchView
}) => {
  const { user } = useAuthContext();
  const { sessions, userSessions } = useSessionsContext();
  const [endTemp, setEndTemp] = useState(null);
  const { t } = useTranslation();

  const customHeader = ({ value, onChange }) => {
    const selectStart = 0;
    const selectEnd = 12;
    const monthOptions = [];

    const current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i += 1) {
      current.month(i);
      months.push(localeData.monthsShort(current));
    }

    for (let index = selectStart; index < selectEnd; index += 1) {
      monthOptions.push(
        <Select.Option className="month-item" key={`${index}`}>
          {months[index]}
        </Select.Option>
      );
    }
    const month = value.month();

    const year = value.year();
    const options = [];
    for (let i = year - 2; i < year + 4; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>
      );
    }

    return (
      <Row
        justify="space-between"
        align="middle"
        style={{
          padding: '16px 12px 16px 16px'
        }}
        gutter={[0, 12]}
      >
        {switchView && (
          <Col xs={24} xl={20} xxl={22}>
            <Row style={{ gap: 16 }}>
              <Row style={{ gap: 8 }} align="middle">
                <div
                  style={{
                    backgroundColor: sessionsStatus.PUBLISHED,
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%'
                  }}
                />
                {t('training_sessions.tabs.sessions_published')}
              </Row>
              <Row style={{ gap: 8 }} align="middle">
                <div
                  style={{
                    backgroundColor: sessionsStatus.PENDING,
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%'
                  }}
                />
                {t('training_sessions.tabs.sessions_pending')}
              </Row>
              <Row style={{ gap: 8 }} align="middle">
                <div
                  style={{
                    backgroundColor: sessionsStatus.RUNNING,
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%'
                  }}
                />{' '}
                {t('training_sessions.tabs.sessions_running')}
              </Row>
              <Row style={{ gap: 8 }} align="middle">
                <div
                  style={{
                    backgroundColor: sessionsStatus.CLOSED,
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%'
                  }}
                />{' '}
                {t('training_sessions.tabs.sessions_closed')}
              </Row>
            </Row>
          </Col>
        )}

        <Col
          xs={24}
          xl={4}
          xxl={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            dropdownMatchSelectWidth={false}
            className="my-year-select"
            onChange={(newYear) => {
              const now = value.clone().year(newYear);
              onChange(now);
            }}
            value={String(year)}
          >
            {options}
          </Select>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            dropdownMatchSelectWidth={false}
            value={String(month)}
            onChange={(selectedMonth) => {
              const newValue = value.clone();
              newValue.month(parseInt(selectedMonth, 10));
              onChange(newValue);
            }}
            style={{ marginLeft: 8 }}
          >
            {monthOptions}
          </Select>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (end !== null) {
      setVisible(true);
    }
  }, [end]);

  useEffect(() => {
    const list = [];
    const listCells = [];
    const listSelected = document.querySelectorAll(
      `td.ant-picker-cell-selected`
    );
    listSelected.forEach((cell) => {
      cell.classList.remove('ant-picker-cell-selected');
    });
    for (
      let i = moment(start);
      i.isBefore(moment(endTemp).add(1, 'days'));
      i.add(1, 'days')
    ) {
      list.push(i.format('YYYY-MM-DD'));
    }
    list.forEach((el) => {
      listCells.push(document.querySelector(`td[title="${el}"]`));
    });
    listCells.forEach((cell) => {
      cell.classList.add('ant-picker-cell-selected');
    });
  }, [start, endTemp]);

  const resetAllSelector = () => {
    setEndTemp(null);
    const listSelected = document.querySelectorAll(
      `td.ant-picker-cell-selected`
    );
    listSelected.forEach((cell) => {
      cell.classList.remove('ant-picker-cell-selected');
    });
  };

  const dateCellRender = (value) => {
    const date = new Date(value);
    return (
      <Cell
        onMouseDown={() => {
          resetAllSelector();
          setStart(date);
        }}
        onMouseEnter={() => start && setEndTemp(date)}
        onMouseUp={() => setEnd(date)}
      >
        {['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user?.role) &&
          (sessions || [])
            .filter(
              (session) =>
                moment(date).day !== 0 &&
                moment(date).format('YYYY-MM-DD') >=
                  moment(session.start_date).format('YYYY-MM-DD') &&
                moment(date).format('YYYY-MM-DD') <=
                  moment(session.end_date).format('YYYY-MM-DD') &&
                moment(date).isoWeekday() !== 7 &&
                moment(date).isoWeekday() !== 6
            )
            .map((session) => (
              <li key={session._id}>
                <Link
                  to={{
                    pathname: `${routes.TRAINING_SESSIONS}/show/${session._id}`
                  }}
                  onClick={() => setVisible(false)}
                >
                  <Tag
                    style={{
                      backgroundColor: `${sessionsStatus[session.status]}`
                    }}
                  >
                    <ul>
                      {session.formation?.name}
                      {!!session?.virtual_classes?.length &&
                        (session.virtual_classes || [])
                          .filter(
                            (virtual_class) =>
                              moment(date).format('YYYY-MM-DD') ===
                              moment(virtual_class.date).format('YYYY-MM-DD')
                          )
                          .map((virtual_class) => (
                            <li key={virtual_class._id}>
                              <VideoCameraOutlined />
                              <span>
                                {moment(virtual_class.start_time).format(
                                  'HH:MM'
                                )}
                              </span>
                            </li>
                          ))}
                    </ul>
                  </Tag>
                </Link>
              </li>
            ))}
        {user?.role === 'users:CONTRIBUTOR' &&
          (userSessions || [])
            .filter(
              (session) =>
                moment(date).day !== 0 &&
                moment(date).format('YYYY-MM-DD') >=
                  moment(session.start_date).format('YYYY-MM-DD') &&
                moment(date).format('YYYY-MM-DD') <=
                  moment(session.end_date).format('YYYY-MM-DD') &&
                moment(date).isoWeekday() !== 7 &&
                moment(date).isoWeekday() !== 6
            )
            .map((session) => (
              <li key={session._id}>
                <Link
                  to={{
                    pathname: `${routes.TRAINING_SESSIONS}/show/${session._id}`
                  }}
                  onClick={() => setVisible(false)}
                >
                  <Tag
                    style={{
                      backgroundColor: `${sessionsStatus[session.status]}`
                    }}
                  >
                    <ul>
                      {session.formation?.name}
                      {(session.virtual_classes || [])
                        .filter(
                          (virtual_class) =>
                            moment(date).format('YYYY-MM-DD') ===
                            moment(virtual_class.date).format('YYYY-MM-DD')
                        )
                        .map((virtual_class) => (
                          <li key={virtual_class._id}>
                            <VideoCameraOutlined />
                            <span>
                              {moment(virtual_class.start_time).format('HH:MM')}
                            </span>
                          </li>
                        ))}
                    </ul>
                  </Tag>
                </Link>
              </li>
            ))}
      </Cell>
    );
  };

  return (
    <Calendar
      dateCellRender={dateCellRender}
      style={{ userSelect: 'none' }}
      headerRender={customHeader}
      className="session-calendar"
    />
  );
};

CalendarView.propTypes = {
  setVisible: PropTypes.bool,
  end: PropTypes.string,
  start: PropTypes.string,
  setEnd: PropTypes.string,
  setStart: PropTypes.string,
  switchView: PropTypes.bool
};

CalendarView.defaultProps = {
  setVisible: false,
  end: null,
  start: null,
  setEnd: null,
  setStart: null,
  switchView: false
};
