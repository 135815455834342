import { CheckCircleOutlined } from '@ant-design/icons';
import { Card, Col, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const VirtualClassesContainer = ({ sessionData, isLoading }) => (
  <Col xs={24} md={10} xl={8}>
    <Card
      className="formation-card virtual_classes-card"
      title={t('training_sessions.form.virtual_classes')}
    >
      <Skeleton active loading={isLoading} paragraph={{ rows: 4 }}>
        {(sessionData.virtual_classes || []).map((virtual_class, index) => (
          <div
            key={virtual_class._id}
            style={{ width: '100%', display: 'flex' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 12
              }}
            >
              {moment(virtual_class?.date).isAfter(moment()) ? (
                <div className="virtual-classes-index">{index + 1}</div>
              ) : (
                <CheckCircleOutlined />
              )}
            </div>

            <div>
              <h4>
                {` ${t('training_sessions.modals.the')} ${moment(
                  virtual_class?.date
                ).format('DD-MM-YYYY')} ${t(
                  'training_sessions.modals.from'
                )} ${moment(virtual_class?.start_time).format('LT')} ${t(
                  'training_sessions.modals.to'
                )} ${moment(virtual_class?.end_time).format('LT')}`}
              </h4>

              <Link to={{ pathname: `${virtual_class?.link}` }}>
                {virtual_class?.link}
              </Link>
            </div>
          </div>
        ))}
      </Skeleton>
    </Card>
  </Col>
);

VirtualClassesContainer.propTypes = {
  sessionData: PropTypes.shape({
    virtual_classes: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  isLoading: PropTypes.bool
};

VirtualClassesContainer.defaultProps = {
  sessionData: null,
  isLoading: false
};
