import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { formItemLayout } from '../../../utils/constants/formLayout';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { MasterClassForm } from './masterClassForm';

export const CreateUpdateMasterClass = ({ purpose }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateMasterClass = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `master-classes/${id}`,
        body: {
          ...body,
          image: image || null
        }
      });
      navigate(-1);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);

      message(e);
    }
  };

  const createMasterClass = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('POST', {
        url: `master-classes`,
        body: {
          ...body,
          created_by:
            user?.role === 'users:CONTRIBUTOR' ? user._id : body.created_by,
          image: image || null,
          status:
            user?.role === 'users:CONTRIBUTOR' ? 'DRAFT' : 'WAITING_VALIDATION'
        }
      });
      setIsSubmitting(false);
      navigate(-1);
    } catch (e) {
      setIsSubmitting(false);
      if (e.response.data.description === 'NAME_ALREADY_TAKEN') {
        message('NAME_ALREADY_TAKEN');
      } else message(e);
    }
  };

  const getMasterClass = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `master-classes/${id}`
      });
      setImage(data.image);
      form.setFieldsValue(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [purpose, id]);

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);
      (async () => {
        await getMasterClass();
      })();
    }
  }, [getMasterClass]);

  const handleSubmit = async (values) => {
    if (purpose === 'edit') await updateMasterClass(values);
    if (purpose === 'create') await createMasterClass(values);
  };

  const onValuesChange = () => {
    setForceRefresh(!forceRefresh);
  };
  return (
    <Form
      onValuesChange={onValuesChange}
      onFinish={handleSubmit}
      {...formItemLayout}
      form={form}
    >
      <PageHeaderCustom title={t(`master-classes.form.${purpose}`)} />
      <ContentCustom>
        <MasterClassForm
          setImage={setImage}
          image={image}
          isLoading={isLoading}
          form={form}
        />
        <Row style={{ marginTop: 10 }} justify="end">
          <Button type="add" htmlType="submit" loading={isSubmitting}>
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={() => navigate(-1)}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
        </Row>
      </ContentCustom>
    </Form>
  );
};

CreateUpdateMasterClass.propTypes = {
  purpose: PropTypes.string.isRequired
};
