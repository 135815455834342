import { useState, useEffect } from 'react';
import { Select, Input, Form, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [skills, setSkills] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const skillDrawerVisibility = () => {};

  const getCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'categories' });
      setCategories(data);
    } catch (e) {
      message(e.message);
    }
  };

  const onSelectCategory = (value) => {
    const list = [...selectedCategory];
    list.push(value);
    setSelectedCategory(list);
  };

  const onDeselectCategory = (value) => {
    setSelectedCategory(selectedCategory.filter((sk) => sk !== value));
  };

  const getSkills = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'skills' });
      setSkills(data);
    } catch (e) {
      message(e.message);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(!isFieldsLoading);
      await getCategories();
      await getSkills();
      setIsFieldsLoading(!isFieldsLoading);
    })();
  }, []);

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['categories'],
      input: (
        <Select
          loading={isFieldsLoading}
          style={{ width: '100%', textAlign: 'left', borderRadius: 5 }}
          onSelect={onSelectCategory}
          value={categories}
          options={categories.map((categorie) => ({
            label: categorie.name,
            value: categorie._id
          }))}
          mode="multiple"
          onDeselect={onDeselectCategory}
        />
      ),
      rules: [{ required: true }]
    }
  ];

  const educational_objective = [
    {
      name: ['skills'],
      input: (
        <Input.Group compact>
          <Form.Item name={['skills']}>
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="children"
              loading={isFieldsLoading}
              style={{ width: 380, textAlign: 'left', borderRadius: 5 }}
              options={skills.map((skill) => ({
                label: skill.name,
                value: skill._id
              }))}
            />
          </Form.Item>
          <Button type="add" onClick={skillDrawerVisibility}>
            <span style={{ fontSize: '15px' }}>{t('buttons.create')}</span>
            <PlusOutlined />
          </Button>
        </Input.Group>
      ),
      rules: [{ required: true }]
    }
  ];

  return {
    fields,
    isFieldsLoading,
    educational_objective
  };
};

export default useFields;
