import { createGlobalStyle } from 'styled-components';
import '../SCSS/admin/index.scss';

export const AdminTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: #101010;
    --addColor: #101010;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #f9f9f9;
    --secondaryHover: #dcf7f4;
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  #71D8CC;
    --secondaryColor: #71D8CC;
    --disabledColor: #8d8d8d;
    --bodyBackground: var(--componentBackground);
    --textColor: var(--primaryColor);
    --textColorSecondary: rgba(16, 16, 16, 0.8);
    --textColorInvert: var(--clientColor);
    --textColorA015: rgba(255, 255, 255, 0.8);
    --itemActiveBackground: #e6f7ff;
    --itemHoverBackground: var(--secondaryHover);
    --borderColor: #BEBEBE;
    --contrastBackground: #f5f5f5;
    --opacitySVG: 1;
    --primaryContrast: #FFD33B;
    --errorColor: #FF4D4F;
    --titleFont: "anton", "Impact", sans-serif;
    --mainFont: "aileron", "Roboto", sans-serif;
    --tertiaryHover: rgb(250, 241, 211);
    --lightGray: #e5e5e5;
    --primaryColorA04
    --primaryColorA07
    --tabsGray
    --textColorHover
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;
