import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const ReportModal = ({ item, visible, setVisible, purpose }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [form] = Form.useForm();
  const [enums, setEnums] = useState();

  const getReportEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `reports/enums`
      });
      setEnums(data.causes);
    } catch (e) {
      message(e);
    }
  };

  const sendReport = async (values) => {
    try {
      await dispatchAPI('POST', {
        url: `reports`,
        body: {
          ...values,
          user_reported: item.sender._id,
          item: {
            infos: item._id,
            ref: purpose === 'Message' ? 'Message' : 'Commentary'
          }
        }
      });
    } catch (e) {
      message(e);
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      sendReport(values);
      setVisible(!visible);
    } catch (e) {
      if (e.errorFields && e.errorFields.length)
        form.scrollToField(e.errorFields[0].name);
    }
  };

  useEffect(() => {
    (() => {
      getReportEnums();
    })();
  }, []);

  return (
    <Modal
      title={t('reports.form.confirm_report')}
      visible={visible}
      bodyStyle={{ padding: 20, maxHeight: 640, overflow: 'scroll' }}
      style={{ height: '1000px' }}
      className="report-modal"
      onOk={handleOk}
    >
      <h2>{t('reports.form.precise_report_cause')}</h2>
      <Form form={form} labelCol={{ span: 5 }} layout="vertical">
        <Form.Item name={['motive']}>
          <Radio.Group>
            {(enums || []).map((motive) => (
              <Radio key={motive} value={motive}>
                {t(`reports.form.${motive}`)}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ReportModal.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    sender: PropTypes.string
  }),
  visible: PropTypes.bool,
  setVisible: PropTypes.bool,
  purpose: PropTypes.string
};

ReportModal.defaultProps = {
  item: null,
  visible: false,
  setVisible: false,
  purpose: undefined
};
