import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Row, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useGenerateFormItem } from '../../../utils/GenerateFormItem';
import useFields from './Fields';
import { ModulesList } from './ModulesList';
import { EvaluationsList } from './EvaluationsList';

const Extra = ({ step, nextStep, previousStep, form }) => {
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const { fields, educational_objective, leanerProfileFields } = useFields();

  return (
    <>
      {step === 1 && (
        <>
          <Card className="formation-card" title={t('formations.form.infos')}>
            {fields.map((field) => generateFields('formations', field))}
          </Card>
          <Card
            className="formation-card"
            title={t('formations.educational_objective')}
          >
            {educational_objective.map((field) =>
              generateFields('formations', field)
            )}
          </Card>
          <Card
            className="formation-card"
            title={t('formations.form.learner_profile.title')}
          >
            {leanerProfileFields.map((field) =>
              generateFields('formations', field)
            )}
          </Card>
          <Row justify="end" style={{ marginTop: 10 }}>
            <Button
              type="link"
              onClick={() => nextStep(step)}
              className="formation-step-btn"
            >
              {t('formations.form.step2')}
              <ArrowRightOutlined />
            </Button>
          </Row>
        </>
      )}
      {step === 2 && (
        <ModulesList
          previousStep={previousStep}
          nextStep={nextStep}
          step={step}
          form={form}
        />
      )}
      {step === 3 && <EvaluationsList form={form} />}
    </>
  );
};

Extra.propTypes = {
  step: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired
};

export default Extra;
