// Main nav menuItems
export const routes = {
  HOME: '/',
  USERS: '/users',
  TRAINING_SESSIONS: '/sessions',
  CATALOG: '/catalog',
  REPORTS: '/reports',
  ORDERS: '/orders',
  NEWSLETTER: '/newsletter',
  MODELS: '/models',
  SETTINGS: '/settings'
};

// Main nav subMenuItems
export const subRoutes = {
  CATALOG: {
    FORMATIONS: '/formations',
    MASTERCLASSES: '/master-classes',
    SKILLS_PROFILES: '/skills_profiles'
  },
  MODELS: {
    SURVEYS: '/surveys',
    DOCUMENTS: '/documents'
  },
  SETTINGS: {
    CATEGORIES: '/categories',
    TOPICS: '/topics',
    SKILLS: '/skills',
    OPCO: '/opco'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {
  USERS: '?p=1&f=status=ACTIVE__',
  CONTRIBUTORS: '?p=1&f=status=ACTIVE__'
};
