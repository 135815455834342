import { EyeOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Datatable } from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { sessionsStatus } from '../../utils/constants/tagColors';

export const ListSessionsFormations = ({ userID }) => {
  const { t } = useTranslation();

  const extraQuery = `contributor=${userID}`;

  const columns = [
    {
      title: t('associatesSessionsFormations.form.title'),
      key: 'formation',
      dataIndex: 'formation',
      sorter: true,
      render: (formation) => formation.name
    },
    {
      title: t('associatesSessionsFormations.form.status'),
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      render: (status) => <Tag color={sessionsStatus[status]}>{status}</Tag>
    },
    {
      title: t('associatesSessionsFormations.form.start_date'),
      key: 'start_date',
      dataIndex: 'start_date',
      sorter: true,
      render: (start_date) => moment(start_date).format('DD-MM-YYYY')
    },
    {
      title: t('associatesSessionsFormations.form.end_date'),
      key: 'end_date',
      dataIndex: 'end_date',
      sorter: true,
      render: (end_date) => moment(end_date).format('DD-MM-YYYY')
    },
    {
      render: (record) => (
        <Link
          to={{ pathname: `${routes.TRAINING_SESSIONS}/show/${record._id}` }}
        >
          <EyeOutlined />
        </Link>
      )
    }
  ];

  return (
    <Datatable
      resourceName="sessions"
      columns={columns}
      populate="formation"
      extraQuery={extraQuery}
      resourceModelName="Session"
      customActionColumn
    />
  );
};

ListSessionsFormations.propTypes = {
  userID: PropTypes.string
};

ListSessionsFormations.defaultProps = {
  userID: undefined
};
