import { Badge, Trophy } from '@carbon/icons-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const UserBadges = ({ userID, formationID }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [badges, setBadges] = useState();

  const getSubscriberBadge = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `formations/${formationID}/badges/${userID}`
      });
      setBadges(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getSubscriberBadge();
  }, []);

  return (
    <Row style={{ gap: 4 }}>
      {badges
        ? badges.map((badge) =>
            badge?.type === 'badge' ? (
              <div
                className={badge.is_acquired ? 'badge-acquired' : 'badge-wait'}
              >
                <div className="badge-icon">
                  <Badge size={20} />
                </div>
              </div>
            ) : (
              <div
                className={badge.is_acquired ? 'badge-acquired' : 'badge-wait'}
              >
                <div className="badge-icon">
                  <Trophy size={20} />
                </div>
              </div>
            )
          )
        : `${t('errors.message.not_specified')}`}
    </Row>
  );
};

UserBadges.propTypes = {
  userID: PropTypes.string,
  formationID: PropTypes.string
};

UserBadges.defaultProps = {
  userID: undefined,
  formationID: undefined
};
