import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Upload, Button, TreeSelect } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';

const { Dragger } = Upload;
const { TreeNode } = TreeSelect;

const UploadTemplate = ({ typeSelector }) => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [type, setType] = useState();

  const listTypes = [
    'formation_certificate',
    'masterclass_certificate',
    'formation',
    'survey',
    'invoice',
    'quote'
  ];

  const props = {
    name: 'file',
    multiple: false,
    disabled: !typeSelector ? disabled : disabled || !type,
    action: `${process.env.REACT_APP_API_URL}/files`,
    data: {
      type
    },
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;
      setDisabled(true);

      if (status === 'error') {
        message(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: {
      showRemoveIcon: false
    }
  };

  return (
    <>
      <PageHeaderCustom title={t('documents.create.title')} />
      <ContentCustom>
        <Row>
          <Col span={8} style={{ textAlign: 'right' }}>
            <div style={{ marginTop: '4px' }}>
              <span style={{ color: 'red' }}>*</span>
              {` ${t(`documents.create.type`)} : `}
            </div>
          </Col>
          <Col span={8} style={{ marginBottom: 10 }}>
            <TreeSelect style={{ width: '100%' }} onChange={(v) => setType(v)}>
              {listTypes.map((localType) => (
                <TreeNode
                  value={localType}
                  title={`${t(`documents.models.${localType}`)}`}
                />
              ))}
            </TreeSelect>
          </Col>
          <Col span={8} offset={8}>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('documents.create.action')}</p>
              <p className="ant-upload-hint">{t('documents.create.formats')}</p>
            </Dragger>
          </Col>
          <Col span={8} offset={8}>
            <Button
              type="primary"
              style={{
                color: '#fff',
                float: 'right',
                backgroundColor: 'rgb(71, 210, 103)',
                border: 'rgb(71, 210, 103)',
                marginTop: 40
              }}
              onClick={() => navigate('/models/documents')}
            >
              Terminé
            </Button>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};

UploadTemplate.propTypes = {
  typeSelector: PropTypes.bool
};

UploadTemplate.defaultProps = {
  typeSelector: false
};

export default UploadTemplate;
