import { Card, Col, Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ColumnChart } from '../ColumnChart';

export const ChartContainer = ({
  purpose,
  columnData,
  setSwitchFormationsChart,
  switchFormationsChart,
  setSwitchMasterClassesChart,
  switchMasterClassesChart,
  switchOpcoChart,
  setSwitchOpcoChart
}) => {
  const { t } = useTranslation();
  const dataNames = columnData?.map((data) => data.name);

  let title;
  switch (purpose) {
    case 'formations':
      title = 'formations_ranking';
      break;
    case 'masterclass':
      title = 'masterclasses_ranking';
      break;
    default:
      title = 'opcos_ranking';
  }

  return (
    <Card
      className="home-card"
      title={
        <Row justify="space-between" align="middle" gutter={[0, 10]}>
          <Col>
            <p>{t(`home.titles.${title}`)}</p>
          </Col>
          <Col>
            <Switch
              onClick={() => {
                switch (purpose) {
                  case 'formations':
                    setSwitchFormationsChart(!switchFormationsChart);
                    break;
                  case 'masterclass':
                    setSwitchMasterClassesChart(!switchMasterClassesChart);
                    break;
                  default:
                    setSwitchOpcoChart(!switchOpcoChart);
                }
              }}
              style={{ width: 'auto' }}
              checkedChildren={t('home.form.most_subscribed')}
              unCheckedChildren={t('home.form.less_subscribed')}
            />
          </Col>
        </Row>
      }
    >
      {columnData && (
        <ColumnChart columnData={columnData} dataNames={dataNames} />
      )}
    </Card>
  );
};

ChartContainer.propTypes = {
  purpose: PropTypes.string,
  columnData: PropTypes.shape(PropTypes.string),
  setSwitchFormationsChart: PropTypes.bool,
  switchFormationsChart: PropTypes.bool,
  setSwitchMasterClassesChart: PropTypes.bool,
  switchMasterClassesChart: PropTypes.bool,
  switchOpcoChart: PropTypes.bool,
  setSwitchOpcoChart: PropTypes.bool
};

ChartContainer.defaultProps = {
  purpose: undefined,
  columnData: null,
  setSwitchFormationsChart: false,
  switchFormationsChart: false,
  setSwitchMasterClassesChart: false,
  switchMasterClassesChart: false,
  switchOpcoChart: false,
  setSwitchOpcoChart: false
};
