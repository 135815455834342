import { Card, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { HourglassOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const SubscriberSurveyAnswers = ({ formation }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { subscriberID } = useParams();
  const [subscriberSurveyAnswers, setSubscriberSurveyAnswers] = useState();

  const getSubscriberSurveyAnswers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `survey-responses/${formation._id}/${subscriberID}`
      });
      setSubscriberSurveyAnswers(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getSubscriberSurveyAnswers();
    })();
  }, []);

  return (subscriberSurveyAnswers || []).map((item) => (
    <Card>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Col>
          {item.chapter.title
            ? `${item.module.title} - 
            ${item.chapter.title}`
            : t('surveys.list.final_exam')}
        </Col>
        <Col>
          {item.survey ? (
            `${item.survey.quiz_mark} / 10`
          ) : (
            <HourglassOutlined size={18} />
          )}
        </Col>
      </Row>
    </Card>
  ));
};

SubscriberSurveyAnswers.propTypes = {
  formationID: PropTypes.shape({
    _id: PropTypes.string
  })
};

SubscriberSurveyAnswers.defaultProps = {
  formationID: null
};
