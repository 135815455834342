import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  TimePicker
} from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  CloseOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { formItemLayout } from '../../utils/constants/formLayout';
import { useSessionsContext } from './SessionsContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const timePickerFormat = 'HH:mm';
const datePickerFormat = 'DD-MM-YYYY';

export const AddEditSessionModal = ({
  handleCloseModal,
  setStart,
  start,
  end,
  setEnd,
  sessionID,
  purpose
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const {
    forceRefresh,
    setForceRefresh,
    visible,
    setVisible,
    createSessions,
    formations,
    contributors
  } = useSessionsContext();
  const { t } = useTranslation();
  const [sessionToPatch, setSessionToPatch] = useState([]);
  const [form] = Form.useForm();
  const { message } = useErrorMessage();

  const disabledDate = (current) =>
    current < moment().endOf('day').subtract(1, 'day');

  const disabledEndDate = (current) => current < start;

  const getSessionToPatch = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `sessions/${sessionID}?populate=formation`
      });
      setSessionToPatch(data);
      form.setFieldsValue({
        ...data,
        formation: data.formation._id,
        start_date: moment(data.start_date),
        end_date: moment(data.end_date),
        virtual_classes: (data.virtual_classes || []).map((virtualClass) => ({
          ...virtualClass,
          date: moment(virtualClass.date),
          start_time: moment(virtualClass.start_time),
          end_time: moment(virtualClass.end_time)
        }))
      });
    } catch (e) {
      message(e);
    }
  };

  const patchSessionData = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `sessions/${sessionID}`,
        body
      });
      setVisible(false);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (purpose === 'edit') {
      getSessionToPatch();
    }
  }, [sessionID]);

  useEffect(() => {
    if (start && end) {
      form.setFieldsValue({
        start_date: moment(start),
        end_date: moment(end)
      });
    }
  }, [start, end]);

  const onFinish = (values) => {
    if (purpose === 'edit') {
      patchSessionData(values);
    } else {
      createSessions(values);
    }
    form.resetFields();
    setForceRefresh(!forceRefresh);
  };

  return (
    <Modal
      visible={visible}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      maskClosable={false}
      width="70%"
      open={visible}
      footer={null}
      className="session-modal"
      title={t(
        `training_sessions.modals.${
          purpose === 'edit' ? 'edit_session' : 'new_formation_session'
        }`
      )}
    >
      <Form onFinish={onFinish} {...formItemLayout} form={form}>
        <>
          <Form.Item
            name="formation"
            initialValue={
              purpose === 'edit' ? sessionToPatch.formation?.name : null
            }
            label={t('training_sessions.modals.select_formation')}
            rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
          >
            <Select>
              {(formations || [])
                .filter((userFormation) =>
                  user.role === 'users:CONTRIBUTOR'
                    ? userFormation.trainer === user._id
                    : userFormation
                )
                .map((formation) => (
                  <Select.Option key={formation._id} value={formation._id}>
                    {formation.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user.role) && (
            <Form.Item
              name="contributor"
              initialValue={
                purpose === 'edit'
                  ? `${sessionToPatch?.contributor?.first_name} ${sessionToPatch?.contributor?.last_name}`
                  : null
              }
              label={t('training_sessions.modals.select_contributor')}
              rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
            >
              <Select>
                {(contributors || []).map((contributor) => (
                  <Select.Option key={contributor._id} value={contributor._id}>
                    {`${contributor.first_name} ${contributor.last_name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="start_date"
            label={t('training_sessions.modals.start_date')}
            rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
          >
            <DatePicker
              onChange={(value) => setStart(value)}
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              format={datePickerFormat}
            />
          </Form.Item>
          <Form.Item
            name="end_date"
            label={t('training_sessions.modals.end_date')}
            rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
          >
            <DatePicker
              onChange={(value) => setEnd(value)}
              style={{ width: '100%' }}
              disabled={start === null}
              disabledDate={disabledEndDate}
              format={datePickerFormat}
            />
          </Form.Item>
        </>

        <Divider />
        <h3>{t('training_sessions.form.virtual_classes')}</h3>
        <p>{t('training_sessions.modals.modal_text')}</p>

        <Form.List name="virtual_classes">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row
                  key={key}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginBottom: 16
                  }}
                  align="baseline"
                >
                  <Col xs={3} lg={1}>
                    <span className="session-class-number">{key + 1}</span>
                  </Col>
                  <Col xs={19} lg={22}>
                    <Row>
                      <Form.Item {...restField} name={[name, 'date']}>
                        <DatePicker
                          style={{ width: '16vw', minWidth: 180 }}
                          format={datePickerFormat}
                          disabledDate={(submissionValue) => {
                            if (!submissionValue) {
                              return false;
                            }
                            return (
                              submissionValue.valueOf() < start ||
                              submissionValue.valueOf() >=
                                moment(end).add(1, 'days')
                            );
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'start_time']}
                        label={t('training_sessions.modals.from')}
                      >
                        <TimePicker
                          style={{ width: '16vw', minWidth: 170 }}
                          format={timePickerFormat}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'end_time']}
                        label={t('training_sessions.modals.to')}
                      >
                        <TimePicker
                          style={{ width: '16vw', minWidth: 170 }}
                          format={timePickerFormat}
                        />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        {...restField}
                        name={[name, 'link']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing link'
                          }
                        ]}
                      >
                        <Input
                          style={{ width: '32vw', marginRight: 10 }}
                          placeholder={t('training_sessions.modals.add_link')}
                        />
                      </Form.Item>
                    </Row>
                  </Col>

                  <Col xs={2} lg={1} style={{ paddingTop: 3 }}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {t('training_sessions.modals.add_virtual_class')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Row style={{ marginTop: 10 }} justify="end">
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={handleCloseModal}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="add" htmlType="submit">
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

AddEditSessionModal.propTypes = {
  handleCloseModal: PropTypes.func,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setStart: PropTypes.func,
  setEnd: PropTypes.func,
  start: PropTypes.string,
  end: PropTypes.string,
  sessionID: PropTypes.string,
  purpose: PropTypes.string
};

AddEditSessionModal.defaultProps = {
  handleCloseModal: null,
  visible: false,
  setVisible: false,
  start: null,
  end: null,
  setStart: null,
  setEnd: null,
  sessionID: null,
  purpose: null
};
