import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col, Card } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ChoiceGridTable } from './ChoiceGridTable';
import { OptionsChoiceFormList } from './OptionsChoiceFormList';
import { useSurveysContext } from './SurveysContext';

export const CreateUpdateSurvey = ({ purpose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id, type } = useParams();
  const {
    isSubmitting,
    newQuestion,
    form,
    createSurvey,
    updateSurvey,
    forceFormDataRefresh,
    extraCardButtons,
    setForceFormDataRefresh
  } = useSurveysContext();

  const questionTypes = [
    'UNIQUE_CHOICE',
    'MULTI_CHOICE',
    'TEXT_AREA',
    'CHOICE_GRID'
  ];

  const getSurvey = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/surveys/${id}` });
      form.setFieldsValue(data);
    } catch (e) {
      message(e.message);
    }
  };

  useEffect(() => {
    if (purpose === 'edit') {
      getSurvey();
    }
  }, []);

  const handleSubmit = async (values) => {
    if (purpose === 'create') await createSurvey({ ...values, type });
    if (purpose === 'edit') await updateSurvey({ ...values }, id);
  };

  const checkRender = (question, index, move, remove) => {
    switch (question.type) {
      case 'UNIQUE_CHOICE':
      case 'MULTI_CHOICE':
        return (
          <OptionsChoiceFormList
            fieldName={[index]}
            type={question.type}
            extraCardButtons={extraCardButtons}
            moveQuestion={move}
            removeQuestion={remove}
          />
        );
      case 'TEXT_AREA':
        return (
          <Card
            title={
              <Form.Item name={[index, 'title']}>
                <Input bordered={false} />
              </Form.Item>
            }
            extra={extraCardButtons(move, remove, index)}
            className="question-card"
          >
            <Form.Item name={[index, 'type']} hidden>
              <Input />
            </Form.Item>
            <TextArea disabled />
          </Card>
        );
      case 'CHOICE_GRID':
        return (
          <ChoiceGridTable
            extraCardButtons={extraCardButtons}
            fieldName={index}
            moveQuestion={move}
            removeQuestion={remove}
            question={question}
            form={form}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      onValuesChange={() => setForceFormDataRefresh(!forceFormDataRefresh)}
    >
      <PageHeaderCustom
        title={t(`surveys.form.title.${purpose}`)}
        extra={
          <>
            <Button type="add" htmlType="submit" loading={isSubmitting}>
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={() => navigate(-1)}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
          </>
        }
      />
      <ContentCustom>
        <Col xs={24} xxl={20}>
          <Form.Item
            name="title"
            label={t('surveys.form.name')}
            rules={[{ required: true }]}
            style={{ marginBottom: 16 }}
          >
            <Input />
          </Form.Item>
          <Form.List name="questions">
            {(fields, { add, move, remove }) => (
              <>
                <Row
                  justify="space-between"
                  style={{ marginBottom: 8 }}
                  gutter={8}
                >
                  {questionTypes.map((el) => (
                    <Col flex="auto">
                      <Button
                        type="dashed"
                        onClick={() => add(newQuestion(el))}
                        style={{ width: '100%', marginBottom: 8 }}
                      >
                        {t(`surveys.form.buttons.${el}`)}
                      </Button>
                    </Col>
                  ))}
                </Row>
                {fields.map(({ name }) =>
                  checkRender(
                    form.getFieldValue(['questions', name]),
                    name,
                    move,
                    remove
                  )
                )}
              </>
            )}
          </Form.List>
        </Col>
      </ContentCustom>
    </Form>
  );
};

CreateUpdateSurvey.propTypes = {
  purpose: PropTypes.string.isRequired
};
