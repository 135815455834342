import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Popconfirm,
  Divider,
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Switch
} from 'antd';
import {
  CheckSquareOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  ContainerOutlined,
  FolderOutlined,
  MenuOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Datatable } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ImportButton } from '../../../components/importButton';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { columns } from './FormationColumns';

const { Search } = Input;

const Formations = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { user, dispatchAPI } = useAuthContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);
  const [showArchived, setShowArchived] = useState(false);

  const searchResource = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  const deleteFormation = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/formations/${id}`
      });
    } catch (e) {
      message(e);
    }
  };

  const updateFormationStatus = async (formation, status) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/formations/${formation._id}`,
        body: { ...formation, status }
      });
    } catch (e) {
      message(e);
    }
  };

  const handleUpdateStatus = async (formation, status) => {
    await updateFormationStatus(formation, status);
    navigate(0);
  };

  const deleteButton = (formation) => {
    if (
      formation?.status === 'WAITING_VALIDATION' ||
      formation?.status === 'DRAFT'
    ) {
      return (
        <>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.archive.title')}
            okText={t('datatable.column.action.archive.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.archive.cancel')}
            onConfirm={() => deleteFormation(formation._id)}
            icon={<WarningOutlined />}
          >
            <ContainerOutlined
              style={{ color: 'var(--errorColor)', fontSize: 18 }}
              type="delete"
            />
          </Popconfirm>
        </>
      );
    }
    if (
      user.role.split(':')[0] === 'admins' &&
      formation?.status !== 'ARCHIVED'
    ) {
      return (
        <>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.archive.title')}
            okText={t('datatable.column.action.archive.ok')}
            okButtonProps={{ type: 'primary' }}
            cancelText={t('datatable.column.action.archive.cancel')}
            onConfirm={() => handleUpdateStatus(formation, 'ARCHIVED')}
            icon={<WarningOutlined />}
            placement="topRight"
          >
            <FolderOutlined style={{ fontSize: 18 }} />
          </Popconfirm>
        </>
      );
    }
    return null;
  };

  const editButton = (formation) => {
    if (
      (user?.role.split(':')[0] === 'admins' &&
        formation?.status !== 'ARCHIVED') ||
      formation?.status === 'DRAFT' ||
      formation?.status === 'WAITING_VALIDATION'
    ) {
      return (
        <>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: `edit/${formation._id}`
            }}
          >
            <EditOutlined style={{ fontSize: 18 }} />
          </Link>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const actionColumn = [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 155,
      render: (record) => (
        <>
          {user.role.split(':')[0] === 'admins' &&
            record.status === 'WAITING_VALIDATION' && (
              <>
                <CheckSquareOutlined
                  style={{ fontSize: 18 }}
                  onClick={() => handleUpdateStatus(record, 'PUBLISHED')}
                />
                <Divider type="vertical" />
              </>
            )}
          <Link
            to={{
              pathname: `show/${record._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {editButton(record)}
          {deleteButton(record)}
        </>
      )
    }
  ];

  const menu = (
    <Menu>
      <Menu.Item key="import">
        <ImportButton resourceName="Formations" />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeaderCustom title={t(`formations.title`)} />
      <ContentCustom>
        <Row
          justify="space-between"
          gutter={[8, 16]}
          style={{ rowGap: '16px' }}
        >
          <Col span={6}>
            <Search
              allowClear
              placeholder={t('placeholder.search')}
              defaultValue={searchValue}
              onSearch={(value) => searchResource(value)}
            />
          </Col>
          <Col
            span={12}
            align="left"
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
          >
            <Switch
              onChange={() => setShowArchived(!showArchived)}
              checked={showArchived}
              style={{ marginLeft: '8px' }}
            />
            <span>{t('datatable.show_archived')}</span>
          </Col>
          <Col span={6}>
            <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link to="create">
                <Button type="add">
                  <PlusOutlined />
                  {`${t('buttons.create')}`}
                  &nbsp;
                </Button>
              </Link>
              <Dropdown overlay={menu}>
                <Button type="link">
                  <MenuOutlined
                    style={{ fontSize: 16, color: 'var(--textColor)' }}
                  />
                </Button>
              </Dropdown>
            </Row>
          </Col>
        </Row>
        <Datatable
          style={{ marginTop: 24 }}
          resourceName="formations"
          columns={[...columns(t), ...actionColumn]}
          customActionColumn
          populate="categories,trainer"
          resourceModelName="Formations"
          scroll={{ x: 1400 }}
          extraQuery={`${
            user?.role === 'users:CONTRIBUTOR'
              ? `trainer=${user._id}&`
              : 'status!=DRAFT&'
          }${showArchived ? 'status=ARCHIVED' : '&status!=ARCHIVED'}`}
        />
      </ContentCustom>
    </>
  );
};

export default Formations;
