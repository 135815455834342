import { Badge, Col, Row, Select } from 'antd';
import { t } from 'i18next';
import { NewMessageModal } from './NewMessageModal';
import { useMessagesContext } from '../../contexts/MessagesContext';

export const Header = () => {
  const { handleSwitchSession, unreadCount, userThreads, sessionEnums } =
    useMessagesContext();

  return (
    <Row justify="space-between" align="middle" gutter={[0, 16]}>
      <Row justify="space-between" align="middle" gutter={10}>
        <Col>
          <p className="inbox-counter">{`${userThreads?.length || 0} ${t(
            'inbox.form.total_exchanges'
          )}`}</p>
        </Col>
        <Col>
          <p className="inbox-counter">
            {unreadCount !== 0 && <Badge status="warning" />}
            {`${unreadCount || 0} ${t('inbox.form.unread')}`}
          </p>
        </Col>
      </Row>

      <Row align="middle" style={{ gap: 24 }}>
        <Row align="middle" style={{ gap: 8 }}>
          <Select
            onSelect={(value) => handleSwitchSession(value)}
            style={{ width: '15rem' }}
            placeholder={t('inbox.form.sessions_ALL')}
          >
            {(sessionEnums.status || []).map(
              (status) =>
                status !== 'PENDING' && (
                  <Select.Option value={status} key={status}>
                    {t(`inbox.form.sessions_${status}`)}
                  </Select.Option>
                )
            )}
            <Select.Option value="ALL">
              {t('inbox.form.sessions_ALL')}
            </Select.Option>
          </Select>
        </Row>
        <NewMessageModal />
      </Row>
    </Row>
  );
};
