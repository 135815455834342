import { UserOutlined } from '@ant-design/icons';
import { Avatar, Tag } from 'antd';
import moment from 'moment/moment';
import { userRoles } from '../../utils/constants/tagColors';

const listContent = (
  {
    first_name,
    last_name,
    role,
    phone_number,
    email,
    address,
    photo,
    date_of_birth,
    career
  },
  t
) => [
  {
    label: '',
    content: photo ? (
      <Avatar src={photo || ''} size={120} />
    ) : (
      <Avatar icon={<UserOutlined />} size={120} />
    ),
    span: 0.5
  },
  {
    label: 'profile.personalInfo.first_name',
    content: first_name,
    span: 1
  },
  {
    label: 'profile.personalInfo.last_name',
    content: last_name,
    span: 1
  },
  {
    label: 'profile.personalInfo.date_of_birth',
    content: date_of_birth && moment(date_of_birth).format('ll'),
    span: 1
  },
  {
    label: 'profile.personalInfo.role',
    content: role && (
      <Tag color={userRoles[role.split(':')[1]]}>
        {t(`users.tags.${role.split(':')[1]}`)}
      </Tag>
    ),
    span: 1
  },
  {
    label: 'profile.personalInfo.carreer',
    content: career,
    span: 1
  },
  {
    label:
      role === 'admins:SUPER-ADMIN'
        ? 'profile.form.address'
        : 'profile.form.billing_address',
    span: 1,
    content:
      address === {} ? (
        <div>
          <p>
            {address.number}
            {address.street}
            <br />
            {address.additional}
            <br />
            {address.postal_code} {address.city}
          </p>
        </div>
      ) : (
        '-'
      )
  },
  {
    label: 'profile.form.phone_number',
    span: 1,
    content:
      phone_number && phone_number.number ? (
        <div>
          {`${phone_number.country_code} ${phone_number.number.slice(
            0,
            1
          )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
        </div>
      ) : (
        '-'
      )
  },
  {
    label: 'profile.personalInfo.email',
    content: email,
    span: 3
  }
];

export default listContent;
