import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge, Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/HandleResize';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/userRoutes';
import { useMessagesContext } from '../../contexts/MessagesContext';
import { useDiscussionsContext } from '../../contexts/DiscussionsContext';

const { SubMenu } = Menu;

export const UserNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const { unreadCount } = useMessagesContext();
  const { unreadCommentariesCount } = useDiscussionsContext();
  const location = useLocation();
  const { width } = useHandleResize();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  return (
    <Menu
      style={{
        padding: '16px 0'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
    >
      {Object.entries(routes).map(([menuItem, p]) =>
        subRoutes[menuItem] ? (
          <SubMenu
            key={p}
            icon={navMenuLogos[menuItem]}
            title={t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
          >
            {Object.entries(subRoutes[menuItem]).map(
              ([subMenuItem, subPath]) => (
                <Menu.Item key={subPath} disabled={subPath === ''}>
                  <NavLink
                    to={`${p}${subPath}${
                      pathSearches[subMenuItem] ? pathSearches[subMenuItem] : ''
                    }`}
                  >
                    <span>
                      {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                    </span>
                  </NavLink>
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={p} disabled={p === ''}>
            <NavLink
              to={`${p}${pathSearches[menuItem] ? pathSearches[menuItem] : ''}`}
            >
              {navMenuLogos[menuItem]}
              {!['INBOX', 'DISCUSS'].includes(menuItem) ? (
                <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
              ) : (
                <Badge
                  status="warning"
                  count={
                    menuItem === 'DISCUSS'
                      ? unreadCommentariesCount
                      : unreadCount
                  }
                  offset={[20, 7]}
                  size="small"
                >
                  <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
                </Badge>
              )}
            </NavLink>
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

UserNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
