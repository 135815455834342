import { Tag } from 'antd';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { sessionsStatus } from '../../utils/constants/tagColors';
import { NextVirtualClass } from './NextVirtualClass';

export const useChartColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('training_sessions.chart.formation'),
      dataIndex: ['formation', 'name'],
      key: 'formation',
      width: '20%',
      sorter: true
    },
    {
      title: t('training_sessions.chart.contributor'),
      dataIndex: 'contributor',
      key: 'contributor',
      render: (contributor) =>
        `${contributor.first_name} ${contributor.last_name}`,
      width: '15%',
      sorter: true
    },
    {
      title: t('training_sessions.chart.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        (status && (
          <Tag color={sessionsStatus[status]} className="status-tag">
            {t(`training_sessions.form.${status}`)}
          </Tag>
        )) ||
        '-',
      width: '15%',
      sorter: true
    },
    {
      title: t('training_sessions.chart.start_date'),
      dataIndex: 'start_date',
      key: 'start_date',
      width: '10%',
      sorter: true,
      render: (start_date) =>
        start_date ? moment(start_date).format('DD-MM-YYYY') : 'N/R'
    },
    {
      title: t('training_sessions.chart.end_date'),
      dataIndex: 'end_date',
      key: 'end_date',
      width: '10%',
      sorter: true,
      render: (end_date) =>
        end_date ? moment(end_date).format('DD-MM-YYYY') : 'N/R'
    },
    {
      title: t('training_sessions.chart.next_virtual_class'),
      width: '15%',
      render: (record) => <NextVirtualClass session={record} />
    }
  ];
};
