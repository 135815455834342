import { Card, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EvaluationInfo = ({ dataToModal }) => {
  const { t } = useTranslation();

  const evaluationToShow = (question, index) => {
    const { type } = question;

    switch (type) {
      case 'MULTI_CHOICE':
        return (
          <>
            <Row>
              <Col span={1}>
                <div className="card-number">{index + 1}.</div>
              </Col>
              <Col span={13}>{question.title}</Col>
              <Col span={10}>
                <Row justify="end">
                  <Col>{t(`surveys.form.buttons.${type}`)}</Col>
                </Row>
              </Col>
            </Row>
            {!!question.answer_options &&
              question.answer_options.map((options) => (
                <Card key={options._id} className="chapter-card" size="small">
                  <Row>
                    <div className="card-answer">{options.title}</div>
                  </Row>
                </Card>
              ))}
          </>
        );
      case 'TEXT':
        return (
          <>
            <Row>
              <Col span={1}>
                <div className="card-number">{index + 1}.</div>
              </Col>
              <Col span={13}>{question.title}</Col>
              <Col span={10}>
                <Row justify="end">
                  <Col>{t(`surveys.form.buttons.${type}`)}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>{question.text}</Col>
            </Row>
          </>
        );
      case 'UNIQUE_CHOICE':
        return (
          <>
            <Row>
              <Col span={1}>
                <div className="card-number">{index + 1}.</div>
              </Col>
              <Col span={13}>{question.title}</Col>
              <Col span={10}>
                <Row justify="end">
                  <Col>{t(`surveys.form.buttons.${type}`)}</Col>
                </Row>
              </Col>
            </Row>
            {!!question.answer_options.length &&
              question.answer_options.map((options) => (
                <Card key={options._id} className="chapter-card" size="small">
                  <Row>
                    <div className="card-answer">{options.title}</div>
                  </Row>
                </Card>
              ))}
          </>
        );
      case 'CHOICE_GRID':
        return (
          <>
            <Row>
              <Col span={1}>
                <div className="card-number">{index + 1}.</div>
              </Col>
              <Col span={13}>{question.title}</Col>
              <Col span={10}>
                <Row justify="end">
                  <Col>{t(`surveys.form.buttons.${type}`)}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>{type}</Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {dataToModal &&
        !!dataToModal.questions?.length &&
        dataToModal.questions.map((question, index) => (
          <Card
            key={question._id}
            size="small"
            header={question.title}
            className="chapter-card"
          >
            {evaluationToShow(question, index)}
          </Card>
        ))}
    </div>
  );
};

EvaluationInfo.propTypes = {
  dataToModal: PropTypes.element.isRequired
};

export default EvaluationInfo;
