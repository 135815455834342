import {
  HomeOutlined,
  UserOutlined,
  ContactsOutlined,
  CalendarOutlined,
  BookOutlined,
  FlagOutlined,
  EuroCircleOutlined,
  FolderOutlined,
  SettingOutlined,
  MailOutlined,
  CommentOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  USERS: <UserOutlined />,
  CONTRIBUTORS: <ContactsOutlined />,
  TRAINING_SESSIONS: <CalendarOutlined />,
  CATALOG: <BookOutlined />,
  REPORTS: <FlagOutlined />,
  ORDERS: <EuroCircleOutlined />,
  MODELS: <FolderOutlined />,
  SETTINGS: <SettingOutlined />,
  INBOX: <MailOutlined />,
  DISCUSS: <CommentOutlined />,
  NEWSLETTER: <MailOutlined />
};

export default navMenuLogos;
