import { FileUnknownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { routes } from '../../../utils/constants/adminRoutes';
import { sessionsStatus } from '../../../utils/constants/tagColors';

export const SessionList = (data, t) => {
  const {
    surveyID,
    formation,
    contributor,
    start_date,
    end_date,
    status,
    subscribers,
    number_of_certified
  } = data;

  const { user } = useAuthContext();

  return [
    {
      label: 'training_sessions.form.formation',
      span: 2,
      content: formation?.name || `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.status',
      span: 1,
      content:
        (status && (
          <Tag color={sessionsStatus[status]}>
            {t(`training_sessions.form.${status}`)}
          </Tag>
        )) ||
        `${t('errors.message.not_specified')}`
    },
    ...(['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user.role)
      ? [
          {
            label: 'training_sessions.form.contributor',
            span: 3,
            content: (
              <>
                <Link
                  to={{ pathname: `/contributors/show/${contributor?._id}` }}
                >
                  {`${contributor?.first_name} ${contributor?.last_name}`}{' '}
                </Link>
                {surveyID && (
                  <Link
                    to={{
                      pathname: `${routes.TRAINING_SESSIONS}/show/surveys/contributor/${surveyID}`
                    }}
                  >
                    <FileUnknownOutlined />
                  </Link>
                )}
              </>
            )
          }
        ]
      : []),
    {
      label: 'training_sessions.form.start_date',
      span: 1,
      content:
        moment(start_date).format('DD-MM-YYYY') ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.end_date',
      span: 1,
      content:
        moment(end_date).format('DD-MM-YYYY') ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.subscribers_count',
      span: 2,
      content: subscribers?.length || `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.certified_count',
      span: 2,
      content: number_of_certified || `${t('errors.message.not_specified')}`
    }
  ];
};
