import { Badge, Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDiscussionsContext } from '../../contexts/DiscussionsContext';

export const DiscussionHeader = () => {
  const { unreadCommentariesCount, handleSwitchSession, sessionEnums } =
    useDiscussionsContext();
  const { t } = useTranslation();

  return (
    <Row
      style={{
        justifyContent: unreadCommentariesCount ? 'space-between' : 'flex-end'
      }}
    >
      {unreadCommentariesCount > 0 && (
        <Col>
          <p className="inbox-counter">
            <Badge status="warning" size="large" />
            {`${unreadCommentariesCount} ${t(
              'discussions.form.unread_commentaries'
            )}`}
          </p>
        </Col>
      )}
      <Col style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Row gutter={[16, 16]}>
          <Select
            onSelect={(value) => handleSwitchSession(value)}
            style={{ width: '15rem' }}
            placeholder={t('inbox.form.sessions_ALL')}
          >
            {(sessionEnums.status || []).map(
              (status) =>
                status !== 'PENDING' && (
                  <Select.Option value={status} key={status}>
                    {t(`inbox.form.sessions_${status}`)}
                  </Select.Option>
                )
            )}
            <Select.Option value="ALL">
              {t('inbox.form.sessions_ALL')}
            </Select.Option>
          </Select>
        </Row>
      </Col>
    </Row>
  );
};
