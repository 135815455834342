import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const FormationsContext = createContext({ isValid: false });

export const FormationsContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [formation, setFormation] = useState({});
  const [images, setImages] = useState({});
  const [surveys, setSurveys] = useState([]);
  const { user } = useAuthContext();

  const nextStep = (number) => {
    setStep(number + 1);
  };

  const previousStep = (number) => {
    setStep(number - 1);
  };

  const getSurveys = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `surveys`
      });
      setSurveys(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getSurveys();
  }, []);

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const formatBody = async () => {
    let image;
    const body = formation;
    const modules = formation.modules;
    modules?.forEach((module, index) => {
      const chapter_choice = (module?.chapters || []).find(
        (chapter) => chapter.type === 'CHALLENGE_CHOICE'
      );
      const chapter_index = (module?.chapters || []).findIndex(
        (chapter) => chapter.type === 'CHALLENGE_CHOICE'
      );
      if (chapter_choice) {
        chapter_choice.challenges.forEach((challenge, challenge_index) => {
          if (
            challenge.image?.file &&
            challenge.image?.file.status === 'removed'
          ) {
            (async () => {
              modules[index].chapters[chapter_index].challenges[
                challenge_index
              ].image = await fileToBase64(challenge.image.file);
            })();
          }
        });
      }
    });

    if (body.image?.file) {
      image = await fileToBase64(body.image.file);
    }

    return { ...body, modules, image };
  };

  const updateFormation = async (id) => {
    setIsSubmitting(true);
    try {
      const body = await formatBody();
      await dispatchAPI('PATCH', {
        url: `formations/${id}`,
        body
      });
      navigate(-1);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const createFormation = async () => {
    setIsSubmitting(true);
    try {
      const body = await formatBody();
      await dispatchAPI('POST', {
        url: `formations`,
        body: {
          ...body,
          trainer: {
            _id: user?.role === 'users:CONTRIBUTOR' ? user._id : body.trainer
          },
          status:
            user?.role === 'users:CONTRIBUTOR' ? 'DRAFT' : 'WAITING_VALIDATION'
        }
      });
      setIsSubmitting(false);
      navigate(-1);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  return (
    <FormationsContext.Provider
      value={{
        isSubmitting,
        updateFormation,
        createFormation,
        previousStep,
        nextStep,
        step,
        formation,
        setFormation,
        images,
        setImages,
        surveys
      }}
    >
      {children}
    </FormationsContext.Provider>
  );
};

export const useFormationsContext = () => {
  const context = useContext(FormationsContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
