import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { UserRouter } from '../routes/users/UserRouter';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import CatalogRouter from '../routes/Catalog/CatalogRouter';
import SettingsRouter from '../routes/settings/SettingsRouter';
import { ModelsRouter } from '../routes/Models/ModelsRouter';
import { SessionRouter } from '../routes/Sessions/SessionRouter';
import { SessionsContextProvider } from '../routes/Sessions/SessionsContext';
import { HomeContextProvider } from '../contexts/HomeContext';
import { OrdersRouter } from '../routes/orders/OrdersRouter';
import { ReportRouter } from '../routes/Reports/ReportRouter';
import { NewsletterRouter } from '../routes/Newsletter/NewsletterRouter';

const RequireAuth = ({ location }) => {
  const { isValid } = useAuthContext();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <AdminLayout />;
};

RequireAuth.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

RequireAuth.defaultProps = {
  location: { pathname: '/' }
};

export const AdminRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <HomeContextProvider>
        <AdminTheme />
        <SessionsContextProvider>
          <Routes>
            <Route
              path={`${outOfNavRoutes.LOGIN}/:token`}
              element={<Login />}
            />
            <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
            <Route
              path={`${outOfNavRoutes.CONFIRMATION}/:token`}
              element={<Confirmation />}
            />
            <Route element={<RequireAuth />}>
              <Route
                path={`${outOfNavRoutes.PROFILE}/*`}
                element={<ProfileRouter />}
              />
              <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
              <Route path={routes.HOME} element={<Home />} />
              <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
              <Route
                path={`${routes.TRAINING_SESSIONS}/*`}
                element={<SessionRouter />}
              />
              <Route path={`${routes.CATALOG}/*`} element={<CatalogRouter />} />
              <Route path={`${routes.REPORTS}/*`} element={<ReportRouter />} />
              <Route path={`${routes.ORDERS}/*`} element={<OrdersRouter />} />
              <Route path={`${routes.MODELS}/*`} element={<ModelsRouter />} />
              <Route
                path={`${routes.NEWSLETTER}/*`}
                element={<NewsletterRouter />}
              />
              <Route
                path={`${routes.SETTINGS}/*`}
                element={<SettingsRouter />}
              />
              <Route path="*" element={<Exception />} />
            </Route>
          </Routes>
        </SessionsContextProvider>
      </HomeContextProvider>
    </BrowserRouter>
  );
};
