import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  message,
  Col,
  List,
  Radio,
  Rate,
  Row
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useSessionsContext } from './SessionsContext';

export const ShowQuizz = () => {
  const [survey, setSurvey] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [responsesToPost, setResponsesToPost] = useState([]);
  const [responsesErrors, setResponsesErrors] = useState([]);
  const { sessionID } = useParams();
  const { errorMessage } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { navigate } = useSessionsContext();

  const getSurvey = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `surveys?type=intervenant_model`
      });
      setSurvey(...data);
    } catch (e) {
      errorMessage(e);
    }
  };

  const createQuizResponse = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: `/survey-responses`,
        body
      });
    } catch (e) {
      errorMessage(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getSurvey();
    })();
  }, []);

  useEffect(() => {
    if (survey.questions) {
      setQuestions(survey.questions);
    }
  }, [survey]);

  useEffect(() => {
    const listResponses = {
      session: sessionID,
      survey: survey._id,
      user: user._id,
      responses: []
    };
    questions.forEach((question) => {
      if (question.type === 'CHOICE_GRID') {
        listResponses.responses.push({
          question: question._id,
          mark: 0,
          grid_responses_list: []
        });
      } else {
        listResponses.responses.push({
          question: question._id,
          response: []
        });
      }
    });
    questions.forEach((question) => {
      if (question) {
        question.questions_list.forEach((el) => {
          listResponses.responses.forEach((response, index) => {
            if (response.question.toString() === question._id.toString()) {
              listResponses.responses[index].grid_responses_list.push({
                question: el._id
              });
            }
          });
        });
      }
    });
    setResponsesToPost(listResponses);
  }, [questions]);

  const addResponse = (type, index, value) => {
    const listResponses = responsesToPost;
    if (type === 'MULTI_CHOICE') {
      listResponses.responses[index].response.push(value);
    } else {
      listResponses.responses[index].response = [value];
    }
  };

  const checkRender = (type, options, indexQuestion) => {
    switch (type) {
      case 'UNIQUE_CHOICE': {
        return (
          <Radio.Group
            onChange={(value) =>
              addResponse(type, indexQuestion, value.target.value)
            }
          >
            {options.map((el) => (
              <Row>
                <Col span={24}>
                  <Radio value={el._id}>{el.title}</Radio>
                </Col>
              </Row>
            ))}
          </Radio.Group>
        );
      }
      case 'MULTI_CHOICE': {
        return (
          <>
            {options.map((el) => (
              <Row>
                <Col span={24}>
                  <Checkbox
                    value={el._id}
                    onChange={(value) =>
                      addResponse(type, indexQuestion, value.target.value)
                    }
                  />
                  <span>{el.title}</span>
                </Col>
              </Row>
            ))}
          </>
        );
      }
      case 'SCORE': {
        return (
          <Rate onChange={(value) => addResponse(type, indexQuestion, value)} />
        );
      }
      default: {
        return (
          <TextArea
            onChange={(value) =>
              addResponse(type, indexQuestion, value.target.value)
            }
          />
        );
      }
    }
  };

  const submitQuiz = async () => {
    const listErrors = [];
    responsesToPost.responses.map((responseItem) =>
      responseItem.response.forEach((response) => {
        if (response.response && !response.response.length) {
          listErrors.push(response.question);
        } else if (response.grid_response_list) {
          response.grid_response_list.forEach((el) => {
            if (!el.response) {
              listErrors.push(response.question);
            }
          });
        }
      })
    );
    if (listErrors.length) {
      errorMessage(`Il vous reste ${listErrors.length} champs à remplir`);
    } else {
      message.success('Vos réponses ont été enregistrées');
      await createQuizResponse(responsesToPost);
      navigate(-1);
    }
    setResponsesErrors(listErrors);
  };

  return (
    <>
      <PageHeaderCustom
        title={t('training_sessions.form.satisfaction_quizz')}
      />
      <Card style={{ marginTop: '20px' }}>
        <List
          grid={{ column: 1 }}
          dataSource={questions}
          renderItem={(item, index) => (
            <List.Item>
              <Card title={item.title}>
                {responsesErrors.includes(item._id) ? (
                  <Alert
                    type="error"
                    message={checkRender(
                      item.type,
                      item.answer_options,
                      index,
                      item.questions_list
                    )}
                  />
                ) : (
                  checkRender(
                    item.type,
                    item.answer_options,
                    index,
                    item.questions_list
                  )
                )}
              </Card>
            </List.Item>
          )}
        />
        <Row style={{ marginTop: '2rem' }} justify="end">
          <Button onClick={() => navigate(-1)} danger type="link">
            <CloseOutlined />
            {`${t('buttons.cancel')} `}
          </Button>

          <Button type="add" htmlType="submit" onClick={submitQuiz}>
            {`${t('buttons.save_answers')} `}
            <CheckOutlined />
          </Button>
        </Row>
      </Card>
    </>
  );
};
