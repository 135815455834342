import { CheckCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Datatable } from '../../components';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';
import { sessionsStatus } from '../../utils/constants/tagColors';
import { UserBadges } from '../Sessions/UserBadges';

const ListUsers = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const extraQuery = `subscribers.user=${id}`;

  const findProgress = (array) => {
    const { progress } = array.find((item) => item.user === id);

    const totalChapters =
      progress?.reduce((total, module) => total + module.chapters.length, 0) ||
      0;
    const completedChapters =
      progress?.reduce(
        (total, module) =>
          total + module.chapters.filter((chapter) => chapter.completed).length,
        0
      ) || 0;

    return Math.round((completedChapters / totalChapters) * 100);
  };

  const columns = [
    {
      title: t('formations.form.name'),
      key: 'formation',
      dataIndex: 'formation',
      sorter: true,
      render: (formation) => formation.name
    },
    {
      title: t('formations.form.progress'),
      render: (record) =>
        record.status !== 'RUNNING' ? (
          <Tag color={sessionsStatus[record.status]} className="status-tag">
            {t(`training_sessions.form.${record.status}`)}
          </Tag>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 8
            }}
          >
            <div
              style={{
                width:
                  findProgress(record.subscribers) !== 100
                    ? `calc(${findProgress(record.subscribers)}% - 20px)`
                    : '100%',
                height: 8,
                borderRadius: 8,
                backgroundColor:
                  findProgress(record.subscribers) !== 100
                    ? '#FFD33B'
                    : '#71D8CC'
              }}
            />
            <div style={{ width: '20px' }}>
              {findProgress(record.subscribers) !== 100 ? (
                `${findProgress(record.subscribers)} %`
              ) : (
                <CheckCircleOutlined style={{ color: '#71D8CC' }} />
              )}
            </div>
          </div>
        )
    },
    {
      title: t('formations.form.badges'),
      render: (record) => (
        <UserBadges formationID={record.formation._id} userID={id} />
      )
    },
    {
      title: t('formations.form.certificate'),
      key: 'certificate',
      dataIndex: 'certificate',
      render: (certificate) => certificate || t('errors.message.not_obtained')
    },
    {
      render: (record) => (
        <Link
          to={{
            pathname: `${routes.CATALOG}${subRoutes.CATALOG.FORMATIONS}/show/${record.formation._id}`
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];

  return (
    <Datatable
      resourceName="sessions"
      columns={columns}
      extraQuery={extraQuery}
      populate="formation"
      resourceModelName="Session"
      customActionColumn
      onDoubleClickAction={{
        action: (record) =>
          navigate(`${routes.TRAINING_SESSIONS}/show/${record._id}`)
      }}
    />
  );
};

export default ListUsers;
