import { Button, Row, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { routes } from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { ordersStatus } from '../../utils/constants/tagColors';
import { PopoverOrderList } from './PopoverOrderList';

const iconSize = 18;

export const useColumns = (forceRefresh, setForceRefresh) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [enums, setEnums] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'orders/enums'
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const updateStatus = async (id, status, purpose) => {
    const getAction = () => {
      switch (status) {
        case enums.status[0]:
          return enums.status[1];
        case enums.status[1]:
          return enums.status[2];
        default:
          return enums.status[1];
      }
    };
    try {
      await dispatchAPI('PATCH', {
        url: `orders/update-status/${id}`,
        body: {
          current_status: purpose === 'refused' ? enums.status[3] : getAction()
        }
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const generatePdfOrder = async (id) => {
    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `orders/pdfFile/${id}?populate=customer,articles.created_by`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        type: `${'application/pdf'}`
      });

      const fileName = headers['content-disposition'].substring(
        headers['content-disposition'].indexOf('=') + 1,
        headers['content-disposition'].length
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    } catch (e) {
      message(e);
    }
  };

  const actionColumns = [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 125,
      render: (record) => (
        <Row align="middle" justify="end" style={{ gap: 16 }}>
          <Link
            to={{
              pathname: `${routes.ORDERS}/show/${record._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <PopoverOrderList
            updateStatus={updateStatus}
            record={record}
            enums={enums}
          />

          {record.current_status === enums.status[2] && (
            <div>
              {record.billing_adress.street ? (
                <Button
                  type="link"
                  onClick={() => generatePdfOrder(record._id)}
                  style={{ padding: 0 }}
                >
                  <DownloadOutlined style={{ fontSize: iconSize }} />
                </Button>
              ) : (
                <Tooltip title={t('orders.form.fill_address')}>
                  <DownloadOutlined style={{ fontSize: iconSize }} />
                </Tooltip>
              )}
            </div>
          )}
        </Row>
      )
    }
  ];

  const columns = [
    {
      title: t('orders.form.number'),
      key: 'number',
      dataIndex: 'number',
      sorter: true,
      width: '10%'
    },
    {
      title: t('orders.form.customer'),
      key: 'customer',
      dataIndex: 'customer',
      sorter: true,
      render: (customer) => `${customer?.first_name} ${customer?.last_name}`,
      width: '15%'
    },
    {
      title: t('orders.form.date'),
      key: 'date',
      dataIndex: 'date',
      sorter: true,
      render: (date) => moment(date).format('DD-MM-YYYY'),
      width: '10%'
    },
    {
      title: t('orders.form.status'),
      key: 'current_status',
      dataIndex: 'current_status',
      sorter: true,
      render: (status) => (
        <Tag color={ordersStatus[status]}>{t(`orders.status.${status}`)}</Tag>
      ),
      width: '15%'
    },
    {
      title: t('orders.form.totalCost'),
      key: 'total_cost',
      dataIndex: 'total_cost',
      sorter: true,
      render: (total_cost) => `${total_cost}€`,
      width: '10%'
    },
    {
      title: t('orders.form.adress'),
      key: 'billing_adress',
      dataIndex: 'billing_adress',
      sorter: true,
      render: (billing_adress) =>
        billing_adress.street
          ? `${billing_adress.street} ${billing_adress.postal_code} ${billing_adress.city}`
          : `${t('errors.message.not_specified')}`,
      width: '30%'
    }
  ];

  return {
    actionColumns,
    columns
  };
};
