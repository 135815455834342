import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Form, Tooltip } from 'antd';
import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const SurveysContext = createContext({ isValid: false });

export const SurveysContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [forceFormDataRefresh, setForceFormDataRefresh] = useState(true);

  const newQuestion = (type) => {
    let question = {};
    switch (type) {
      case 'CHOICE_GRID':
        question = {
          title: 'Question',
          type,
          answer_options: [
            {
              title: '1'
            },
            {
              title: '2'
            },
            {
              title: '3'
            }
          ],
          questions_list: [
            {
              title: '1'
            },
            {
              title: '2'
            },
            {
              title: '3'
            }
          ]
        };
        break;
      case 'TEXT':
        question = {
          title: 'Question',
          type
        };
        break;
      default: {
        question = {
          title: 'Question',
          type,
          answer_options: [
            {
              title: '1'
            },
            {
              title: '2'
            },
            {
              title: '3'
            }
          ]
        };
      }
    }
    return question;
  };

  const extraCardButtons = (
    moveQuestion,
    removeQuestion,
    questionIndex,
    optionIndex
  ) => (
    <>
      <Tooltip title="Changer de position">
        <Button
          type="link"
          disabled={(!optionIndex && questionIndex === 0) || optionIndex === 0}
          onClick={() =>
            moveQuestion(
              optionIndex ?? questionIndex,
              (optionIndex ?? questionIndex) - 1
            )
          }
          icon={<UpOutlined />}
        />
        <Button
          type="link"
          disabled={
            (!optionIndex ? questionIndex : optionIndex) ===
            form.getFieldValue(
              optionIndex >= 0
                ? ['questions', questionIndex, 'answer_options']
                : 'questions'
            ).length -
              1
          }
          onClick={() =>
            moveQuestion(
              optionIndex ?? questionIndex,
              (optionIndex ?? questionIndex) + 1
            )
          }
          icon={<DownOutlined />}
        />
      </Tooltip>
      <Button
        type="link"
        icon={<CloseOutlined />}
        onClick={() => removeQuestion(questionIndex)}
      />
    </>
  );

  const updateSurvey = async (body, id) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `surveys/${id}`,
        body
      });
      navigate(-1);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  const createSurvey = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('POST', {
        url: `surveys`,
        body
      });
      setIsSubmitting(false);
      navigate(-1);
    } catch (e) {
      setIsSubmitting(false);
      message(e);
    }
  };

  return (
    <SurveysContext.Provider
      value={{
        isSubmitting,
        updateSurvey,
        createSurvey,
        extraCardButtons,
        form,
        newQuestion,
        forceFormDataRefresh,
        setForceFormDataRefresh
      }}
    >
      {children}
    </SurveysContext.Provider>
  );
};

export const useSurveysContext = () => {
  const context = useContext(SurveysContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
