import { Route, Routes } from 'react-router-dom';
import { Newsletter } from './Newsletter';
import { CreateUpdateNewsletterEmail } from './CreateUpdateNewsletterEmail';

export const NewsletterRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateNewsletterEmail purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateNewsletterEmail purpose="edit" />}
    />
    <Route index element={<Newsletter />} />
  </Routes>
);
