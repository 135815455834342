import { Tag, Row } from 'antd';

import { formationsStatus } from '../../../utils/constants/tagColors';
import { NextAndLastSession } from '../../users/NextAndLastSession';

export const columns = (t) => [
  {
    title: t('formations.form.name'),
    key: 'name',
    dataIndex: 'name',
    sorter: true,
    width: '15%'
  },
  {
    title: t('formations.form.contributor'),
    key: 'trainer',
    sorter: true,
    dataIndex: 'trainer',
    render: (trainer) =>
      trainer && trainer.first_name && trainer.last_name ? (
        <>
          {trainer.first_name} {trainer.last_name}
        </>
      ) : (
        '-'
      )
  },
  {
    title: t('formations.form.status'),
    sorter: true,
    dataIndex: 'status',
    width: '10%',
    render: (status) =>
      (status && (
        <Tag color={formationsStatus[status]} className="status-tag">
          {t(`formations.tags.${status}`)}
        </Tag>
      )) ||
      '-'
  },
  {
    title: t('formations.form.last_session'),
    sorter: true,
    render: (record) => (
      <NextAndLastSession
        formationID={record._id}
        contributorID={record.trainer._id}
        purpose="last"
      />
    )
  },
  {
    title: t('formations.form.next_session'),
    sorter: true,
    render: (record) => (
      <NextAndLastSession
        formationID={record._id}
        contributorID={record.trainer._id}
        purpose="next"
      />
    )
  },
  {
    title: t('formations.form.categories'),
    key: 'categories',
    sorter: true,
    width: '25%',
    dataIndex: 'categories',
    render: (categories) => (
      <Row style={{ flexWrap: 'wrap' }} gutter={[0, 4]}>
        {categories.map((tag) => (
          <Tag
            key={tag._id}
            className="course-category"
            style={{ color: tag.color }}
          >
            {tag.name}
          </Tag>
        ))}
      </Row>
    )
  }
];
