import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useSocketContext } from './SocketContext';
import { useErrorMessage } from '../utils/ErrorMessage';

const MessagesContext = createContext({});

export const MessagesContextProvider = ({ children }) => {
  const { dispatchAPI, user, token } = useAuthContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const { socket } = useSocketContext();
  const { message } = useErrorMessage();
  const [users, setUsers] = useState([]);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [threadsToDisplay, setThreadsToDisplay] = useState([]);
  const [threadItem, setThreadItem] = useState();
  const [sessionsFilter, setSessionsFilter] = useState();
  const [unreadCount, setUnreadCount] = useState();
  const [sessionStatus, setSessionStatus] = useState('ALL');
  const [sessionEnums, setSessionEnums] = useState([]);
  const [userThreads, setUserThreads] = useState();

  const handleSwitchSession = (status) => {
    setSessionStatus(status);
    setForceRefresh(!forceRefresh);
    setThreadsToDisplay([]);
    setThreadItem();
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: sessionsFilter
          ? `/sessions/${sessionsFilter}?populate=subscribers.user`
          : `/users?role=users:TRAINEE`
      });
      if (sessionsFilter) {
        setUsers(data.subscribers);
      } else {
        setUsers(data);
      }
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getUsers();
    })();
  }, [sessionsFilter]);

  const getMessages = async ({ reset = false, stopIfLoading = false }) => {
    if (stopIfLoading && isLoading) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `messages?populate=sender,recipient,attachments,thread.session&skip=${
          reset ? 0 : messages.length
        }`
      });
      setMessages([...(reset ? [] : messages), ...data]);
    } catch (e) {
      setMessages([]);
      if (e) message(e);
    }
  };

  const getUnreadCount = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `messages/unread-count`
      });
      setUnreadCount(data);
    } catch (e) {
      message(e);
    }
  };

  const getUserThreads = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `threads/userThreads?populate=session,messages`
      });
      setUserThreads(data);
    } catch (e) {
      message(e);
    }
  };

  const getThread = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `threads/formattedThread/${id}?populate=messages.attachments`
      });
      setThreadItem(data);
    } catch (e) {
      message(e);
    }
  };

  const getSessionEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'sessions/enums'
      });
      setSessionEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getSessionEnums();
    })();
  }, []);

  useEffect(() => {
    if (socket && token) {
      socket.on('newMessage', async () => {
        await getMessages({ reset: true });
        await getUserThreads();
        await getUnreadCount();
      });
    }
  }, [socket, token]);

  useEffect(() => {
    if (token) {
      (async () => {
        if (!['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user?.role)) {
          await getMessages({ reset: true });
          await getUserThreads();
          await getUnreadCount();
          if (threadItem) {
            await getThread(threadItem._id);
          }
          setIsLoading(false);
        }
      })();
    }
  }, [forceRefresh, token]);

  return (
    <MessagesContext.Provider
      value={{
        messages,
        setMessages,
        selectedMessages,
        setSelectedMessages,
        sessionsFilter,
        setSessionsFilter,
        forceRefresh,
        setForceRefresh,
        users,
        isLoading,
        getMessages,
        handleSwitchSession,
        threadsToDisplay,
        setThreadsToDisplay,
        threadItem,
        setThreadItem,
        unreadCount,
        userThreads,
        getThread,
        sessionStatus,
        setSessionStatus,
        sessionEnums,
        setSessionEnums
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessagesContext = () => {
  const context = useContext(MessagesContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
