import PropTypes from 'prop-types';
import { Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const DescriptionList = ({ title, data, skipEmpty, translate }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Descriptions title={title}>
        {data.map(
          (item) =>
            (item.content !== '-' || !skipEmpty) && (
              <Descriptions.Item
                label={translate ? t(`${item.label}`) : item.label}
                span={item.span || 1}
                key={item.label}
              >
                <Typography>{item.content || '-'}</Typography>
              </Descriptions.Item>
            )
        )}
      </Descriptions>
    </div>
  );
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skipEmpty: PropTypes.bool,
  translate: PropTypes.bool
};

DescriptionList.defaultProps = {
  title: null,
  skipEmpty: false,
  translate: false
};
