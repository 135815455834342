import { Button, Col, Form, InputNumber, Modal, Row, Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import {
  CheckOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { routes } from '../../utils/constants/adminRoutes';
import { useSessionsContext } from './SessionsContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { formItemLayout } from '../../utils/constants/formLayout';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const AddSubscriberModal = ({ visible, handleCloseModal, session }) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { users, forceRefresh, setForceRefresh } = useSessionsContext();
  const [form] = Form.useForm();
  const [opcos, setOpcos] = useState();

  const addUsersToSession = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/sessions/add-subscribers/${session._id}`,
        body
      });
      setForceRefresh(!forceRefresh);
      form.resetFields();
    } catch (e) {
      message(e);
    }
  };

  const getOPCOS = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `opcos`
      });
      setOpcos(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOPCOS();
    })();
  }, []);

  return (
    <Modal
      visible={visible}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      maskClosable={false}
      width={800}
      open={visible}
      footer={null}
      title={t('training_sessions.modals.add_subscriber')}
      className="session-modal"
    >
      <Form onFinish={addUsersToSession} {...formItemLayout} form={form}>
        <Form.List name="subscribers">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row
                  key={key}
                  style={{
                    display: 'flex',
                    width: '100%',
                    marginBottom: 16
                  }}
                  align="baseline"
                >
                  <Col xs={3} lg={1}>
                    <span className="session-class-number">{key + 1}</span>
                  </Col>
                  <Col xs={19} lg={22}>
                    <Row align="middle" style={{ gap: 10 }}>
                      <Form.Item {...restField} name={[name, 'user']}>
                        <Select
                          placeholder={t(
                            'training_sessions.modals.choose_user'
                          )}
                          style={{ width: '16vw', minWidth: 210 }}
                        >
                          {(users || [])
                            .filter(
                              (user) =>
                                !session?.subscribers?.some(
                                  (subscriber) =>
                                    user?._id === subscriber?.user?._id
                                )
                            )
                            .map((user) => (
                              <Option
                                key={user._id}
                                value={user._id}
                              >{`${user.first_name} ${user.last_name}`}</Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item {...restField} name={[name, 'opco', 'title']}>
                        <Select
                          placeholder={t(
                            'training_sessions.modals.choose_opco'
                          )}
                          style={{ width: '16vw', minWidth: 210 }}
                        >
                          {(opcos || []).map((opco) => (
                            <Option key={opco._id} value={opco._id}>
                              {opco.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'opco', 'amount']}
                        style={{ display: 'flex' }}
                      >
                        <InputNumber
                          placeholder={t(
                            'training_sessions.modals.choose_opco_amount'
                          )}
                          style={{ width: '16vw', minWidth: 210 }}
                          min={0}
                        />
                      </Form.Item>
                    </Row>
                  </Col>

                  <Col xs={2} lg={1} style={{ paddingTop: 3 }}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {t('training_sessions.modals.add_subscriber')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Button type="add" htmlType="submit" onClick={handleCloseModal}>
          {`${t('buttons.save')} `}
          <CheckOutlined />
        </Button>
      </Form>

      <h4 style={{ marginTop: 16 }}>
        {t('training_sessions.modals.dont_find_user')}
        <Link to={`${routes.USERS}/create`}>
          <span>{t('training_sessions.modals.link')}</span>
        </Link>
      </h4>
    </Modal>
  );
};

AddSubscriberModal.propTypes = {
  visible: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  session: PropTypes.shape({
    _id: PropTypes.string,
    subscribers: PropTypes.shape([])
  })
};

AddSubscriberModal.defaultProps = {
  visible: false,
  handleCloseModal: null,
  session: null
};
