import { Card, Row, Skeleton, Col } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DescriptionList, PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { OrderList } from './List/OrderList';
import { ShowOrderRemarks } from './ShowOrderRemarks';
import { ShowOrderTable } from './ShowOrderTable';
import { StatusHistory } from './StatusHistory';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';

export const ShowOrder = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [orderData, setOrderData] = useState();

  const getOrderData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `orders/${id}?populate=customer,articles,comment.written_by,status_history.modified_by`
      });
      setOrderData(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOrderData();
    })();
  }, [isLoading]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('orders.form.order_number')}${orderData?.number}`}
      />
      <ContentCustom>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={16}>
            <Card title={t('orders.form.infos')} className="formation-card">
              <Skeleton active loading={isLoading} paragraph={{ rows: 3 }}>
                <DescriptionList
                  data={OrderList(orderData || {}, t)}
                  translate
                />
              </Skeleton>
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <StatusHistory orderData={orderData} isLoading={isLoading} />
          </Col>
        </Row>
        <ShowOrderTable orderData={orderData?.articles} />
        <ShowOrderRemarks
          orderData={orderData?.comment}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </ContentCustom>
    </>
  );
};
