import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

export const NoMessages = () => {
  const { t } = useTranslation();
  return (
    <Card
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 0
      }}
    >
      {t('inbox.form.no_messages')}
    </Card>
  );
};
