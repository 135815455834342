import {
  CheckmarkOutline,
  Download,
  MisuseOutline,
  Warning
} from '@carbon/icons-react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Divider, Dropdown, Menu, Row, List } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { useAuthContext } from '../../../contexts/AuthContext';

export const HomeworksDeposits = ({ sessionData }) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { subscriberID } = useParams();
  const { downloadDocument } = useDownloadDocument();
  const navigate = useNavigate();

  const onCheckHomework = async (file, purpose) => {
    try {
      await dispatchAPI('PATCH', {
        url: `formations/${sessionData?.formation?._id}/${purpose}/${file._id}`,
        body: {
          user: file.trainee,
          formation: sessionData?.formation?._id,
          chapter: file?.chapter,
          type: 'badge'
        }
      });
    } catch (e) {
      message(e);
    }
  };

  const updateUserProgression = async (file, purpose) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/sessions/update-user-progression/${sessionData?._id}`,
        body: {
          module: file.module,
          chapter: file.chapter,
          badge: purpose === 'VALIDATED' ? 'ACHIEVED' : 'FAILED',
          user: subscriberID
        }
      });
    } catch (e) {
      message(e);
    }
  };

  const handleCheckHomework = (file, purpose) => {
    onCheckHomework(file, purpose);
    updateUserProgression(file, purpose);
    navigate(0);
  };

  const menuDropDown = (file) => (
    <Menu>
      <Menu.Item key="1">
        <Button
          onClick={() => handleCheckHomework(file, 'VALIDATED')}
          type="link"
          icon={<CheckmarkOutline color="green" size={18} />}
        />
      </Menu.Item>
      <Menu.Item key="2">
        <Button
          onClick={() => handleCheckHomework(file, 'REJECTED')}
          type="link"
          icon={<MisuseOutline color="red" size={18} />}
        />
      </Menu.Item>
    </Menu>
  );
  return (
    <List
      dataSource={sessionData?.formation?.files.filter(
        (el) => el.trainee === subscriberID
      )}
      renderItem={(item) => {
        const module = (sessionData?.formation?.modules || []).find(
          (el) => el._id === item.module
        );
        const chapter = (module?.chapters || []).find(
          (el) => el._id === item.chapter
        );
        return (
          <List.Item>
            <Card style={{ width: '100%' }}>
              <Row justify="space-between">
                <Col>{`Module ${module?.title} - ${chapter?.title}`}</Col>
                <Col>
                  <Button
                    type="link"
                    icon={<Download size={18} />}
                    onClick={() => downloadDocument(item)}
                  />
                  <Divider type="vertical" />
                  {item?.result === 'REJECTED' && (
                    <MisuseOutline color="red" size={18} />
                  )}
                  {item?.result === 'VALIDATED' && (
                    <CheckmarkOutline color="green" size={18} />
                  )}
                  {item?.result === 'PENDING' && (
                    <Dropdown overlay={() => menuDropDown(item)}>
                      <Button
                        type="link"
                        icon={<Warning color="yellow" size={18} />}
                      />
                    </Dropdown>
                  )}
                </Col>
              </Row>
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

HomeworksDeposits.propTypes = {
  sessionData: PropTypes.shape({
    formation: PropTypes.shape({
      _id: PropTypes.string,
      files: PropTypes.arrayOf(PropTypes.shape({})),
      modules: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    _id: PropTypes.string.isRequired
  }).isRequired
};
