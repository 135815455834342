import { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/ErrorMessage';

const HomeContext = createContext({ isValid: false });

export const HomeContextProvider = ({ children }) => {
  const { dispatchAPI, user, token } = useAuthContext();
  const { message } = useErrorMessage();
  const [activeSessions, setactiveSessions] = useState();
  const [activeSubscribers, setActiveSubscribers] = useState();
  const [formations, setFormations] = useState();
  const [totalCertified, setTotalCertified] = useState();
  const [formationFilter, setFormationFilter] = useState(null);
  const [completionRateFormation, setCompletionRateFormation] = useState();
  const [completionRateMasterClass, setCompletionRateMasterClass] = useState();
  const [masterclassFilter, setMasterclassFilter] = useState();
  const [traineesFilter, setTraineesFilter] = useState();
  const [purchasersCount, setPurchasersCount] = useState();
  const [masterclasses, setMasterClasses] = useState();
  const [sales, setSales] = useState();
  const [trainees, setTrainees] = useState();

  const getFormations = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/formations?${
          user.role === 'users:CONTRIBUTOR' ? `trainer=${user._id}` : ''
        } `
      });
      setFormations(data);
    } catch (e) {
      message(e);
    }
  };

  const getActiveSessions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `sessions/active-sessions?${
          user?.role === 'CONTRIBUTOR' ? `contributor=${user._id}` : ''
        }&populate=formation${
          formationFilter ? `&formation=${formationFilter}` : ''
        }`
      });
      setactiveSessions(data.activeSessions);
      setActiveSubscribers(data.numberOfActiveSubscribers);
    } catch (e) {
      message(e);
    }
  };

  const getCertifiedTotal = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `sessions/certified${
          user.role === 'users:CONTRIBUTOR' ? `?contributor=${user._id}` : ''
        }${
          formationFilter
            ? `${
                user.role === 'users:CONTRIBUTOR' ? '&' : '?'
              }formation=${formationFilter}`
            : ''
        }`
      });
      setTotalCertified(data);
    } catch (e) {
      message(e);
    }
  };

  const getCompletionRateFormation = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `sessions/completion-rate/${
          user.role === 'users:CONTRIBUTOR' ? `?contributor=${user._id}` : ''
        }${
          formationFilter
            ? `${
                user.role === 'users:CONTRIBUTOR' ? '&' : '?'
              }formation=${formationFilter}`
            : ''
        }`
      });
      setCompletionRateFormation(data);
    } catch (e) {
      message(e);
    }
  };

  const getCompletionRateMasterClass = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `master-classes/completion-rate?status=PUBLISHED${
          user.role === 'users:CONTRIBUTOR' ? `&created_by=${user._id}` : ''
        }${masterclassFilter ? `&_id=${masterclassFilter}` : ''}${
          traineesFilter ? `&purchasers.user=${traineesFilter}` : ''
        }`
      });
      setCompletionRateMasterClass(data);
    } catch (e) {
      message(e);
    }
  };

  const getSubscribersCount = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/master-classes/purchasers-count?status=PUBLISHED${
          masterclassFilter ? `&_id=${masterclassFilter}` : ''
        }`
      });
      setPurchasersCount(data);
    } catch (e) {
      message(e);
    }
  };

  const getMasterClasses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `master-classes?status=PUBLISHED&purchasers.length>0${
          traineesFilter ? `&purchasers.user=${traineesFilter}` : ''
        }`
      });
      setMasterClasses(data);
    } catch (e) {
      message(e);
    }
  };

  const getTrainees = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: masterclassFilter
          ? `master-classes/${masterclassFilter}?populate=purchasers.user`
          : `users?role=users:TRAINEE`
      });
      if (masterclassFilter) {
        setTrainees(data.purchasers);
      } else {
        setTrainees(data);
      }
    } catch (e) {
      message(e);
    }
  };

  const getSales = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `orders/sales${
          traineesFilter ? `?customer=${traineesFilter}` : ''
        }${
          masterclassFilter
            ? `${traineesFilter ? '&' : '?'}articles=${masterclassFilter}`
            : ''
        }`
      });
      setSales(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (token) {
      (async () => {
        await getSubscribersCount();
        await getMasterClasses();
        await getSales();
        await getTrainees();
      })();
    }
  }, [masterclassFilter, traineesFilter, token]);

  useEffect(() => {
    if (token) {
      (async () => {
        await getFormations();
        await getActiveSessions();
        await getCertifiedTotal();
        await getCompletionRateFormation();
        await getCompletionRateMasterClass();
      })();
    }
  }, [formationFilter, masterclassFilter, traineesFilter, token]);

  return (
    <HomeContext.Provider
      value={{
        activeSessions,
        activeSubscribers,
        formations,
        totalCertified,
        formationFilter,
        setFormationFilter,
        completionRateFormation,
        completionRateMasterClass,
        traineesFilter,
        masterclassFilter,
        setMasterclassFilter,
        setTraineesFilter,
        purchasersCount,
        masterclasses,
        sales,
        trainees
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export const useHomeContext = () => {
  const context = useContext(HomeContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};
