import { useAuthContext } from '../../contexts/AuthContext';
import { AdminHome } from './admin/AdminHome';
import { UserHome } from './contributor/UserHome';

export const Home = () => {
  const { user } = useAuthContext();

  return (
    <div className="home-wrapper">
      {user?.role === 'users:CONTRIBUTOR' ? <UserHome /> : <AdminHome />}
    </div>
  );
};
