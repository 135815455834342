import { useTranslation } from 'react-i18next';

const learnerProfileList = (data) => {
  const { learner_profile } = data;
  const { t } = useTranslation();

  return [
    {
      label: 'formations.form.learner_prerequisite',
      span: 3,
      content:
        (learner_profile && learner_profile.learner_prerequisite) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.learner_public',
      span: 3,
      content:
        (learner_profile && learner_profile.learner_public) ||
        `${t('errors.message.not_specified')}`
    }
  ];
};

export default learnerProfileList;
