import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/ErrorMessage';

const DiscussionsContext = createContext({});

export const DiscussionsContextProvider = ({ children }) => {
  const { user, dispatchAPI, token } = useAuthContext();
  const { message } = useErrorMessage();
  const [unreadCommentariesCount, setUnreadCommentariesCount] = useState();
  const [commentaries, setCommentaries] = useState();
  const [chapterDiscussions, setChapterDiscussions] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [discussionItem, setDiscussionItem] = useState();
  const [chaptersToDisplay, setChaptersToDisplay] = useState();
  const [sessionStatus, setSessionStatus] = useState('ALL');
  const [sessionEnums, setSessionEnums] = useState([]);
  const editorFocus = useRef(null);
  const [purpose, setPurpose] = useState('new');
  const [isLoading, setIsLoading] = useState(true);

  const getUnreadCommentariesCount = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/commentaries/count?read_by!=${user._id}&sender!=${user._id}`
      });
      setUnreadCommentariesCount(data);
    } catch (e) {
      message(e);
    }
  };

  const handleSwitchSession = (status) => {
    setSessionStatus(status);
    setDiscussionItem();
    setChaptersToDisplay();
    setChapterDiscussions();
  };

  const getCommentaries = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `commentaries?populate=session,sender,attachments`
      });
      setCommentaries(data);
    } catch (e) {
      message(e);
    }
  };

  const getChapterDiscussions = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `commentaries?${
          id ? `chapter=${id}&` : 'chapter=null&'
        }&!reply_to.0&populate=sender,module,chapter`
      });
      setChapterDiscussions(data);
    } catch (e) {
      message(e);
    }
  };

  const handleReply = (discussion) => {
    setDiscussionItem(discussion);
    setPurpose('reply');
    editorFocus.current?.scrollIntoView({ behaviour: 'smooth' });
  };

  const getSessionEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'sessions/enums'
      });
      setSessionEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getSessionEnums();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (
        token &&
        !['admins:ADMIN', 'admins:SUPER-ADMIN'].includes(user.role)
      ) {
        await getUnreadCommentariesCount();
        await getCommentaries();
      }
      setIsLoading(false);
    })();
  }, [forceRefresh]);

  return (
    <DiscussionsContext.Provider
      value={{
        unreadCommentariesCount,
        commentaries,
        chapterDiscussions,
        getChapterDiscussions,
        setChapterDiscussions,
        discussionItem,
        setDiscussionItem,
        forceRefresh,
        setForceRefresh,
        handleReply,
        handleSwitchSession,
        editorFocus,
        purpose,
        setPurpose,
        sessionStatus,
        setSessionStatus,
        chaptersToDisplay,
        setChaptersToDisplay,
        sessionEnums,
        setSessionEnums,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </DiscussionsContext.Provider>
  );
};

export const useDiscussionsContext = () => {
  const context = useContext(DiscussionsContext);
  if (!context)
    throw new Error('Context must be used within a context provider');
  return context;
};
