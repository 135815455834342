import { Card, Col, Row, Select } from 'antd';
import { t } from 'i18next';
import { DonutChart } from './DonutChart';
import { useHomeContext } from '../../contexts/HomeContext';
import { useAuthContext } from '../../contexts/AuthContext';

export const SatisfactionRate = () => {
  const {
    setFormationFilter,
    activeSessions,
    activeSubscribers,
    formations,
    totalCertified,
    formationFilter,
    completionRateFormation
  } = useHomeContext();
  const { user } = useAuthContext();

  return (
    <Card
      className="home-card"
      title={
        user?.role === 'users:CONTRIBUTOR' ? (
          <p>{t('home.titles.satisfaction_rate')}</p>
        ) : (
          <Row justify="space-between" align="middle" gutter={[0, 16]}>
            <p>{t('home.titles.formations')}</p>
            <Select
              style={{ width: 320 }}
              onSelect={(value) => setFormationFilter(value)}
              placeholder="Toutes les formations"
            >
              <Select.Option value="">
                {' '}
                {t('home.form.all_formations')}
              </Select.Option>
              {(formations || []).map((formation) => (
                <Select.Option value={formation._id} key={formation._id}>
                  {formation.name}
                </Select.Option>
              ))}
            </Select>
          </Row>
        )
      }
    >
      <Row justify="space-between" gutter={[0, 16]} className="home-indicator">
        {!formationFilter && (
          <Col>
            <Row>{formations?.length}</Row>
            <Row>{t('home.form.published_formations')}</Row>
          </Col>
        )}

        <Col>
          <Row>{totalCertified}</Row>
          <Row>{t('home.form.certified')}</Row>
        </Col>
        <Col>
          <Row>{activeSessions?.length}</Row>
          <Row>{t('home.form.sessions_in_progress')}</Row>
        </Col>
        <Col>
          <Row>{activeSubscribers}</Row>{' '}
          <Row>{t('home.form.active_trainees')}</Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Row justify="center">
            <DonutChart donutData={completionRateFormation} />
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
