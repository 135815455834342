import { Button, Row } from 'antd';
import {
  CheckSquareOutlined,
  EditOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListResource } from '../../../components';
import { useChartColumns } from '../ChartColumns';
import { routes } from '../../../utils/constants/adminRoutes';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useSessionsContext } from '../SessionsContext';

const iconSize = 18;

export const TableView = ({ openEditModal }) => {
  const { patchSession, forceRefresh } = useSessionsContext();
  const columns = useChartColumns();
  const { user } = useAuthContext();
  const headers = [
    {
      label: 'formation',
      key: 'formation.name'
    },
    {
      label: 'contributor',
      key: 'contributor'
    },
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'start_date',
      key: 'start_date'
    },
    {
      label: 'end_date',
      key: 'end_date'
    }
  ];

  const actionColumn = [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 125,
      render: (record) => (
        <Row align="middle" justify="end" style={{ gap: 16 }}>
          {['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user?.role) &&
            record.status === 'PENDING' && (
              <Button
                type="link"
                onClick={() => patchSession(record._id)}
                style={{ padding: 0 }}
              >
                <CheckSquareOutlined style={{ fontSize: iconSize }} />
              </Button>
            )}
          <Link
            to={{
              pathname: `${routes.TRAINING_SESSIONS}/show/${record._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {['PUBLISHED', 'PENDING', 'RUNNING'].includes(record.status) && (
            <Button
              type="link"
              onClick={() => openEditModal(record._id)}
              style={{ padding: 0 }}
            >
              <EditOutlined style={{ fontSize: iconSize }} />
            </Button>
          )}
        </Row>
      )
    }
  ];

  return (
    <ListResource
      resourceName="sessions"
      populate="contributor,formation"
      headers={headers}
      extraQuery={
        user?.role === 'users:CONTRIBUTOR' ? `contributor=${user._id}` : ''
      }
      searchAddRow={false}
      columns={[...columns, ...actionColumn]}
      resourceModelName="Session"
      scroll={{ x: 1200 }}
      withoutHeader={false}
      withUploadButton={false}
      forceRefresh={forceRefresh}
      customActionColumn
    />
  );
};

TableView.propTypes = {
  openEditModal: PropTypes.func
};

TableView.defaultProps = {
  openEditModal: null
};
