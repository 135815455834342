import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import OPCOTable from './OPCOTable';
import { PageHeaderCustom, ContentCustom } from '../../../components';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const CategorySettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [OPCOs, setOPCOs] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [newOPCO] = useState({
    title: ''
  });

  const fetchCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/opcos`
      });
      setOPCOs(data);
    } catch (e) {
      message(e);
    }
  };

  const addOPCO = async () => {
    try {
      await dispatchAPI('POST', { url: '/opcos', body: newOPCO });
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  const deleteOPCO = async (category) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/opcos/${category._id}`
      });
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  const editOPCO = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/opcos/${_id}`,
        body: row
      });
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  useEffect(() => {
    fetchCategories();
  }, [forceRefresh]);

  return (
    <>
      <PageHeaderCustom title={t(`opco.title`)} />
      <ContentCustom>
        {OPCOs.length > 0 && (
          <OPCOTable
            OPCOs={OPCOs}
            editOPCO={editOPCO}
            deleteOPCO={deleteOPCO}
          />
        )}
        <Button
          type="dashed"
          style={{ width: '100%', marginTop: 10 }}
          onClick={addOPCO}
        >
          {t('opco.form.add')}
        </Button>
      </ContentCustom>
    </>
  );
};

export default CategorySettings;
