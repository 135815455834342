import { useState } from 'react';
import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select, Switch, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { OptionsChoiceFormList } from '../../Models/Surveys/OptionsChoiceFormList';

const { Option } = Select;
export const EditQuizModal = ({ fieldName, form, moduleIndex, purpose }) => {
  const { t } = useTranslation();
  const questionTypes = ['UNIQUE_CHOICE', 'MULTI_CHOICE'];
  const [visible, setVisible] = useState(false);

  const extraCardButtons = (
    moveQuestion,
    removeQuestion,
    questionIndex,
    optionIndex
  ) => (
    <Row>
      {optionIndex >= 0 && (
        <>
          <Form.Item valuePropName="checked" name={[optionIndex, 'is_right']}>
            <Switch />
          </Form.Item>
          <span>{t('surveys.form.right_answer')}</span>
        </>
      )}
      <Tooltip title="Changer de position">
        <Button
          type="link"
          disabled={(!optionIndex && questionIndex === 0) || optionIndex === 0}
          onClick={() =>
            moveQuestion(
              optionIndex ?? questionIndex,
              (optionIndex ?? questionIndex) - 1
            )
          }
          icon={<UpOutlined />}
        />
        <Button
          type="link"
          disabled={
            (!optionIndex ? questionIndex : optionIndex) ===
            (
              form.getFieldValue(
                optionIndex >= 0
                  ? [
                      'modules',
                      moduleIndex,
                      'chapters',
                      ...fieldName,
                      'quiz',
                      'questions',
                      questionIndex,
                      'answer_options'
                    ]
                  : [
                      'modules',
                      moduleIndex,
                      'chapters',
                      ...fieldName,
                      'quiz',
                      'questions'
                    ]
              ) || []
            ).length -
              1
          }
          onClick={() =>
            moveQuestion(
              optionIndex ?? questionIndex,
              (optionIndex ?? questionIndex) + 1
            )
          }
          icon={<DownOutlined />}
        />
      </Tooltip>
      <Button
        type="link"
        icon={<CloseOutlined />}
        onClick={() => removeQuestion(questionIndex)}
      />
    </Row>
  );

  const openModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={openModal}>
        {t(`buttons.add_quiz_without_model`)}
      </Button>
      <Modal
        maskClosable={false}
        width={800}
        open={visible}
        title={
          <Row justify="space-between">
            <Col>{t(`Éditer un quiz`)}</Col>
            <Col>
              <span>{t('surveys.form.quiz_mark')}</span>
              <Form.Item
                initialValue={10}
                name={[...fieldName, 'quiz', 'quiz_mark']}
              >
                <Select defaultValue={10} style={{ width: 120 }}>
                  <Option value={10}>10</Option>
                  <Option value={20}>20</Option>
                  <Option value={100}>100</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        }
        visible={visible}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
      >
        <Form.List name={[...fieldName, 'quiz', 'questions']}>
          {(fields, { add, move, remove }) => (
            <>
              <Row justify="space-between" style={{ marginBottom: 16 }}>
                {questionTypes.map((el) => (
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({
                        title: 'Question',
                        type: el,
                        answer_options: [
                          {
                            title: '1'
                          },
                          {
                            title: '2'
                          },
                          {
                            title: '3'
                          }
                        ]
                      })
                    }
                  >
                    {t(`surveys.form.buttons.${el}`)}
                  </Button>
                ))}
              </Row>
              {fields.map(({ name }) => (
                <OptionsChoiceFormList
                  fieldName={
                    purpose === 'final_exam' ? [...fieldName, name] : [name]
                  }
                  purpose={purpose}
                  type={
                    purpose === 'final_exam'
                      ? form.getFieldValue([
                          'modules',
                          ...fieldName,
                          'quiz',
                          'questions',
                          name,
                          'type'
                        ])
                      : form.getFieldValue([
                          'modules',
                          moduleIndex,
                          'chapters',
                          ...fieldName,
                          'quiz',
                          'questions',
                          name,
                          'type'
                        ])
                  }
                  extraCardButtons={extraCardButtons}
                  moveQuestion={move}
                  removeQuestion={remove}
                  form={form}
                  moduleIndex={moduleIndex}
                  chapterIndex={fieldName[0]}
                />
              ))}
            </>
          )}
        </Form.List>
      </Modal>
    </>
  );
};

EditQuizModal.propTypes = {
  fieldName: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired
  }).isRequired,
  moduleIndex: PropTypes.number,
  purpose: PropTypes.string
};

EditQuizModal.defaultProps = {
  moduleIndex: undefined,
  purpose: undefined
};
