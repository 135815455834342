import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { listContent } from './listContent';
import { ShowModules } from './Show/showModules';
import ShowCourseHeader from '../../../components/layouts/HeaderLayout/CourseHeaderLayout';
import { ReviewsManagement } from './ReviewsManagement';

export const ShowMasterClass = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [masterClass, setMasterClass] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const { message } = useErrorMessage();

  const getMasterClass = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/master-classes/${id}?populate=topic,created_by,reviews.user`
      });
      setMasterClass(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getMasterClass();
    })();
  }, [getMasterClass, forceRefresh]);

  return (
    <>
      <PageHeaderCustom
        title={
          <>
            {t('master-classes.show')}
            {masterClass?.title}
          </>
        }
        extra={
          <ShowCourseHeader course={masterClass} courseType="master-classes" />
        }
      />
      <ContentCustom>
        <Skeleton active loading={isLoading} paragraph={{ rows: 3 }}>
          <Card
            title={t('master-classes.form.infos')}
            className="formation-card"
          >
            <DescriptionList
              data={listContent(masterClass || {}, t)}
              translate
            />
          </Card>
        </Skeleton>
        <ShowModules modules={masterClass?.modules} />
        <ReviewsManagement
          reviews={masterClass?.reviews}
          setForceRefresh={setForceRefresh}
        />
      </ContentCustom>
    </>
  );
};
