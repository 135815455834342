import { Badge, Button, Col, PageHeader, Row, Skeleton } from 'antd';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useSessionsContext } from '../Sessions/SessionsContext';
import { AnswerInbox } from './AnswerInbox';
import { Header } from './Header';
import { MessageItem } from './MessageItem';
import { useMessagesContext } from '../../contexts/MessagesContext';
import { NoMessages } from './NoMessages';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';

export const Messages = () => {
  const { dispatchAPI, user } = useAuthContext();
  const { userSessions } = useSessionsContext();
  const { sessionStatus } = useMessagesContext();
  const { message: errorMessage } = useErrorMessage();
  const {
    messages,
    threadItem,
    setThreadItem,
    forceRefresh,
    threadsToDisplay,
    setThreadsToDisplay,
    getThread,
    userThreads,
    sessionEnums,
    isLoading
  } = useMessagesContext();

  const customStatusCondition = (session) => {
    switch (sessionStatus) {
      case 'ALL':
        return sessionEnums.status.includes(session.status);
      default:
        return session?.status === sessionStatus;
    }
  };

  const sessionsToShow = userSessions.filter((session) =>
    messages.some(
      (message) =>
        message?.thread?.session?._id === session?._id &&
        session?.contributor?._id === user?._id &&
        customStatusCondition(session)
    )
  );

  const isSessionBadgeDisplayed = (e, session) =>
    !e.read_by &&
    e.sender._id !== user._id &&
    e?.thread?.session?._id === session._id;

  const isThreadBadgeDisplayed = (e, thread) =>
    !e.read_by && e.sender._id !== user._id && e.thread._id === thread._id;

  const handleThreadsToDisplay = async (id) => {
    if (threadItem) {
      setThreadItem();
    }
    try {
      const { data } = await dispatchAPI('GET', {
        url: `threads?session=${id}&populate=messages.recipient,messages.sender`
      });
      setThreadsToDisplay(data);
    } catch (e) {
      errorMessage(e);
    }
  };

  const handleSessionButton = (id) => {
    if (threadsToDisplay) {
      setThreadsToDisplay();
      setThreadItem();
    } else {
      handleThreadsToDisplay(id);
    }
  };

  useEffect(() => {
    setThreadsToDisplay();
    setThreadItem();
  }, []);

  return (
    <>
      <PageHeader title={t('inbox.title.inbox')} />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <Header />
          {userThreads?.length ? (
            <Row className="inbox-wrapper">
              <Col
                style={{ display: 'flex', flexDirection: 'column' }}
                forceRefresh={forceRefresh}
                xs={24}
                md={6}
                xl={4}
                className="sessions-column"
              >
                {sessionsToShow.map((session) => (
                  <Button
                    onClick={() => handleSessionButton(session._id)}
                    type="link"
                    className="inbox-session-btn"
                    key={session._id}
                  >
                    <Row
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingRight: 8
                      }}
                    >
                      {messages.some((e) =>
                        isSessionBadgeDisplayed(e, session)
                      ) && <Badge status="warning" />}
                    </Row>
                    <Row style={{ flexDirection: 'column' }}>
                      <h4>{session.formation.name}</h4>
                      <h5>
                        {`${t('training_sessions.modals.from2')} ${moment(
                          session.start_date
                        ).format('DD-MM-YYYY')} ${t(
                          'training_sessions.modals.to2'
                        )} ${moment(session.end_date).format('DD-MM-YYYY')}`}
                      </h5>
                    </Row>
                  </Button>
                ))}
              </Col>
              <Col
                style={{ display: 'flex', flexDirection: 'column' }}
                xs={24}
                md={6}
                xl={4}
                className="trainees-column"
              >
                {threadsToDisplay &&
                  threadsToDisplay.map((thread) => (
                    <Button
                      onClick={() =>
                        threadItem ? setThreadItem() : getThread(thread._id)
                      }
                      type="link"
                      className="inbox-trainee-btn"
                    >
                      {messages.some((e) =>
                        isThreadBadgeDisplayed(e, thread)
                      ) && <Badge status="warning" />}
                      <Row>
                        <Row
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {forceRefresh &&
                            messages.includes('message.read_by') && (
                              <Badge status="warning" />
                            )}
                        </Row>
                        <Col>
                          <Row>
                            <h4>
                              {`${thread?.messages[0]?.recipient?.first_name} ${thread?.messages[0]?.recipient?.last_name}`}
                            </h4>
                          </Row>
                          <Row>
                            <h5>{thread.name}</h5>
                          </Row>
                        </Col>
                      </Row>
                    </Button>
                  ))}
              </Col>
              <Col xs={24} md={12} xl={16} className="message-column">
                {threadItem?.messages?.map((message) => (
                  <MessageItem message={message} key={message._id} />
                ))}

                {threadItem && <AnswerInbox threadItem={threadItem} />}
              </Col>
            </Row>
          ) : (
            <NoMessages />
          )}
        </Skeleton>
      </ContentCustom>
    </>
  );
};
