import { Tag } from 'antd';
import moment from 'moment/moment';

import { masterclassStatus } from '../../../utils/constants/tagColors';
import { AverageNote } from './AverageNote';

export const columns = (t) => [
  {
    title: t('master-classes.form.title'),
    key: 'title',
    dataIndex: 'title',
    sorter: true,
    width: '25%'
  },
  {
    title: t('master-classes.form.topic'),
    key: 'topic',
    dataIndex: 'topic',
    render: (topic) =>
      topic && (
        <Tag className="masterclass-topic" style={{ color: topic.color }}>
          {topic.name}
        </Tag>
      ),
    sorter: true
  },
  {
    title: t('master-classes.form.status'),
    dataIndex: 'status',
    render: (status) =>
      (status && (
        <Tag
          className="status-tag"
          color={masterclassStatus[status]}
          style={{ color: 'white' }}
        >
          {t(`master-classes.form.${status}`)}
        </Tag>
      )) ||
      '-',
    sorter: true
  },
  {
    title: t('master-classes.form.publication_date'),
    dataIndex: 'publication_date',
    sorter: true,
    render: (publication_date) =>
      (publication_date && moment(publication_date).format('DD/MM/YYYY')) ||
      `${t('errors.message.not_specified')}`
  },
  {
    title: t('master-classes.form.average_note'),
    render: (record) => (
      <AverageNote masterclassID={record._id} purpose="star" />
    )
  }
];
