import { useState } from 'react';
import { useColumns } from './columns';
import { ListResource } from '../../components/ListResource/ListResource';

export const Orders = () => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const { columns, actionColumns } = useColumns(forceRefresh, setForceRefresh);

  return (
    <ListResource
      forceRefresh={forceRefresh}
      withCreateButton={false}
      resourceName="orders"
      customActionColumn
      columns={[...columns, ...actionColumns]}
      populate="customer"
      resourceModelName="Order"
    />
  );
};
