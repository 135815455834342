import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Col,
  Avatar,
  Divider,
  Tabs,
  Tag
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import ListFormations from './ListFormations';
import ListOrders from './ListOrders';
import { ListAssociatesFormations } from './ListAssociatesFormations';
import { ListSessionsFormations } from './ListSessionsFormations';
import { UserNotes } from './UserNotes';
import { SessionsToValidate } from './SessionsToValidate';

const { TabPane } = Tabs;

const ShowUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=notes.author`
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser, forceRefresh]);

  const deleteUser = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/users/${id}`,
        body: {
          status: 'ARCHIVED'
        }
      });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('users.show.title')}
        extra={
          user?.status !== 'ARCHIVED' ? (
            <>
              <Link to={{ pathname: `${routes.USERS}/edit/${id}` }}>
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>

              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={deleteUser}
                icon={<WarningOutlined />}
              >
                <Button type="link">
                  {`${t('buttons.delete')} `}
                  <ContainerOutlined />
                </Button>
              </Popconfirm>
            </>
          ) : (
            <Tag color="#727272">{t(`users.tags.${user?.status}`)}</Tag>
          )
        }
      />
      <ContentCustom>
        <Row gutter={[0, 24]} style={{ padding: '16px 0 0 16px' }}>
          <Col xs={24} md={6} xl={5} xxl={4}>
            <Skeleton
              loading={isLoading}
              title={0}
              paragraph={0}
              avatar={{ size: 120 }}
              active
            >
              {/* <Avatar size={64} src={user ? user.photo : ''}/> */}
              <Avatar size={120} src={user && user.photo ? user.photo : ``} />
            </Skeleton>
          </Col>
          <Col xs={24} md={18} xl={19} xxl={16}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList data={listContent(user || {}, t)} translate />
            </Skeleton>
          </Col>
        </Row>
        <Divider />
        {user?.role === 'users:TRAINEE' && (
          <Tabs defaultActiveKey="1" className="user-tabs">
            <TabPane tab="Formations suivies" key="1">
              <ListFormations />
            </TabPane>
            <TabPane tab="Commandes" key="2">
              <ListOrders />
            </TabPane>
          </Tabs>
        )}
        {user?.role === 'users:CONTRIBUTOR' && (
          <>
            <Tabs defaultActiveKey="1" className="user-tabs">
              <TabPane tab="Formations associées" key="1">
                <ListAssociatesFormations userID={id} />
              </TabPane>
              <TabPane tab="Sessions de formations associées" key="2">
                <ListSessionsFormations userID={id} />
              </TabPane>
            </Tabs>
            <UserNotes
              user={user}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
            />
          </>
        )}
        {user?.role === 'admins:SUPER-ADMIN' && (
          <SessionsToValidate
            forceRefresh={forceRefresh}
            setForceRefresh={setForceRefresh}
          />
        )}
      </ContentCustom>
    </>
  );
};

export default ShowUser;
