import { Routes, Route } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/adminRoutes';
import CategorySettings from './categories/categorySettings';
import TopicsSettings from './topics/topicSettings';
import SkillsSettings from './skills/skillsSettings';
import OPCO from './OPCO/OPCO';

const SettingsRouter = () => (
  <Routes>
    <Route
      path={`${subRoutes.SETTINGS.CATEGORIES}`}
      element={<CategorySettings />}
    />
    <Route path={`${subRoutes.SETTINGS.TOPICS}`} element={<TopicsSettings />} />
    <Route path={`${subRoutes.SETTINGS.SKILLS}`} element={<SkillsSettings />} />
    <Route path={`${subRoutes.SETTINGS.OPCO}`} element={<OPCO />} />
  </Routes>
);

export default SettingsRouter;
