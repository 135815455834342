import { Collapse, Typography, Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ShowChapters } from './ShowChapters';
import FinalExamIcon from '../../../../assets/images/icons/final-exam_module.svg';

const { Panel } = Collapse;

export const ShowModules = ({ modules }) => {
  const { t } = useTranslation();

  return (
    <Card title={t('master-classes.form.modules')} className="formation-card">
      <Collapse className="module-collapse">
        {!!modules?.length &&
          modules.map((module, index) =>
            index !== modules.length - 1 ? (
              <Panel key={module._id} header={`${module.title}`}>
                <ShowChapters chapters={module.chapters} />
              </Panel>
            ) : (
              <Panel
                key={module._id}
                header={
                  <>
                    <img
                      src={FinalExamIcon}
                      alt={t('formations.form.final_exam')}
                      style={{ marginRight: 8 }}
                    />
                    {t('formations.form.final_exam')}
                  </>
                }
              >
                <Row style={{ marginBottom: 16 }}>
                  <Col span={24}>
                    <Typography level={5}>
                      {t('module.form.description')}
                    </Typography>
                    <span>{module.description || '-'}</span>
                  </Col>
                </Row>
                <ShowChapters chapters={module.chapters} />
              </Panel>
            )
          )}
      </Collapse>
    </Card>
  );
};

ShowModules.propTypes = {
  modules: PropTypes.arrayOf({}).isRequired
};
