import PropTypes from 'prop-types';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';

export const ChoiceGridHeader = ({ restField, fieldKey, name, remove }) => (
  <Col xs={24} md={8}>
    <Row align="middle" style={{ flexWrap: 'nowrap' }} gutter={[0, 8]}>
      <Col>
        <Form.Item {...restField} name={[name, 'title']} fieldKey={[fieldKey]}>
          <Input
            bordered={false}
            suffix={
              <Button
                type="link"
                icon={<EditOutlined />}
                style={{ padding: 4 }}
              />
            }
          />
        </Form.Item>
      </Col>
      <Col>
        <Button
          onClick={() => remove(name)}
          type="link"
          style={{ padding: '4px 8px' }}
        >
          <CloseOutlined />
        </Button>
      </Col>
    </Row>
  </Col>
);

ChoiceGridHeader.propTypes = {
  restField: PropTypes.shape({}),
  fieldKey: PropTypes.number,
  name: PropTypes.number,
  remove: PropTypes.func
};

ChoiceGridHeader.defaultProps = {
  restField: null,
  fieldKey: undefined,
  name: undefined,
  remove: null
};
