import { ExclamationCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { OrderStatusIcon } from '../../utils/constants/customIcons';

const iconSize = 18;

export const PopoverOrderList = ({ updateStatus, record, enums }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleConfirm = (id, status, purpose) => {
    updateStatus(id, status, purpose);
    setOpen(!open);
  };

  const popoverContent = ({ id, status, purpose }) => (
    <div>
      <Row>
        <Col>
          <ExclamationCircleOutlined />
          {t(
            `orders.popover.${
              purpose === 'update'
                ? 'confirm_order_status'
                : 'confirm_payment_refused'
            }`
          )}
        </Col>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
        <Button onClick={() => setOpen(false)} type="danger">
          {t('orders.popover.no')}
        </Button>
        <Button onClick={() => handleConfirm(id, status, purpose)} type="add">
          {t('orders.popover.yes')}
        </Button>
      </Row>
    </div>
  );

  return (
    <Row style={{ gap: 16 }}>
      {record.current_status !== enums.status[2] && (
        <>
          <Popover
            content={popoverContent({
              id: record._id,
              status: record.current_status,
              purpose: 'update'
            })}
            trigger="click"
            onOpenChange={() => setOpen(!open)}
          >
            <Button type="link" style={{ padding: 0 }}>
              <OrderStatusIcon style={{ fontSize: iconSize }} />
            </Button>
          </Popover>
          {record.current_status !== enums.status[3] && (
            <Popover
              content={popoverContent({
                id: record._id,
                status: record.current_status,
                purpose: 'refused'
              })}
              trigger="click"
              onOpenChange={() => setOpen(!open)}
            >
              <Button type="link" style={{ padding: 0 }}>
                <StopOutlined style={{ fontSize: iconSize }} />
              </Button>
            </Popover>
          )}
        </>
      )}
    </Row>
  );
};

PopoverOrderList.propTypes = {
  record: PropTypes.shape({
    _id: PropTypes.string,
    current_status: PropTypes.string
  }),
  enums: PropTypes.PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string)
  }),
  updateStatus: PropTypes.func
};

PopoverOrderList.defaultProps = {
  record: null,
  enums: null,
  updateStatus: null
};
