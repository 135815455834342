import { Pie } from '@ant-design/plots';
import PropTypes from 'prop-types';

export const DonutChart = ({ donutData }) => {
  const data = [
    {
      type: `${Math.round(donutData)} %`,
      value: Math.round(donutData)
    },
    {
      type: `${Math.round(100 - donutData)} %`,
      value: Math.round(100 - donutData)
    }
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ['#FFE383', '#E5E5E5'],
    radius: 1,
    innerRadius: 0.8,
    label: {
      type: 'outer',
      offset: '50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14
      }
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      }
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontFamily: 'var(--mainFont)',
          fontWeight: 400,
          textTransform: 'uppercase',
          color: 'var(--textColorSecondary)',
          fontSize: 16
        },
        content: 'Taux de complétion'
      }
    }
  };
  return <Pie {...config} />;
};

DonutChart.propTypes = {
  donutData: PropTypes.number
};

DonutChart.defaultProps = {
  donutData: undefined
};
