import Vimeo from '@u-wave/react-vimeo';
import { Avatar, Tag } from 'antd';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import { masterclassStatus } from '../../../utils/constants/tagColors';
import { AverageNote } from './AverageNote';

export const listContent = (data, t) => {
  const { id } = useParams();
  const {
    title,
    topic,
    status,
    subtitle,
    created_by,
    publication_date,
    description,
    price,
    video,
    image
  } = data;

  return [
    {
      label: 'master-classes.form.title',
      span: 1,
      content: title || `${t('errors.message.not_specified')}`
    },
    {
      label: 'master-classes.form.subtitle',
      span: 2,
      content: subtitle || `${t('errors.message.not_specified')}`
    },
    {
      label: 'master-classes.form.topic',
      span: 1,
      content:
        (topic && (
          <Tag className="masterclass-topic" color={topic.color}>
            {topic.name}
          </Tag>
        )) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'master-classes.form.status',
      span: 1,
      content:
        (status && (
          <Tag color={masterclassStatus[status]}>
            {t(`master-classes.form.${status}`)}
          </Tag>
        )) ||
        `${t('errors.message.not_specified')}`
    },
    ...(publication_date
      ? [
          {
            label: 'master-classes.form.publication_date',
            span: 1,
            content:
              moment(publication_date).format('DD/MM/YYYY') ||
              `${t('errors.message.not_specified')}`
          }
        ]
      : []),
    {
      label: 'master-classes.form.created_by',
      span: 1,
      content:
        (created_by && `${created_by.first_name} ${created_by.last_name}`) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'master-classes.form.price',
      span: 1,
      content: `${price} € T.T.C.` || `${t('errors.message.not_specified')}`
    },
    {
      label: 'master-classes.form.average_note',
      span: 2,
      content:
        <AverageNote masterclassID={id} purpose="number" /> ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'master-classes.form.intro_video',
      span: 1,
      content: <Vimeo video={video} />
    },
    {
      label: 'master-classes.form.image',
      span: 1,
      content:
        (image && <Avatar shape="square" src={image} size={120} />) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'master-classes.form.description',
      span: 2,
      content: description || `${t('errors.message.not_specified')}`
    }
  ];
};
