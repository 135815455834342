import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

export const useGenerateFormItem = () => {
  const { t } = useTranslation();

  return (
    resource,
    {
      label,
      name,
      initialValue,
      dependencies,
      hasFeedback,
      validateStatus,
      rules,
      input,
      layout,
      noLabel,
      valuePropName,
      labelAlign,
      help,
      tooltip,
      translate = true,
      hidden = false,
      displayOnly = false
    }
  ) => (
    <div key={name}>
      <Form.Item
        {...layout}
        labelAlign={labelAlign}
        hidden={hidden}
        name={displayOnly ? undefined : name}
        initialValue={initialValue}
        dependencies={dependencies}
        label={
          !noLabel &&
          `${
            translate
              ? t(`${resource}.form.${label || name.join('.')}`)
              : `${label || name}`
          }`
        }
        hasFeedback={hasFeedback}
        rules={rules}
        valuePropName={valuePropName}
        validateStatus={validateStatus}
        help={help}
        tooltip={
          tooltip && {
            title: t(`${resource}.form.tooltip.${tooltip.name || name}`),
            overlayInnerStyle: { width: 500 }
          }
        }
      >
        {input || <Input />}
      </Form.Item>
    </div>
  );
};
