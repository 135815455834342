import { Card, Modal, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { View } from '@carbon/icons-react';

import EvaluationInfo from './EvaluationInfo';

export const ShowEvaluation = ({ quizzes }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataToModal, setDataToModal] = useState();

  const { t } = useTranslation();

  const quizzesTypes = [
    'pre_training',
    'quick_satisfaction',
    'chilled_satisfaction'
  ];

  const showModal = (data) => {
    if (!data) {
      return;
    }
    setDataToModal(data);
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    setDataToModal();
  };

  return (
    <div>
      <Modal
        title={dataToModal && dataToModal.title}
        visible={isModalVisible}
        destroyOnClose
        onCancel={hideModal}
        footer={null}
        className="evaluation-modal"
      >
        <EvaluationInfo dataToModal={dataToModal} />
      </Modal>
      {!!quizzesTypes.length &&
        quizzesTypes.map((type) => {
          const evaluation = quizzes[type];
          return (
            <Card
              className="formation-card"
              key={type}
              title={t(`formations.form.${type}`)}
            >
              <Card
                type="small"
                header
                onClick={() => showModal(evaluation)}
                className="quiz-card"
              >
                <Row align="middle" justify="space-between">
                  <Col span={23}>
                    <span>
                      {evaluation
                        ? evaluation.title
                        : t('formations.form.no_evaluation_model')}
                    </span>
                  </Col>
                  {evaluation && <View size={18} />}
                </Row>
              </Card>
            </Card>
          );
        })}
    </div>
  );
};

ShowEvaluation.propTypes = {
  quizzes: PropTypes.arrayOf({}).isRequired
};
