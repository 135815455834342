import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  StopOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Avatar, Button, Card, Col, Row, Skeleton } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import moment from 'moment/moment';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useReportList } from './List/reportList';
import { RemarkModal } from './RemarkModal';

export const ShowReportItem = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { reportId } = useParams();
  const [report, setReport] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const fields = useReportList(report || {});

  let content;
  if (report?.item?.infos?.content) {
    content = JSON.parse(report?.item?.infos?.content);
  }

  const handleRemarkModal = () => {
    setVisible(!visible);
  };

  const getReport = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `reports/${reportId}?populate=user_reported,report_author,item.infos,notes.author`
      });
      setReport(data);
    } catch (e) {
      message(e);
    }
  };

  const patchItemStatus = async () => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `reports/${reportId}`,
        body: {
          status: 'RESOLVED'
        }
      });
      setForceRefresh(!forceRefresh);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      message(e);
    }
  };

  const archiveItem = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/reports/${report._id}/${report?.item?.ref}/${report?.item?.infos._id}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const patchAccessUserStatus = async () => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `users/${report.user_reported._id}`,
        body: {
          access_to_discussion_messages:
            !report.user_reported.access_to_discussion_messages
        }
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getReport();
    })();
  }, [forceRefresh, isLoading]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('reports.title')} - ${report?.user_reported?.first_name} ${
          report?.user_reported?.last_name
        }`}
        extra={
          <div style={{ display: 'flex', gap: '1rem' }}>
            {report?.status !== 'RESOLVED' && (
              <Button onClick={patchItemStatus}>
                <CheckCircleOutlined />
                {t('reports.form.marked_as_resolved')}
              </Button>
            )}
            <Button onClick={patchAccessUserStatus}>
              {report?.user_reported?.access_to_discussion_messages === true ? (
                <>
                  <StopOutlined style={{ marginRight: '0.5rem' }} />
                  {t('reports.form.ban_user')}
                </>
              ) : (
                <>
                  <CheckCircleOutlined style={{ marginRight: '0.5rem' }} />
                  {t('reports.form.unban_user')}
                </>
              )}
            </Button>
          </div>
        }
      />
      <ContentCustom>
        <Card title={t('reports.form.infos')}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList data={fields} translate />
          </Skeleton>
        </Card>
        <Card title={t('reports.form.item_reported')}>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Row style={{ display: 'flex', gap: '1rem' }}>
              <Col>
                <Avatar
                  icon={report?.user_reported?._photo || <UserOutlined />}
                />
              </Col>
              <Col>
                {`${report?.user_reported?.first_name} ${report?.user_reported?.last_name}`}
              </Col>
              <Col>{`Le ${moment(report?.item?.infos?.date).format(
                'LL'
              )} à ${moment(report?.item?.infos?.date).format('LT')}`}</Col>
            </Row>
            <Row>
              {report?.status !== 'RESOLVED' && (
                <Button>
                  <DeleteOutlined onClick={archiveItem} />
                </Button>
              )}
            </Row>
          </Row>
          {report?.item?.infos?.content &&
          report?.item?.infos?.status === 'ACTIVE' ? (
            <Editor initialContentState={content} toolbarHidden readOnly />
          ) : (
            report?.item?.infos?.isDeleted
          )}
        </Card>
        {report?.notes?.length > 0 && (
          <Card title={t('reports.form.notes')}>
            {report?.notes.map((note) => (
              <>
                <Row style={{ display: 'flex', gap: '1rem' }}>
                  <Col>
                    <Avatar icon={note?.author?.photo || <UserOutlined />} />
                  </Col>
                  <Col>
                    {`${note?.author?.first_name} ${note?.author?.last_name}`}
                  </Col>
                  <Col>
                    {`Le ${moment(note?.date).format('LL')} à ${moment(
                      note?.date
                    ).format('LT')}`}
                  </Col>
                </Row>
                <Row>{note?.content}</Row>
              </>
            ))}
          </Card>
        )}
        <Button onClick={handleRemarkModal} type="primary">
          <PlusOutlined />
          {t('reports.form.add_remark')}
        </Button>
        <RemarkModal
          visible={visible}
          report={report}
          handleRemarkModal={handleRemarkModal}
          forceRefresh={forceRefresh}
          setForceRefresh={setForceRefresh}
        />
      </ContentCustom>
    </>
  );
};
