import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tag, Switch } from 'antd';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ListResource } from '../../../components';

const ListSkillsProfile = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [refresh, setRefresh] = useState(false);

  const handleVisiblility = async (record) => {
    const visibility = !record.visibility;
    try {
      await dispatchAPI('PATCH', {
        url: `skills_profile/${record._id}`,
        body: { visibility }
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    }
  ];

  const columns = [
    {
      title: t('skills_profile.form.title'),
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      width: '25%'
    },
    {
      title: t('skills_profile.form.categories'),
      key: 'categories',
      dataIndex: 'categories',
      sorter: true,
      width: '35%',
      render: (categories) =>
        (categories || []).map((category) => (
          <Tag
            key={category?._id}
            className="course-category"
            style={{ color: category?.color }}
          >
            {category?.name}
          </Tag>
        ))
    },
    {
      title: t('skills_profile.form.visibility'),
      key: 'visibility',
      dataIndex: 'visibility',
      render: (_, record) => (
        <Switch
          checked={record.visibility}
          onChange={() => handleVisiblility(record)}
        />
      )
    },
    {
      title: t('skills_profile.form.last_modif'),
      key: 'updated_at',
      dataIndex: 'updated_at',
      sorter: true,
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    }
  ];

  return (
    <ListResource
      resourceName="skills_profile"
      columns={columns}
      headers={headers}
      resourceModelName="Skills_profile"
      populate="categories"
      forceRefresh={refresh}
    />
  );
};

export default ListSkillsProfile;
