import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import { DescriptionList } from '../../../../components';
import { listContent } from './listContent';

const { Panel } = Collapse;

export const ShowChapters = ({ chapters }) => (
  <Collapse className="chapter-collapse">
    {chapters.map((chapter) => (
      <Panel key={chapter._id} header={chapter.title}>
        <DescriptionList data={listContent(chapter || {})} translate />
      </Panel>
    ))}
  </Collapse>
);

ShowChapters.propTypes = {
  chapters: PropTypes.arrayOf({}).isRequired
};
