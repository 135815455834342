import { Route, Routes } from 'react-router-dom';
import { Orders } from './Orders';
import { ShowOrder } from './ShowOrder';
import { Exception } from '../../components';

export const OrdersRouter = () => (
  <Routes>
    <Route path="/" element={<Orders />} />
    <Route path="/show/:id" element={<ShowOrder />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
