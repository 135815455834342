import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/userRoutes';
import { Login } from '../routes/login';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { UserLayout } from '../components/layouts/UserLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import { Home } from '../routes/home/Home';
import { MessagesRouter } from '../routes/Messages/MessagesRouter';
import { SessionRouter } from '../routes/Sessions/SessionRouter';
import CatalogRouter from '../routes/Catalog/CatalogRouter';
import { SessionsContextProvider } from '../routes/Sessions/SessionsContext';
import { HomeContextProvider } from '../contexts/HomeContext';
import { DiscussionsRouter } from '../routes/Discussions/DiscussionsRouter';

const RequireAuth = ({ location }) => {
  const { isValid } = useAuthContext();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <UserLayout />;
};

RequireAuth.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

RequireAuth.defaultProps = {
  location: { pathname: '/' }
};

export const UserRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <HomeContextProvider>
        <UserTheme />
        <SessionsContextProvider>
          <Routes>
            <Route
              path={`${outOfNavRoutes.LOGIN}/:token`}
              element={<Login />}
            />
            <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
            <Route
              path={`${outOfNavRoutes.CONFIRMATION}/:token`}
              element={<Confirmation />}
            />
            <Route element={<RequireAuth />}>
              <Route
                path={`${outOfNavRoutes.PROFILE}/*`}
                element={<ProfileRouter />}
              />
              <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
              <Route path={routes.HOME} element={<Home />} />
              <Route
                path={`${routes.TRAINING_SESSIONS}/*`}
                element={<SessionRouter />}
              />
              <Route path={`${routes.CATALOG}/*`} element={<CatalogRouter />} />
              <Route path={`${routes.INBOX}/*`} element={<MessagesRouter />} />
              <Route
                path={`${routes.DISCUSS}/*`}
                element={<DiscussionsRouter />}
              />
              <Route path={routes.HOME} element={<Home />} />
              <Route path="*" element={<Exception />} />
            </Route>
          </Routes>
        </SessionsContextProvider>
      </HomeContextProvider>
    </BrowserRouter>
  );
};
