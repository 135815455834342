import { Route, Routes } from 'react-router-dom';
import { ShowReportItem } from './ShowReportItem';
import { Reports } from './Reports';
import { Exception } from '../../components';

export const ReportRouter = () => (
  <Routes>
    <Route path="/" element={<Reports />} />
    <Route path="/show/:reportId" element={<ShowReportItem />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
