import { EyeOutlined } from '@ant-design/icons';
import { Card, Table } from 'antd';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';

export const ShowOrderTable = ({ orderData }) => {
  const columns = [
    {
      title: t('orders.form.article'),
      dataIndex: 'title',
      key: 'title',
      width: '70%',
      render: (title) => title
    },
    {
      title: t('orders.form.totalCost'),
      dataIndex: 'price',
      key: 'price',
      width: '20%',
      render: (price) => `${price} €`
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: '10%',
      render: (record) => (
        <Link
          to={`${routes.CATALOG}${subRoutes.CATALOG.MASTERCLASSES}/show/${record._id}`}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];

  return (
    <Card title={t('orders.show.articles')} className="formation-card">
      <Table columns={columns} dataSource={orderData} />
    </Card>
  );
};

ShowOrderTable.propTypes = {
  orderData: PropTypes.shape({})
};

ShowOrderTable.defaultProps = {
  orderData: null
};
