import { Button, Tag } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  EditOutlined,
  MailOutlined,
  PlusSquareOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { routes } from '../../utils/constants/userRoutes';
import { useSessionsContext } from './SessionsContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const ShowSessionHeader = ({
  sessionData,
  surveyID,
  forceRefresh,
  setForceRefresh,
  setSubscriberModalVisible,
  visible
}) => {
  const { user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patchSession, setVisible } = useSessionsContext();

  const deleteSession = async () => {
    setForceRefresh(!forceRefresh);
    try {
      await dispatchAPI('DELETE', {
        url: `/sessions/${id}`
      });
      navigate(`${routes.TRAINING_SESSIONS}`);
    } catch (e) {
      message(e);
    }
  };

  const openModal = () => {
    setSubscriberModalVisible(true);
  };

  const contributorHeader = () => (
    <>
      {user?.role === 'users:CONTRIBUTOR' &&
        sessionData.status === 'CLOSED' &&
        !surveyID && (
          <Button
            type="primary"
            onClick={() =>
              navigate(
                `${routes.TRAINING_SESSIONS}/show/surveys/session/${sessionData._id}`
              )
            }
          >
            <EditOutlined />
            {`${t('buttons.fill_satisfaction_quizz')}`}
          </Button>
        )}
      <Button type="primary" onClick={() => navigate(`/discussions`)}>
        <MailOutlined />
        {`${t('buttons.see_discussion')}`}
      </Button>
    </>
  );

  const adminHeader = () => (
    <>
      {sessionData?.status === 'PENDING' &&
        user?.role !== 'users:CONTRIBUTOR' && (
          <>
            <Button
              type="primary"
              onClick={() => patchSession(sessionData._id)}
            >
              <CheckSquareOutlined />
              {`${t('buttons.validate_session')}`}
            </Button>
            <Button type="primary" onClick={deleteSession}>
              <CloseSquareOutlined />
              {`${t('buttons.cancel')}`}
            </Button>
          </>
        )}
      {['PUBLISHED', 'RUNNING'].includes(sessionData?.status) && (
        <>
          <Button type="primary" onClick={openModal}>
            <PlusSquareOutlined />
            {`${t('buttons.add_subscriber')}`}
          </Button>
          <Button type="primary" onClick={() => setVisible(!visible)}>
            <EditOutlined />
            {t('buttons.edit')}
          </Button>
        </>
      )}
      {sessionData?.status === 'ARCHIVED' && (
        <Tag color="#727272">
          {t(`training_sessions.form.${sessionData?.status}`)}
        </Tag>
      )}
    </>
  );

  return user?.role === 'users:CONTRIBUTOR'
    ? contributorHeader()
    : adminHeader();
};
