import { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import TopicsTable from './topicsTable';
import { PageHeaderCustom, ContentCustom } from '../../../components';

const TopicsSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [topics, setTopics] = useState([]);
  const [newTopics] = useState({
    label: 'label ...',
    accounting_code: 'code ...',
    in_incomes: false,
    in_expenses: false
  });
  const fetchTopics = async () => {
    try {
      const data = await dispatchAPI('GET', {
        url: `/topics`
      });
      setTopics(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const addTopics = async () => {
    try {
      await dispatchAPI('POST', { url: '/topics', body: newTopics });
    } catch (e) {
      message.error(e.message);
    }
    fetchTopics();
  };
  const deleteTopics = async (topic) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/topics/${topic._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.topics_delete'));
    }
    fetchTopics();
  };
  const editTopics = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/topics/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchTopics();
  };
  useEffect(() => {
    fetchTopics();
  }, []);
  return (
    <>
      <PageHeaderCustom title={t(`topics.title`)} />
      <ContentCustom>
        {topics.length > 0 && (
          <TopicsTable
            topics={topics}
            editTopics={editTopics}
            deleteTopics={deleteTopics}
          />
        )}
        <Button
          type="dashed"
          style={{ width: '100%', marginTop: 10 }}
          onClick={addTopics}
        >
          {t('topics.add')}
        </Button>
      </ContentCustom>
    </>
  );
};

export default TopicsSettings;
