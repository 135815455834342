import { useEffect, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
// import { useHandleResize } from '../../utils/HandleResize';
import ChangePwdForm from './ChangePwdForm';
import BackgroundImg from '../../assets/images/chris-montgomery-smgTvepind4-unsplash.jpg';

const Title = styled.h1`
  font-family: var(--titleFont);
  font-weight: 400;
  color: var(--clientColor);
  font-size: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
`;

export const Login = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  // const { width, height } = useHandleResize();
  const [currentForm, setCurrentForm] = useState('login');
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    register: <RegisterForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  return (
    <div>
      <Layout style={{ minHeight: '100vh', display: 'inherit' }}>
        <Row
          style={{
            height: '100vh',
            width: '100vw',
            background: `url(${BackgroundImg})`,
            backgroundPosition: '0 40%',
            backgroundSize: 'cover'
          }}
        >
          {/* <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 16 }}>
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
              <img style={{ width: '80%' }} alt="logo" src={Logo} />
            </Row>
          </Col> */}
          <Col xs={{ span: 2 }} />
          <Col
            xs={{ span: 20 }}
            md={{ span: 12 }}
            lg={{ span: 10 }}
            xl={{ span: 8 }}
            xxl={{ span: 6 }}
            style={{
              backgroundColor: 'rgba(16, 16, 16, 0.6)',
              backdropFilter: 'blur(4px)',
              color: 'var(--clientColor)',
              maxHeight: '100vh',
              overflowY: 'auto'
            }}
          >
            <Row
              justify="center"
              align="top"
              style={{ height: '100vh', paddingTop: '10vh' }}
            >
              {/* {width < 768 && height > 550 && (
                <img alt="logo" style={{ width: '80%' }} src={Logo} />
              )}
              {width < 768 && height <= 550 && (
                <img alt="logo" style={{ width: '60%' }} src={Logo} />
              )} */}
              <Col
                xs={20}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%'
                }}
              >
                {/* {width > 768 && (
                  <img
                    style={{
                      width: 160,
                      marginBottom: 50
                    }}
                    alt="Logo"
                    src={Logo}
                  />
                )} */}
                <p className="logo" style={{ fontSize: 24 }}>
                  AEP Formation
                </p>
                <div style={{ paddingBottom: '10vh' }}>
                  <Title>{t(`login.title.${currentForm}`)}</Title>
                  {forms[currentForm]}
                </div>
                {/* <Row>
                  {width < 768 && height > 550 && (
                    <img
                      style={{
                        width: 120,
                        margin: '0 auto',
                        display: 'block'
                      }}
                      alt="logo"
                      src={Logo}
                    />
                  )}
                </Row> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
};
