import { Button, Card, Form, Input, Modal, Row, Tooltip } from 'antd';
import { useState } from 'react';
import {
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  UpOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ChoiceGridTable } from '../../Models/Surveys/ChoiceGridTable';
import { OptionsChoiceFormList } from '../../Models/Surveys/OptionsChoiceFormList';

const { TextArea } = Input;

export const ModalEditEvaluation = ({ form, fieldName }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const questionTypes = [
    'UNIQUE_CHOICE',
    'MULTI_CHOICE',
    'TEXT',
    'CHOICE_GRID'
  ];
  const openModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };
  const newQuestion = (type) => {
    let question = {};
    switch (type) {
      case 'CHOICE_GRID':
        question = {
          title: 'Question',
          type,
          answer_options: [
            {
              title: '1'
            },
            {
              title: '2'
            },
            {
              title: '3'
            }
          ],
          questions_list: [
            {
              title: '1'
            },
            {
              title: '2'
            },
            {
              title: '3'
            }
          ]
        };
        break;
      case 'TEXT':
        question = {
          title: 'Question',
          type
        };
        break;
      default: {
        question = {
          title: 'Question',
          type,
          answer_options: [
            {
              title: '1'
            },
            {
              title: '2'
            },
            {
              title: '3'
            }
          ]
        };
      }
    }
    return question;
  };

  const extraCardButtons = (
    moveQuestion,
    removeQuestion,
    questionIndex,
    optionIndex
  ) => (
    <>
      <Tooltip title="Changer de position">
        <Button
          type="link"
          disabled={(!optionIndex && questionIndex === 0) || optionIndex === 0}
          onClick={() =>
            moveQuestion(
              optionIndex ?? questionIndex,
              (optionIndex ?? questionIndex) - 1
            )
          }
          icon={<UpOutlined />}
        />
        <Button
          type="link"
          disabled={
            (!optionIndex ? questionIndex : optionIndex) ===
            form.getFieldValue(
              optionIndex >= 0
                ? [...fieldName, 'questions', questionIndex, 'answer_options']
                : [...fieldName, 'questions']
            ).length -
              1
          }
          onClick={() =>
            moveQuestion(
              optionIndex ?? questionIndex,
              (optionIndex ?? questionIndex) + 1
            )
          }
          icon={<DownOutlined />}
        />
      </Tooltip>
      <Button
        type="link"
        icon={<CloseOutlined />}
        onClick={() => removeQuestion(questionIndex)}
      />
    </>
  );

  const checkRender = (question, index, move, remove) => {
    switch (question.type) {
      case 'UNIQUE_CHOICE':
      case 'MULTI_CHOICE':
        return (
          <OptionsChoiceFormList
            fieldName={[index]}
            type={question.type}
            extraCardButtons={extraCardButtons}
            moveQuestion={move}
            form={form}
            removeQuestion={remove}
          />
        );
      case 'TEXT':
        return (
          <Card
            title={
              <Form.Item name={[index, 'title']}>
                <Input />
              </Form.Item>
            }
            style={{ marginBottom: 16 }}
            extra={extraCardButtons(move, remove, index)}
          >
            <Form.Item name={[index, 'type']} hidden>
              <Input />
            </Form.Item>
            <TextArea disabled />
          </Card>
        );
      case 'CHOICE_GRID':
        return (
          <ChoiceGridTable
            form={form}
            extraCardButtons={extraCardButtons}
            fieldName={index}
            moveQuestion={move}
            removeQuestion={remove}
            question={question}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Button onClick={openModal} type="link" icon={<EditOutlined />} />

      <Modal
        visible={visible}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        maskClosable={false}
        width={800}
        open={visible}
      >
        <Form.Item
          name={[...fieldName, 'title']}
          label={t('surveys.form.name')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.List name={[...fieldName, 'questions']}>
          {(fields, { add, move, remove }) => (
            <>
              <Row justify="space-between" style={{ marginBottom: 16 }}>
                {questionTypes.map((el) => (
                  <Button type="dashed" onClick={() => add(newQuestion(el))}>
                    {t(`surveys.form.buttons.${el}`)}
                  </Button>
                ))}
              </Row>
              {fields.map(({ name }) =>
                checkRender(
                  form.getFieldValue([...fieldName, 'questions', name]),
                  name,
                  move,
                  remove
                )
              )}
            </>
          )}
        </Form.List>
      </Modal>
    </>
  );
};

ModalEditEvaluation.propTypes = {
  form: PropTypes.shape({ getFieldValue: PropTypes.func.isRequired })
    .isRequired,
  fieldName: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired
};
