import { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import CategoriesTable from './categoriesTable';
import { PageHeaderCustom, ContentCustom } from '../../../components';

const CategorySettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [newCategory] = useState({
    label: 'label ...',
    accounting_code: 'code ...',
    in_incomes: false,
    in_expenses: false
  });

  const fetchCategories = async () => {
    try {
      const data = await dispatchAPI('GET', {
        url: `/categories`
      });
      setCategories(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addCategory = async () => {
    try {
      await dispatchAPI('POST', { url: '/categories', body: newCategory });
    } catch (e) {
      message.error(e.message);
    }
    fetchCategories();
  };

  const deleteCategory = async (category) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/categories/${category._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
    fetchCategories();
  };

  const editCategory = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/categories/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchCategories();
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <>
      <PageHeaderCustom title={t(`categories.title`)} />
      <ContentCustom>
        {categories.length > 0 && (
          <CategoriesTable
            categories={categories}
            editCategory={editCategory}
            deleteCategory={deleteCategory}
          />
        )}
        <Button
          type="dashed"
          style={{ width: '100%', marginTop: 10 }}
          onClick={addCategory}
        >
          {t('categories.add')}
        </Button>
      </ContentCustom>
    </>
  );
};

export default CategorySettings;
