export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta',
  TRAINEE: 'blue',
  CONTRIBUTOR: 'cyan'
};

export const userStatus = {
  ACTIVE: '#C2FDF6',
  ARCHIVED: '#727272'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const formationsStatus = {
  COMING: '#E5E5E5',
  PENDING: '#FAF1D3',
  CLOSED: '#E5E5E5',
  ARCHIVED: '#727272',
  DRAFT: '#E5E5E5',
  WAITING_VALIDATION: '#FAF1D3',
  PUBLISHED: '#C2FDF6',
  NOT_PUBLISHED: '#E5E5E5'
};

export const sessionsStatus = {
  PUBLISHED: '#C2FDF6',
  RUNNING: '#91F7B2',
  PENDING: '#FAF1D3',
  CLOSED: '#E5E5E5',
  ARCHIVED: '#727272'
};

export const masterclassStatus = {
  PUBLISHED: '#2367ef',
  NOT_PUBLISHED: '#92959b',
  ARCHIVED: '#727272',
  DRAFT: '#544a4a',
  WAITING_VALIDATION: '#FFD33B'
};

export const reviewsStatus = {
  PENDING_VALIDATION: '#FFD33B',
  AVAILABLE: '#00E74E',
  BLOCKED: '#CC1414',
  ARCHIVED: '#727272'
};

export const ordersStatus = {
  PAID: '#00E74E',
  PENDING_PAYMENT: '#A1A1A1',
  NEED_HANDLING: '#FF6D00',
  REJECTED: '#D63333'
};

export const reportsStatus = {
  NEED_HANDLING: '#FF6D00',
  RESOLVED: '#00E74E'
};
