import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const NextVirtualClass = ({ session }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [nextVirtualClass, setNextVirtualClass] = useState('');

  const getNextVirtualClass = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `sessions/next-virtual-class?_id=${session._id}`
      });
      if (data !== 'NO_CLASSES') {
        setNextVirtualClass(moment(data).format('DD/MM/YYYY'));
      } else {
        setNextVirtualClass(t('training_sessions.chart.not_planned'));
      }
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      if (['CLOSED', 'ARCHIVED', 'PENDING'].includes(session.status)) {
        setNextVirtualClass(t(`training_sessions.chart.${session.status}`));
      } else await getNextVirtualClass();
    })();
  }, []);

  return nextVirtualClass;
};
