import { useState, useEffect } from 'react';
import { Select, Upload, Input, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { PictureOutlined } from '@ant-design/icons/lib';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { Dragger } = Upload;

const useFields = (image, setImage) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [topics, setTopics] = useState([]);
  const [contributors, setContributors] = useState();
  const { dispatchAPI, user } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        setImage(await fileToBase64(file));
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const getTopics = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'topics' });
      setTopics(data);
    } catch (e) {
      message(e.message);
    }
  };

  const getContributors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `users?role=users:CONTRIBUTOR`
      });
      setContributors(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(!isFieldsLoading);
      await getTopics();
      await getContributors();
      setIsFieldsLoading(!isFieldsLoading);
    })();
  }, []);

  const fields = [
    ...(['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user?.role)
      ? [
          {
            name: ['created_by'],
            rules: [{ required: true }],
            input: (
              <Select>
                {(contributors || []).map((contributor) => (
                  <Select.Option key={contributor._id} value={contributor._id}>
                    {`${contributor.first_name} ${contributor.last_name}`}
                  </Select.Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['title'],
      rules: [{ required: true }]
    },
    {
      name: ['subtitle'],
      rules: [{ required: true }]
    },
    {
      name: ['topic'],
      input: (
        <Select
          loading={isFieldsLoading}
          style={{ width: '100%', textAlign: 'left', borderRadius: 5 }}
          options={topics.map((topic) => ({
            label: topic.name,
            value: topic._id
          }))}
        />
      ),
      rules: [{ required: true }]
    },
    {
      name: ['price'],
      rules: [{ required: true }],
      input: <InputNumber min={0} type="number" addonAfter="€ T.T.C." />
    },
    {
      name: ['description'],
      input: <Input.TextArea />
    },
    {
      name: ['image'],
      input: (
        <Dragger {...draggerProps}>
          {image ? (
            <img src={image} alt="imageFormation" style={{ width: '60%' }} />
          ) : (
            <>
              <p className="ant-upload-drag-icon">
                <PictureOutlined style={{ color: 'var(--textColor)' }} />
              </p>
              <p className="ant-upload-text">{t('files.create.action')}</p>
            </>
          )}
        </Dragger>
      )
    },
    {
      name: ['video'],
      input: <Input />
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
