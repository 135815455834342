import Vimeo from '@u-wave/react-vimeo';
import { useTranslation } from 'react-i18next';

export const listContent = (data) => {
  const { title, duration, description, video } = data;
  const { t } = useTranslation();

  return [
    {
      label: 'master-classes.form.title',
      span: 2,
      content: title || `${t('errors.message.not_specified')}`
    },
    {
      label: 'master-classes.form.duration',
      span: 1,
      content:
        `${duration} ${t('master-classes.form.minutes')}` ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'master-classes.form.description',
      span: 3,
      content: description || `${t('errors.message.not_specified')}`
    },
    {
      label: 'master-classes.form.video',
      span: 3,
      content: <Vimeo video={video} />
    }
  ];
};
