import { Column } from '@ant-design/plots';
import PropTypes from 'prop-types';

export const ColumnChart = ({ columnData, dataNames }) => {
  const config = {
    data: columnData,
    xField: 'name',
    yField: 'value',
    colorField: 'name',
    color: ({ name }) => {
      switch (name) {
        case dataNames[0]:
          return '#FFE383';
        case dataNames[1]:
          return '#B1FFF6';
        case dataNames[2]:
          return '#FFBF8F';
        case dataNames[3]:
          return '#8DE0D6';
        default:
          return '#F29999';
      }
    },
    label: {
      position: 'bottom',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6
      }
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false
      }
    },
    meta: {
      type: {
        alias: '类别'
      },
      sales: {
        alias: '销售额'
      }
    }
  };
  return <Column {...config} />;
};

ColumnChart.propTypes = {
  columnData: PropTypes.shape({
    length: PropTypes.number
  }),
  dataNames: PropTypes.shape(PropTypes.string)
};

ColumnChart.defaultProps = {
  columnData: null,
  dataNames: null
};
