import { Route, Routes } from 'react-router-dom';
import TemplateManager from './TemplateManager';
import UploadTemplate from './UploadTemplate';

export const DocumentsRouter = () => (
  <Routes>
    <Route path="/create" element={<UploadTemplate true />} />
    <Route index element={<TemplateManager />} />
  </Routes>
);
