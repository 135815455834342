import {
  LinkOutlined,
  UserOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import { t } from 'i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useHomeContext } from '../../../contexts/HomeContext';
import { routes } from '../../../utils/constants/userRoutes';

export const SessionsInProgress = () => {
  const { activeSessions } = useHomeContext();
  const redirectPage = (url) => {
    window.open(url);
  };

  return (
    <Card
      title={<p>{t('home.titles.sessions_in_progress')}</p>}
      className="home-card"
    >
      {activeSessions &&
        (activeSessions || []).map((session) => (
          <Card key={session._id} className="dashboard-session-card">
            <Row justify="space-between" align="middle">
              <Col>
                {`${moment(session.start_date).format('DD-MM-YYYY')} - 
              ${moment(session.end_date).format('DD-MM-YYYY')}`}
              </Col>
              <Col>
                <VideoCameraOutlined />
                <Button
                  type="link"
                  onClick={() =>
                    redirectPage(session?.virtual_classes[0]?.link)
                  }
                >
                  <LinkOutlined />
                </Button>
              </Col>
            </Row>
            <Row justify="space-between" align="middle">
              <Col>
                <Link
                  to={{
                    pathname: `${routes.TRAINING_SESSIONS}/show/${session._id}`
                  }}
                >
                  {session.formation.name}
                </Link>
              </Col>
              <Col>
                <UserOutlined style={{ marginRight: 6 }} />
                {`${session.subscribers.length} ${
                  session.subscribers.length <= 1
                    ? t('home.form.subscriber')
                    : t('home.form.subscribers')
                }`}
              </Col>
            </Row>
          </Card>
        ))}
    </Card>
  );
};
