import moment from 'moment';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const NextAndLastSession = ({ formationID, contributorID, purpose }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [sessionDates, setSessionDates] = useState('');

  const getSession = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `sessions/${
          purpose === 'next' ? 'next' : 'last'
        }-sessions/${contributorID}?formation=${formationID}`
      });
      if (data) {
        setSessionDates(
          `${moment(data?.start_date).format('DD/MM/YYYY')} - ${moment(
            data?.end_date
          ).format('DD/MM/YYYY')}`
        );
      } else {
        setSessionDates(t('users.form.not_planned'));
      }
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getSession();
    })();
  }, []);

  return sessionDates || t('users.form.not_planned');
};
