import { Card, Col, Row, Tag } from 'antd';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formationsStatus } from '../../../utils/constants/tagColors';
import { routes, subRoutes } from '../../../utils/constants/userRoutes';

export const UserFormations = ({ formations }) => (
  <Card title={<p>{t('home.titles.my_formations')}</p>} className="home-card">
    {formations &&
      (formations || []).map((formation) => (
        <Card key={formation._id} className="dashboard-session-card">
          <Link
            to={{
              pathname: `${routes.CATALOG}${subRoutes.CATALOG.YOUR_FORMATIONS}/show/${formation._id}`
            }}
          >
            <Row justify="space-between" align="middle">
              <Col>{formation.name}</Col>
              <Col>
                <Tag
                  color={formationsStatus[formation.status]}
                  className="status-tag"
                  style={{ marginRight: 0 }}
                >
                  {t(`formations.tags.${formation.status}`)}
                </Tag>
              </Col>
            </Row>
          </Link>
        </Card>
      ))}
  </Card>
);

UserFormations.propTypes = {
  formations: PropTypes.arrayOf(PropTypes.shape({}))
};

UserFormations.defaultProps = {
  formations: null
};
