import {
  BellOutlined,
  CalendarOutlined,
  DesktopOutlined,
  EuroCircleOutlined,
  FormOutlined,
  FrownOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const Notifications = ({
  notifications,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const deleteNotification = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `notifications/${id}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const handleNotifMenuItemLink = (notifID, itemID, item) => {
    switch (true) {
      case item.ref === 'Report':
        return (
          <Link
            to={{ pathname: `${routes.REPORTS}/show/${itemID}` }}
            onClick={() => deleteNotification(notifID)}
          >
            <FrownOutlined style={{ marginRight: '1rem' }} />
            {t('notifications.form.required_report_action')}
          </Link>
        );
      case item.ref === 'Session':
        return (
          <Link
            to={{ pathname: `${routes.TRAINING_SESSIONS}/show/${itemID}` }}
            onClick={() => deleteNotification(notifID)}
          >
            <CalendarOutlined style={{ marginRight: '1rem' }} />
            {t('notifications.form.required_session_action')}
          </Link>
        );
      case item.ref === 'Formation':
        return (
          <Link
            to={{
              pathname: `${routes.CATALOG}${subRoutes.CATALOG.FORMATIONS}/show/${itemID}`
            }}
            onClick={() => deleteNotification(notifID)}
          >
            <DesktopOutlined style={{ marginRight: '1rem' }} />
            {t('notifications.form.required_formation_action')}
          </Link>
        );
      case item.ref === 'Order':
        return (
          <Link
            to={{
              pathname: `${routes.ORDERS}/show/${itemID}`
            }}
            onClick={() => deleteNotification(notifID)}
          >
            <EuroCircleOutlined style={{ marginRight: '1rem' }} />
            {t('notifications.form.required_new_order')}
          </Link>
        );
      case item.ref === 'Master-class' && item.action === 'Creation':
        return (
          <Link
            to={{
              pathname: `${routes.CATALOG}${subRoutes.CATALOG.MASTERCLASSES}/show/${itemID}`
            }}
            onClick={() => deleteNotification(notifID)}
          >
            <DesktopOutlined style={{ marginRight: '1rem' }} />
            {t('notifications.form.required_masterclass_creation_action')}
          </Link>
        );
      case item.ref === 'Master-class' && item.action === 'Review':
        return (
          <Link
            to={{
              pathname: `${routes.CATALOG}${subRoutes.CATALOG.MASTERCLASSES}/show/${itemID}`
            }}
            onClick={() => deleteNotification(notifID)}
          >
            <FormOutlined style={{ marginRight: '1rem' }} />
            {t('notifications.form.required_masterclass_review_action')}
          </Link>
        );
      default:
        return 'bonjour';
    }
  };

  const menu = (
    <Menu>
      {(notifications || []).map((notification) => (
        <Menu.Item>
          {handleNotifMenuItemLink(
            notification?._id,
            notification?.item?.type?._id,
            notification?.item
          )}
        </Menu.Item>
      ))}
    </Menu>
  );

  return notifications?.length ? (
    <Dropdown trigger={['hover']} overlay={menu}>
      <Button type="link" style={{ margin: 0 }}>
        <BellOutlined
          style={{
            fontSize: 16
          }}
        />
        {t('notifications.title')}
      </Button>
    </Dropdown>
  ) : null;
};

Notifications.propTypes = {
  notifications: PropTypes.shape([{}]),
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

Notifications.defaultProps = {
  notifications: null,
  forceRefresh: false,
  setForceRefresh: false
};
