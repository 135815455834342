import { Card, Checkbox, Col, List, Radio, Rate, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const DetailQuizz = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [surveyData, setSurveyData] = useState([]);
  const { surveyID } = useParams();

  const getSurveyResponses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `survey-responses/${surveyID}?populate=survey,user`
      });
      setSurveyData(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(async () => {
    await getSurveyResponses();
  }, []);

  const checkRender = (type, options, indexQuestion, item) => {
    const list = surveyData.responses
      .filter((el) => el.question.toString() === item._id.toString())
      .map((el) => el.response)[0];

    switch (type) {
      case 'UNIQUE_CHOICE': {
        return (
          <>
            {options.map((el) => (
              <Row>
                <Col span={24}>
                  <Radio
                    disabled
                    checked={list.includes(el._id)}
                    value={el._id}
                  />
                  <span>{el.title}</span>
                </Col>
              </Row>
            ))}
          </>
        );
      }
      case 'MULTI_CHOICE': {
        return (
          <>
            {options.map((el) => (
              <Row>
                <Col span={24}>
                  <Checkbox checked={list.includes(el._id)} disabled />
                  <span> {el.title} </span>
                </Col>
              </Row>
            ))}
          </>
        );
      }

      case 'SCORE': {
        return <Rate value={list[0]} disabled />;
      }
      default: {
        return <TextArea value={list[0]} disabled />;
      }
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={`${t('training_sessions.surveys.intervenant_survey')} - ${
          surveyData?.user?.first_name
        } ${surveyData?.user?.last_name}`}
      />
      <List
        grid={{ column: 1 }}
        dataSource={surveyData?.survey?.questions}
        renderItem={(item, index) => (
          <List.Item>
            <Card title={item.title}>
              {checkRender(item.type, item.answer_options, index, item)}
            </Card>
          </List.Item>
        )}
      />
    </>
  );
};
