import { CheckSquareOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ListResource } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useChartColumns } from '../Sessions/ChartColumns';
import { routes } from '../../utils/constants/adminRoutes';

export const SessionsToValidate = ({ forceRefresh, setForceRefresh }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const columns = useChartColumns();
  const navigate = useNavigate();

  const patchSession = async (id) => {
    setForceRefresh(!forceRefresh);
    try {
      await dispatchAPI('PATCH', {
        url: `/sessions/${id}`,
        body: {
          status: 'PUBLISHED'
        }
      });
    } catch (e) {
      message(e);
    }
  };

  const headers = [
    {
      label: 'formation',
      key: 'formation.name'
    },
    {
      label: 'contributor',
      key: 'contributor'
    },
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'start_date',
      key: 'start_date'
    },
    {
      label: 'end_date',
      key: 'end_date'
    }
  ];

  const actionColumn = [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: '10%',
      render: (record) => (
        <Button type="link" onClick={() => patchSession(record._id)}>
          <CheckSquareOutlined />
        </Button>
      )
    }
  ];

  return (
    <>
      {t('users.form.sessions_to_validate')}
      <ListResource
        resourceName="sessions"
        populate="contributor,formation"
        headers={headers}
        extraQuery="status=PENDING"
        searchAddRow={false}
        columns={[...columns, ...actionColumn]}
        resourceModelName="Session"
        scroll={{ x: 900 }}
        withoutHeader={false}
        withUploadButton={false}
        forceRefresh={forceRefresh}
        customActionColumn
        onDoubleClickAction={{
          action: (record) =>
            navigate(`${routes.TRAINING_SESSIONS}/show/${record._id}`)
        }}
      />
    </>
  );
};

SessionsToValidate.propTypes = {
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.bool
};

SessionsToValidate.defaultProps = {
  forceRefresh: false,
  setForceRefresh: false
};
