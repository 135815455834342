import { Tag } from 'antd';
import moment from 'moment';
import { userRoles } from '../../utils/constants/tagColors';

const userList = (data, t) => {
  const {
    first_name,
    last_name,
    role,
    address,
    date_of_birth,
    email,
    phone_number,
    career,
    description,
    company_name
  } = data;

  return [
    {
      label: 'users.form.last_name',
      span: 1,
      content: last_name || `${t('errors.message.not_specified')}`
    },
    {
      label: 'users.form.first_name',
      span: 1,
      content: first_name || `${t('errors.message.not_specified')}`
    },
    {
      label: 'users.form.role',
      span: 1,
      content:
        (role && (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`)}
          </Tag>
        )) ||
        `${t('errors.message.not_specified')}`
    },
    ...(company_name
      ? [
          {
            label: 'users.form.company_name',
            span: 1,
            content: company_name
          }
        ]
      : []),
    {
      label: 'users.form.email',
      span: 1,
      content: email || `${t('errors.message.not_specified')}`
    },
    {
      label: 'users.form.phone_number_full',
      span: 1,
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          `${t('errors.message.not_specified')}`
        )
    },
    {
      label: 'users.form.date_of_birth',
      span: 1,
      content: date_of_birth
        ? moment(date_of_birth).format('ll')
        : `${t('errors.message.not_specified')}`
    },
    ...(role === 'users:CONTRIBUTOR'
      ? [
          {
            label: 'users.form.address_full',
            span: 2,
            content:
              address && address.number !== '' ? (
                <>
                  {address.number} {address.street}
                  <br />
                  {address.additional}
                  {address.additional && <br />}
                  {address.postal_code} {address.city}
                </>
              ) : (
                `${t('errors.message.not_specified')}`
              )
          }
        ]
      : []),
    ...(role === 'users:TRAINEE'
      ? [
          {
            label: 'users.form.address_invoice',
            span: 2,
            content:
              address && address.number !== '' ? (
                <>
                  {address.number} {address.street}
                  <br />
                  {address.additional}
                  {address.additional && <br />}
                  {address.postal_code} {address.city}
                </>
              ) : (
                `${t('errors.message.not_specified')}`
              )
          }
        ]
      : []),
    {
      label: 'users.form.career',
      span: 1,
      content: career || `${t('errors.message.not_specified')}`
    },
    ...(role === 'users:CONTRIBUTOR'
      ? [
          {
            label: 'users.form.description',
            span: 3,
            content: description || `${t('errors.message.not_specified')}`
          }
        ]
      : [])
  ];
};

export default userList;
