import { useState, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Skeleton, Card, Typography, Col, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

export const AssociatedSkills = ({
  skillsProfile,
  isLoading,
  setIsLoading
}) => {
  const [allSkills, setAllSkills] = useState([]);
  const [sourceSkills, setSourceSkills] = useState([]);
  const [targetSkills, setTargetSkills] = useState([]);
  const [spinIsLoading, setSpinIsLoading] = useState(false);
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { Text } = Typography;
  const dragItem = useRef();
  const table = useRef();

  const getAllSkills = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/skills`
      });
      setAllSkills(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  });

  const updateSkills = useCallback(async (currentTargetSkills) => {
    setSpinIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/skills_profiles/${id}`,
        body: { ...skillsProfile, skills: currentTargetSkills }
      });
    } catch (e) {
      message(e);
    }
    setSpinIsLoading(false);
  });

  const filterAllSkills = () => {
    const sourceSkillsSorted = [];
    if (allSkills.length) {
      allSkills.forEach((skill) => {
        let skillFound = false;
        if (skillsProfile?.skills?.length) {
          skillsProfile.skills.forEach((associatedSkill) => {
            if (associatedSkill._id === skill._id) {
              skillFound = true;
            }
          });
        }
        if (!skillFound) {
          sourceSkillsSorted.push(skill);
        }
      });
    }
    setSourceSkills(sourceSkillsSorted);
  };

  const onDragRight = async () => {
    if (table.current === 'target') {
      return;
    }
    setSpinIsLoading(true);
    const newSourceSkills = sourceSkills.filter(
      (s) => s._id !== dragItem.current._id
    );
    setSourceSkills(newSourceSkills);
    setTargetSkills([...targetSkills, dragItem.current]);
    await updateSkills([...targetSkills, dragItem.current]);
    setSpinIsLoading(false);
  };

  const onDragLeft = async () => {
    if (table.current === 'source') {
      return;
    }
    setSpinIsLoading(true);
    const newTargetSkills = targetSkills.filter(
      (s) => s._id !== dragItem.current._id
    );
    setTargetSkills(newTargetSkills);
    setSourceSkills([...sourceSkills, dragItem.current]);
    await updateSkills(newTargetSkills);
    setSpinIsLoading(false);
  };

  const dragStart = (e, skill, currentTable) => {
    dragItem.current = skill;
    table.current = currentTable;
  };

  useEffect(() => {
    (async () => {
      await getAllSkills();
      setTargetSkills(skillsProfile.skills);
    })();
  }, [skillsProfile]);

  useEffect(() => {
    filterAllSkills();
  }, [allSkills]);

  return (
    <Spin spinning={spinIsLoading}>
      <Skeleton loading={isLoading} active>
        <Row gutter={[16, 16]} className="skill-dragdrop-wrapper">
          <Col xs={24} md={12}>
            <Text>{t('skills_profile.show.all_skills')}</Text>
            <Card
              className="skill-list-card"
              bordered={false}
              onDrop={onDragLeft}
              onDragOver={(e) => e.preventDefault()}
            >
              {sourceSkills && sourceSkills.length ? (
                (sourceSkills || {}).map((skill) => (
                  <Card
                    onDragStart={(e) => {
                      dragStart(e, skill, 'source');
                    }}
                    className="skill-card"
                    type="small"
                    draggable
                  >
                    <Text key={skill.id}>{skill.name}</Text>
                  </Card>
                ))
              ) : (
                <Text>{t('skills_profile.show.no_skills')}</Text>
              )}
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Text>{t('skills_profile.show.associated_skills')}</Text>
            <Card
              className="skill-list-card"
              bordered={false}
              onDrop={onDragRight}
              onDragOver={(e) => e.preventDefault()}
            >
              {targetSkills?.length ? (
                (targetSkills || {}).map((skill) => (
                  <Card
                    className="skill-card"
                    type="small"
                    onDragStart={(e) => {
                      dragStart(e, skill, 'target');
                    }}
                    draggable
                  >
                    <Text key={skill.id}>{skill.name}</Text>
                  </Card>
                ))
              ) : (
                <Text>{t('skills_profile.show.no_associated_skills')}</Text>
              )}
            </Card>
          </Col>
        </Row>
      </Skeleton>
    </Spin>
  );
};

AssociatedSkills.propTypes = {
  skillsProfile: PropTypes.element.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired
};

export default AssociatedSkills;
