import { Card, Col, notification, Row, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { SessionList } from './List/SessionsList';

import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { AddSubscriberModal } from './AddSubscriberModal';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { SessionUserTable } from './List/SessionUserTable';
import { useSessionsContext } from './SessionsContext';
import { VirtualClassesContainer } from './VirtualClassesContainer';
import { ShowSessionHeader } from './ShowSessionHeader';
import { AddEditSessionModal } from './AddEditSessionModal';

export const ShowSession = () => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [sessionData, setSessionData] = useState([]);
  const [surveyID, setSurveyID] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriberModalVisible, setSubscriberModalVisible] = useState(false);
  const { forceRefresh, setForceRefresh, visible, setVisible } =
    useSessionsContext();
  const { t } = useTranslation();
  const { id } = useParams();

  const handleCloseSubscriberModal = () => {
    setSubscriberModalVisible(false);
  };

  const handleCloseSessionModal = () => {
    setVisible(false);
  };

  const isSurveyFilled = async () => {
    if (sessionData.status === 'CLOSED') {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/survey-response?session=${id}`
        });
        if (data.length) {
          setSurveyID(data[0]._id);
        }
      } catch (e) {
        message(e);
      }
    }
  };

  const getSession = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${id}?populate=formation,contributor,subscribers.user`
      });
      setSessionData(data);
    } catch (e) {
      message(e);
    }
  };

  const deleteUser = async (userId) => {
    try {
      await dispatchAPI('PATCH', {
        url: `sessions/${sessionData._id}/delete-subscriber/${userId}`
      });
      setForceRefresh(!forceRefresh);
      notification.success({ message: 'Apprenant supprimé' });
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getSession();
      setIsLoading(false);
    })();
  }, [forceRefresh]);

  useEffect(() => {
    (async () => {
      if (sessionData) {
        await isSurveyFilled();
      }
      setIsLoading(false);
    })();
  }, [sessionData]);

  return (
    <div>
      <PageHeaderCustom
        title={`${sessionData?.formation?.name} : ${moment(
          sessionData.start_date
        ).format('DD-MM-YYYY')} - ${moment(sessionData.end_date).format(
          'DD-MM-YYYY'
        )}`}
        extra={
          <ShowSessionHeader
            sessionData={sessionData}
            surveyID={surveyID}
            forceRefresh={forceRefresh}
            setForceRefresh={setForceRefresh}
            setSubscriberModalVisible={setSubscriberModalVisible}
          />
        }
      />

      <ContentCustom>
        <Row gutter={24} align="stretch">
          <Col xs={24} md={14} xl={16}>
            <Card
              className="formation-card session-card"
              title={t('training_sessions.form.informations')}
            >
              <Skeleton active loading={isLoading} paragraph={{ rows: 2 }}>
                <DescriptionList
                  data={SessionList({ ...sessionData, surveyID } || {}, t)}
                  translate
                />
              </Skeleton>
            </Card>
          </Col>
          <VirtualClassesContainer sessionData={sessionData} />
        </Row>
        {sessionData.status !== 'PENDING' &&
          !!sessionData?.subscribers?.length && (
            <Card
              title={t('training_sessions.form.subscribers')}
              className="formation-card session-card"
            >
              <SessionUserTable
                role={user?.role}
                sessionData={sessionData.subscribers}
                formationID={sessionData.formation._id}
                sessionID={id}
                deleteUser={deleteUser}
              />
            </Card>
          )}

        <AddSubscriberModal
          handleCloseModal={handleCloseSubscriberModal}
          visible={subscriberModalVisible}
          session={sessionData}
          setVisible={setSubscriberModalVisible}
        />
        <AddEditSessionModal
          purpose="edit"
          sessionID={id}
          visible={visible}
          setVisible={setVisible}
          handleCloseModal={handleCloseSessionModal}
        />
      </ContentCustom>
    </div>
  );
};
