import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const OptionsChoiceFormList = ({
  fieldName,
  type,
  extraCardButtons,
  removeQuestion,
  moveQuestion,
  moduleIndex,
  chapterIndex,
  form,
  purpose
}) => {
  const { t } = useTranslation();

  return (
    <Card
      className="question-card"
      title={
        <>
          <Form.Item
            name={
              purpose === 'final_exam'
                ? [fieldName[3], 'type']
                : [...fieldName, 'type']
            }
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={
              purpose === 'final_exam'
                ? [fieldName[3], 'title']
                : [...fieldName, 'title']
            }
          >
            <Input bordered={false} />
          </Form.Item>
        </>
      }
      extra={extraCardButtons(moveQuestion, removeQuestion, fieldName[0])}
    >
      <Form.List
        name={
          purpose === 'final_exam'
            ? [fieldName[3], 'answer_options']
            : [...fieldName, 'answer_options']
        }
      >
        {(fields, { add, remove, move }) => (
          <>
            {fields.map(({ fieldKey, name, ...restField }) => (
              <Row align="middle" justify="space-between" gutter={8}>
                <Col xs={24} lg={19} xl={20}>
                  <Row align="middle" gutter={8}>
                    <Col span={1}>
                      {type === 'MULTI_CHOICE' ? (
                        <Checkbox disabled />
                      ) : (
                        <Radio disabled />
                      )}{' '}
                    </Col>

                    <Col span={22}>
                      <Form.Item
                        {...restField}
                        name={[name, 'title']}
                        fieldKey={[fieldKey]}
                      >
                        <Input
                          bordered={false}
                          suffix={
                            <Button type="link" icon={<EditOutlined />} />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={5} xl={4}>
                  {extraCardButtons(move, remove, fieldName[0], name)}
                </Col>
              </Row>
            ))}
            <Row>
              <Col>
                <Button
                  type="link"
                  onClick={() => add({ title: 'Autre option' })}
                >
                  <PlusOutlined />
                  {t('surveys.form.buttons.add_answer')}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
      {moduleIndex >= 0 ||
        (purpose === 'final_exam' && (
          <Row>
            <Form.Item label={t('surveys.form.question_mark')}>
              <Form.Item
                name={
                  purpose === 'final_exam'
                    ? [fieldName[3], 'question_mark']
                    : [...fieldName, 'question_mark']
                }
              >
                <InputNumber />
              </Form.Item>
            </Form.Item>
            /
            <Form.Item>
              <Input
                disabled
                value={form.getFieldValue(
                  purpose === 'final_exam'
                    ? [
                        'modules',
                        fieldName[0],
                        fieldName[1],
                        fieldName[2],
                        'quiz',
                        'quiz_mark'
                      ]
                    : [
                        'modules',
                        moduleIndex,
                        'chapters',
                        chapterIndex,
                        'quiz',
                        'quiz_mark'
                      ]
                )}
                type="number"
              />
            </Form.Item>
          </Row>
        ))}
    </Card>
  );
};

OptionsChoiceFormList.propTypes = {
  type: PropTypes.string.isRequired,
  fieldName: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  extraCardButtons: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  moveQuestion: PropTypes.func.isRequired,
  moduleIndex: PropTypes.number,
  chapterIndex: PropTypes.number,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired
  }),
  purpose: PropTypes.string
};

OptionsChoiceFormList.defaultProps = {
  moduleIndex: undefined,
  chapterIndex: undefined,
  form: undefined,
  purpose: undefined
};
