import { Divider } from 'antd';

const skillList = (data) => {
  const { skills } = data;

  return [
    {
      label: 'formations.form.skill_selected',
      span: 3,
      content:
        !!skills?.length &&
        skills.map((tag) => (
          <span key={tag._id} className="course-goals">
            {tag.name}
            <Divider />
          </span>
        ))
    }
  ];
};

export default skillList;
