import {
  Avatar,
  Button,
  Card,
  Col,
  List,
  message,
  Popconfirm,
  Popover,
  Row,
  Tag
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FlagOutlined,
  UserOutlined,
  WarningOutlined
} from '@ant-design/icons';
import {
  Download,
  OverflowMenuVertical,
  Reply,
  TrashCan
} from '@carbon/icons-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useDiscussionsContext } from '../../contexts/DiscussionsContext';
import { ReportModal } from '../Reports/ReportModal';
import { useDownloadDocument } from '../../utils/downloadDoc';

export const DiscussionItem = ({ discussion, chapterID }) => {
  const { dispatchAPI, user } = useAuthContext();
  const [replies, setReplies] = useState();
  const { t } = useTranslation();
  const { forceRefresh, setForceRefresh, handleReply } =
    useDiscussionsContext();
  const [editorPurpose, setEditorPurpose] = useState('read');
  const [visible, setVisible] = useState(true);
  const [visibleReportModal, setVisibleReportModal] = useState(false);
  const content = JSON.parse(discussion?.content);
  const [revealSender, setRevealSender] = useState(false);
  const [attachments, setAttachments] = useState();
  const { downloadDocument } = useDownloadDocument();

  const patchDiscussionItemReadBy = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/commentaries/read_by/${discussion._id}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const getDiscussionAttachments = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `commentaries/${discussion._id}?populate=attachments`
      });
      setAttachments(data.attachments);
    } catch (e) {
      message(e);
    }
  };

  const getReplies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `commentaries?${
          chapterID ? `chapter=${chapterID}&` : 'chapter=null&'
        }reply_to.0=${
          discussion._id
        }&populate=sender,module,chapter,attachments`
      });
      setReplies(data);
    } catch (e) {
      message(e);
    }
  };

  const deleteFile = async (idFile) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/commentaries/${discussion._id}/files/${idFile}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const handleDelete = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `commentaries/${id}`,
        body: {
          status: 'ARCHIVED'
        }
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  const handlePatch = () => {
    if (editorPurpose === 'read') {
      setEditorPurpose('edit');
    } else setEditorPurpose('read');
  };

  useEffect(() => {
    (async () => {
      await getReplies();
      await getDiscussionAttachments();
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (!discussion.read_by || !discussion?.read_by?.includes(user._id)) {
      patchDiscussionItemReadBy();
    }
  }, [forceRefresh]);

  return (
    <div className="discussion-item" style={{ display: 'flex' }}>
      <Card
        style={{
          border:
            !discussion?.read_by?.includes(user._id) &&
            discussion?.sender?._id !== user._id &&
            '1px solid yellow'
        }}
        className="discussion-card"
      >
        <Row justify="space-between" align="middle">
          <Row style={{ gap: 12 }} align="middle">
            <Row style={{ gap: 10 }} align="middle">
              <Avatar
                src={discussion?.sender?.photo ? discussion?.sender?.photo : ''}
                size="medium"
                icon={discussion?.sender?.photo ? '' : <UserOutlined />}
              />
              <Col>
                {discussion.isAnonymous && (
                  <Row align="moddle" className="discussion-user">
                    {revealSender ? (
                      `${discussion.sender.first_name} ${discussion.sender.last_name}`
                    ) : (
                      <div>{t('discussions.form.anonymous')}</div>
                    )}
                    <Button
                      onClick={() => setRevealSender(!revealSender)}
                      type="link"
                    >
                      <EyeOutlined />
                    </Button>
                  </Row>
                )}
                {!discussion.isAnonymous && (
                  <span className="discussion-user">{`${discussion.sender.first_name} ${discussion.sender.last_name}`}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col>{`Le ${moment(discussion.date).format('LLLL')}`}</Col>
            </Row>
          </Row>
          {discussion.sender._id === discussion.session.contributor && (
            <Row>
              <Tag>{t('discussions.form.expert')}</Tag>
            </Row>
          )}
          {discussion.sender._id !== user._id ? (
            <Row>
              <Popover
                content={
                  <Button
                    onClick={() => setVisibleReportModal(!visibleReportModal)}
                    type="link"
                  >
                    <FlagOutlined style={{ marginRight: '0.5rem' }} />
                    {`${t('discussions.form.report_commentary')}`}
                  </Button>
                }
                trigger="click"
              >
                <Button type="link">
                  <OverflowMenuVertical />
                </Button>
              </Popover>
            </Row>
          ) : (
            <Row>
              <Button type="link">
                <EditOutlined onClick={handlePatch} />
              </Button>
              <Button type="link">
                <DeleteOutlined onClick={() => handleDelete(discussion._id)} />
              </Button>
            </Row>
          )}
        </Row>
        <Row>
          <>
            <Col style={{ width: '90%' }}>
              {discussion?.status !== 'ARCHIVED' ? (
                <Editor initialContentState={content} toolbarHidden readOnly />
              ) : (
                <span style={{ margin: '14px 0px' }}>
                  {discussion?.isDeleted}
                </span>
              )}
            </Col>
            <Col
              style={{
                display: 'flex',
                width: '10%',
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }}
            >
              <Button onClick={() => handleReply(discussion)} type="link">
                <Reply />
              </Button>
            </Col>
          </>
        </Row>
        {!!attachments?.length && (
          <List
            dataSource={attachments}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    style={{ color: 'green' }}
                    icon={<Download />}
                    onClick={() => downloadDocument(item)}
                  />,
                  <Popconfirm
                    title={t('datatable.column.action.delete.title')}
                    okText={t('datatable.column.action.delete.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t('datatable.column.action.delete.cancel')}
                    onConfirm={() => deleteFile(item?._id)}
                    icon={<WarningOutlined />}
                    placement="topRight"
                  >
                    <TrashCan
                      size={18}
                      style={{
                        color: '#FF4D4F'
                      }}
                      type="delete"
                    />
                  </Popconfirm>
                ]}
              >
                {item?.metadata?.originalName}
              </List.Item>
            )}
          />
        )}
        <Row>
          {replies?.length > 0 &&
            (visible ? (
              <Button onClick={() => setVisible(!visible)} shape="circle">
                <EyeOutlined />
              </Button>
            ) : (
              <Button onClick={() => setVisible(!visible)} shape="circle">
                <EyeInvisibleOutlined />
                {`${t('discussions.form.show_commentaries')} (${
                  replies?.length
                })`}
              </Button>
            ))}
          {visible &&
            (replies || []).map((reply) => (
              <DiscussionItem
                discussion={reply}
                chapterID={chapterID}
                style={{
                  border:
                    !discussion?.read_by?.includes(user._id) &&
                    discussion?.sender?._id !== user._id &&
                    '1px solid yellow',
                  marginTop: '1rem',
                  width: '100%'
                }}
              />
            ))}
        </Row>
      </Card>
      <ReportModal
        visible={visibleReportModal}
        item={discussion}
        purpose="Commentary"
        setVisible={setVisibleReportModal}
      />
    </div>
  );
};

DiscussionItem.propTypes = {
  discussion: PropTypes.shape({
    sender: PropTypes.shape({
      photo: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      _id: PropTypes.string
    }),
    session: PropTypes.shape({
      contributor: PropTypes.string
    }),
    date: PropTypes.string,
    content: PropTypes.string,
    chapter: PropTypes.string,
    isOriginalPost: PropTypes.bool,
    subDiscussion: PropTypes.string,
    isOriginalSubdiscussion: PropTypes.bool,
    discussion: PropTypes.shape({}),
    read_by: PropTypes.shape([PropTypes.string]),
    _id: PropTypes.string,
    status: PropTypes.string,
    isDeleted: PropTypes.string,
    isAnonymous: PropTypes.bool,
    attachments: PropTypes.shape([{}])
  }),
  chapterID: PropTypes.string
};

DiscussionItem.defaultProps = {
  discussion: null,
  chapterID: undefined
};
