import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, Typography } from 'antd';
import { register } from '../../serviceWorker';

const { Title } = Typography;

export const UpdateAlert = () => {
  const location = useLocation();
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    navigator.serviceWorker.getRegistrations().then((regs) =>
      regs.forEach((reg) => {
        if (reg.waiting) {
          setWaitingServiceWorker(reg.waiting);
          setUpdateAvailable(true);
        }
      })
    );
  }, [location]);

  useEffect(() => {
    register({
      onUpdate: (registration) => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);
      },
      onWaiting: (waiting) => {
        setWaitingServiceWorker(waiting);
        setUpdateAvailable(true);
      }
    });
  }, []);

  useEffect(() => {
    // reload after getting update
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }, [waitingServiceWorker]);

  const value = useMemo(
    () => ({
      isUpdateAvailable,
      updateCache: () => {
        if (waitingServiceWorker) {
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        }
      }
    }),
    [isUpdateAvailable, waitingServiceWorker]
  );

  return isUpdateAvailable ? (
    <Card
      style={{
        position: 'fixed',
        zIndex: 1000,
        bottom: 10,
        right: 10,
        background: '#ffffff'
      }}
    >
      <Title level={4}>Une nouvelle version est disponible</Title>
      <Button
        type="primary"
        onClick={value.updateCache}
        style={{ width: '100%' }}
      >
        Mettre à jour maintenant
      </Button>
    </Card>
  ) : (
    ''
  );
};
