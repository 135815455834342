import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const useNewsletterColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('newsletteremails.form.first_name'),
      dataIndex: ['first_name'],
      key: 'first_name',
      width: '20%',
      sorter: true
    },
    {
      title: t('newsletteremails.form.last_name'),
      dataIndex: ['last_name'],
      key: 'last_name',
      width: '20%',
      sorter: true
    },
    {
      title: t('newsletteremails.form.email'),
      dataIndex: ['email'],
      key: 'email',
      width: '20%',
      sorter: true
    },
    {
      title: t('newsletteremails.form.subscribed'),
      dataIndex: ['subscribed'],
      key: 'email',
      width: '20%',
      sorter: true,
      render: (subscribed) =>
        subscribed ? <CheckOutlined /> : <CloseOutlined />
    }
  ];
};
