import { DocumentAttachment } from '@carbon/icons-react';
import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Card, Form, notification, Row, Upload } from 'antd';
import { FileOutlined, SendOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useSocketContext } from '../../contexts/SocketContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useMessagesContext } from '../../contexts/MessagesContext';

const { Dragger } = Upload;

export const AnswerInbox = ({ threadItem }) => {
  const { t } = useTranslation();
  const { socket } = useSocketContext();
  const { forceRefresh, setForceRefresh } = useMessagesContext();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [fileList, setFileList] = useState([]);
  const [uploadZone, setUploadZone] = useState(false);
  const [form] = Form.useForm();

  const reset = () => {
    form.resetFields();
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleAttachmentButton = () => {
    setUploadZone(!uploadZone);
  };

  const draggerProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return true;
    },
    fileList
  };

  const handleOk = async (values) => {
    const { messages, session } = threadItem;
    if (values.content) {
      try {
        // JSON.stringify on values.content allows to keep empty keys which are necessary to allow the module to work properly
        const JSONContent = JSON.stringify(values.content);

        const formData = new FormData();

        if (fileList.length) {
          fileList.forEach((file) => {
            formData.append('attachments', file);
          });
        }

        formData.append(
          'values',
          JSON.stringify({
            content: JSONContent,
            thread: messages[0].thread,
            session,
            recipient:
              messages[0].sender._id === user._id
                ? messages[0].recipient._id
                : messages[0].sender._id
          })
        );

        await dispatchAPI('POST', {
          url: '/messages/inbox',
          body: formData
        });
        if (socket) {
          socket.emit('newMessage', { recipient: messages[0].sender._id });
        }
        setEditorState(() => EditorState.createEmpty());
        notification.success({ message: 'Message envoyé.' });
        reset();
        setForceRefresh(!forceRefresh);
      } catch (e) {
        message(e);
      }
    } else {
      notification.error({ message: 'Veuillez renseigner le message.' });
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      handleOk(values);
    } catch (e) {
      if (e.errorFields && e.errorFields.length)
        form.scrollToField(e.errorFields[0].name);
    }
  };

  return (
    <Card className="message-item message-editor-card">
      <Form
        form={form}
        labelCol={{ span: 5 }}
        layout="vertical"
        initialValues={{
          resource_path: 'other'
        }}
      >
        <Row>
          {uploadZone && (
            <Form.Item name={['attachments']}>
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <FileOutlined style={{ color: 'var(--textColor)' }} />
                </p>
                <p className="ant-upload-text">{t('files.create.action')}</p>
              </Dragger>
            </Form.Item>
          )}
        </Row>
        <Row>
          <Form.Item name={['content']}>
            <Editor
              toolbar={{
                options: [
                  'inline',
                  'fontSize',
                  'fontFamily',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'emoji'
                ],
                inline: {
                  options: ['bold', 'italic', 'underline']
                },
                textAlign: { inDropdown: true }
              }}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbarStyle={{ border: 0 }}
              editorStyle={{ border: '1px solid rgb(190,190,190)' }}
              toolbarCustomButtons={[
                <Button
                  onClick={handleAttachmentButton}
                  type="link"
                  style={{ fontSize: 16 }}
                >
                  <DocumentAttachment />
                </Button>
              ]}
            />
          </Form.Item>
        </Row>

        <Row justify="end">
          <Form.Item>
            <Button
              htmlType="submit"
              type="add"
              shape="circle"
              onClick={handleSubmit}
            >
              <SendOutlined />
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Card>
  );
};

AnswerInbox.propTypes = {
  threadItem: PropTypes.shape({
    messages: PropTypes.shape({
      thread: PropTypes.string,
      recipient: PropTypes.string,
      sender: PropTypes.string
    }),
    session: PropTypes.string
  })
};

AnswerInbox.defaultProps = {
  threadItem: {
    subject: null,
    session: null,
    sender: {
      _id: null
    }
  }
};
