import { Route, Routes, Navigate } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/adminRoutes';
import { SurveysRouter } from './Surveys/SurveysRouter';
import { DocumentsRouter } from './Documents/DocumentsRouter';

export const ModelsRouter = () => (
  <Routes>
    <Route path={`${subRoutes.MODELS.SURVEYS}/*`} element={<SurveysRouter />} />
    <Route index element={<Navigate to={`.${subRoutes.MODELS.SURVEYS}`} />} />
    <Route
      path={`${subRoutes.MODELS.DOCUMENTS}/*`}
      element={<DocumentsRouter />}
    />
    <Route index element={<Navigate to={`.${subRoutes.MODELS.DOCUMENTS}`} />} />
  </Routes>
);
