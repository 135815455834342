import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Collapse, Select, Input, Button, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ContentCustom } from '../../../components';
import { useGenerateFormItem } from '../../../utils/GenerateFormItem';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import useFields from './Fields';
import { useFormationsContext } from './FormationsContext';

const { Panel } = Collapse;
const { Option } = Select;

const ChaptersForm = ({ fieldName, form, purpose }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const generateFields = useGenerateFormItem();
  const [enums, setEnums] = useState({});
  const { formation } = useFormationsContext();
  const {
    videoFields,
    homeworkDepositsFields,
    quizzFields,
    textFields,
    challengeSlideFields,
    challengesFields
  } = useFields();

  const fieldsChapter = {
    HOMEWORK_DEPOSITS: homeworkDepositsFields,
    QUIZ: quizzFields,
    TEXT: textFields,
    VIDEO: videoFields,
    CHALLENGE_CHOICE: challengesFields,
    CHALLENGE_SLIDE: challengeSlideFields,
    CHALLENGE_RETURN: challengesFields
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/formations/enums' });
      setEnums(data);
    } catch (e) {
      message(e.message);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const handleDisable = (index, type) => {
    if (
      ['CHALLENGE_CHOICE', 'CHALLENGE_SLIDE', 'CHALLENGE_RETURN'].includes(type)
    ) {
      let challengeChoiceChapterIndex;
      let challengeChoiceModuleIndex;
      let challengeSlidesChapterIndex;
      let challengeSlidesModuleIndex;
      let showChallengeChoice = true;
      const modules = formation.modules?.filter(
        (module, indexMod) => indexMod !== formation.modules.length - 1
      );
      (modules || []).forEach((module, indexModule) => {
        module?.chapters?.forEach((chapter, indexC) => {
          if (chapter?.type === 'CHALLENGE_CHOICE') {
            showChallengeChoice = false;
            challengeChoiceModuleIndex = indexModule;
            challengeChoiceChapterIndex = indexC;
          } else if (chapter?.type === 'CHALLENGE_SLIDE') {
            challengeSlidesModuleIndex = indexModule;
            challengeSlidesChapterIndex = indexC;
          }
        });
      });

      if (type === 'CHALLENGE_CHOICE') {
        if (showChallengeChoice) {
          return false;
        }
      }
      if (type === 'CHALLENGE_SLIDE') {
        if (
          !showChallengeChoice &&
          (challengeChoiceModuleIndex < fieldName ||
            (index > challengeChoiceChapterIndex &&
              challengeChoiceModuleIndex === fieldName))
        ) {
          return false;
        }
      }
      if (type === 'CHALLENGE_RETURN') {
        if (
          challengeSlidesModuleIndex < fieldName ||
          (index > challengeSlidesChapterIndex &&
            challengeSlidesModuleIndex === fieldName)
        ) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  return (
    <Form.List initialValue={['']} name={[fieldName, 'chapters']}>
      {(fieldsChapters, { add: addChapter, remove: removeChapter }) => (
        <>
          <Collapse accordion className="chapter-collapse">
            {fieldsChapters.map(({ key, name, fieldKey, ...restField }) => {
              const chapterType = form.getFieldValue([
                'modules',
                fieldName,
                'chapters',
                name,
                'type'
              ]);
              return (
                <Panel
                  header={
                    form.getFieldValue([
                      'modules',
                      fieldName,
                      'chapters',
                      name,
                      'title'
                    ]) || `Chapitre ${name + 1}`
                  }
                  key={`chap-${name}`}
                  extra={
                    <Button type="link" onClick={() => removeChapter(fieldKey)}>
                      <CloseOutlined />
                    </Button>
                  }
                >
                  <ContentCustom>
                    {purpose !== 'master-class' && (
                      <Form.Item
                        {...restField}
                        name={[name, 'type']}
                        label={t('chapter.form.type')}
                        fieldKey={[fieldKey]}
                      >
                        <Select>
                          {(enums?.chapter_type || []).map((type) => (
                            <Option
                              disabled={handleDisable(name, type)}
                              key={type}
                              value={type}
                            >
                              {t(`chapter.tags.${type}`)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}

                    <Form.Item
                      name={[name, 'title']}
                      fieldKey={[fieldKey]}
                      label={t('chapter.form.title')}
                    >
                      <Input />
                    </Form.Item>
                    {purpose !== 'master-class' && <Divider />}
                    {(purpose === 'master-class' || chapterType) &&
                      fieldsChapter[
                        purpose !== 'master-class' ? chapterType : 'VIDEO'
                      ]([name], chapterType || purpose, form, fieldName)
                        ?.map((field) => ({
                          ...field,
                          name: [name, ...field.name],
                          translate: false
                        }))
                        .map((field) => generateFields('chapter', field))}
                  </ContentCustom>
                </Panel>
              );
            })}
          </Collapse>

          <Button
            type="dashed"
            style={{ width: '100%' }}
            onClick={() => addChapter()}
          >
            {t('chapter.add')}
          </Button>
        </>
      )}
    </Form.List>
  );
};

ChaptersForm.propTypes = {
  fieldName: PropTypes.string.isRequired,
  form: PropTypes.shape().isRequired,
  purpose: PropTypes.string
};

ChaptersForm.defaultProps = {
  purpose: null
};

export default ChaptersForm;
