import moment from 'moment';
import { Tag } from 'antd';
import { useEffect, useState } from 'react';
import { formationsStatus } from '../../../../utils/constants/tagColors';
import { NextAndLastSession } from '../../../users/NextAndLastSession';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/ErrorMessage';

const manageList = (data, t) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [count, setCount] = useState();

  const getSessionCount = async () => {
    try {
      const { data: dataCount } = await dispatchAPI('GET', {
        url: `formations/sessions-count/${data._id}`
      });
      setCount(dataCount);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (data._id) {
      (async () => {
        await getSessionCount();
      })();
    }
  }, [data]);

  return [
    {
      label: 'formations.form.status',
      span: 3,
      content:
        (data.status && (
          <Tag color={formationsStatus[data.status]} className="status-tag">
            {t(`formations.tags.${data.status}`)}
          </Tag>
        )) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.max_learner',
      span: 3,
      content: data.number_max_learner || `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.number_sessions',
      span: 3,
      content: count || `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.publish_date',
      span: 3,
      content: data.created_at
        ? moment(data.created_at).format('ll')
        : `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.last_session',
      span: 3,
      content: data._id && data.trainer && (
        <NextAndLastSession
          formationID={data._id}
          contributorID={data.trainer._id}
          purpose="last"
        />
      )
    },
    {
      label: 'formations.form.next_session',
      span: 3,
      content: data._id && data.trainer && (
        <NextAndLastSession
          formationID={data._id}
          contributorID={data.trainer._id}
          purpose="next"
        />
      )
    }
  ];
};

export default manageList;
