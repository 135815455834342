import { Modal, Table, Card, Switch } from 'antd';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { reviewsList } from './reviewsList';

export const ReviewsManagement = ({ reviews, setForceRefresh }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [writer, setWriter] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [reviewComment, setReviewComment] = useState('');
  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleModal = (record) => {
    setVisible(true);
    setWriter(`${record.user.first_name} ${record.user.last_name}`);
    setReviewComment(record.comment);
  };

  const patchReview = async (reviewId, objective) => {
    setForceRefresh(true);
    try {
      await dispatchAPI('PATCH', {
        url: `master-classes/${id}/patch-review/${reviewId}/${objective}`
      });
      setForceRefresh(false);
    } catch (e) {
      message(e);
    }
  };

  return (
    <Card
      title={`${t('master-classes.form.user_reviews')}`}
      className="formation-card"
      extra={
        <>
          {t('master-classes.form.show_all')}
          <Switch
            onChange={() => setShowArchived(!showArchived)}
            checked={showArchived}
            style={{ marginLeft: '8px', marginRight: '8px' }}
          />
          {t('master-classes.form.show_archived')}
        </>
      }
    >
      <Table
        columns={reviewsList(patchReview, handleModal)}
        dataSource={(reviews || []).filter((review) =>
          showArchived
            ? review?.status === 'ARCHIVED'
            : review?.status !== 'ARCHIVED'
        )}
        tableLayout="fixed"
        scroll={{ x: 1000 }}
        className="review-table"
      />
      <Modal
        visible={visible}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        maskClosable={false}
        width={800}
        open={visible}
        title={`${t('master-classes.form.review_written_by')} ${writer}`}
      >
        <p>{reviewComment}</p>
      </Modal>
    </Card>
  );
};

ReviewsManagement.propTypes = {
  reviews: PropTypes.shape([{}]),
  setForceRefresh: PropTypes.bool
};

ReviewsManagement.defaultProps = {
  reviews: null,
  setForceRefresh: false
};
