import { Col, Row, Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import { NextSessions } from './NextSessions';
import { SatisfactionRate } from '../SatisfactionRate';
import { SessionsInProgress } from './SessionsInProgress';
import { UserFormations } from './UserFormations';
import { useHomeContext } from '../../../contexts/HomeContext';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';

export const UserHome = () => {
  const {
    setFormationFilter,
    formations,
    activeSessions,
    formationFilter,
    activeSubscribers,
    totalCertified,
    completionRateFormation
  } = useHomeContext();
  return (
    <ContentCustom>
      <Row justify="end">
        <Select
          style={{ width: 320, marginBottom: 16 }}
          onSelect={(value) => setFormationFilter(value)}
          placeholder="--Toutes les formations--"
        >
          {formations?.map((formation) => (
            <Option value={formation._id}>{formation.name}</Option>
          ))}
          <Option value="">--Toutes les formations--</Option>
        </Select>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12} className="main-card-wrapper">
          <SessionsInProgress activeSessions={activeSessions} />
        </Col>
        <Col xs={24} lg={12} className="main-card-wrapper">
          <NextSessions formationFilter={formationFilter} />
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12} className="main-card-wrapper">
          <UserFormations formations={formations} />
        </Col>
        <Col xs={24} lg={12} className="main-card-wrapper">
          <SatisfactionRate
            activeSubscribers={activeSubscribers}
            activeSessions={activeSessions?.length}
            formations={formations}
            totalCertified={totalCertified}
            formationFilter={formationFilter}
            completionRateFormation={completionRateFormation}
          />
        </Col>
      </Row>
    </ContentCustom>
  );
};
