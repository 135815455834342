import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { EyeOutlined } from '@ant-design/icons';
import { Datatable } from '../../components';
import { formationsStatus } from '../../utils/constants/tagColors';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';
import { NextAndLastSession } from './NextAndLastSession';

export const ListAssociatesFormations = ({ userID }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const extraQuery = `trainer=${userID}&&status!=DRAFT`;

  const columns = [
    {
      title: t('associatesformations.form.title'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('associatesformations.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) =>
        status && (
          <Tag color={formationsStatus[status]}>
            {t(`formations.tags.${status}`)}
          </Tag>
        ),
      sorter: true
    },
    {
      title: t('associatesformations.form.last_session'),
      render: (record) => (
        <NextAndLastSession
          formationID={record._id}
          contributorID={record.trainer}
          purpose="last"
        />
      )
    },
    {
      title: t('associatesformations.form.next_session'),
      render: (record) => (
        <NextAndLastSession
          formationID={record._id}
          purpose="next"
          contributorID={record.trainer}
        />
      )
    },
    {
      render: (record) => (
        <Link
          to={{
            pathname: `${routes.CATALOG}${subRoutes.CATALOG.FORMATIONS}/show/${record._id}`
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];

  return (
    <Datatable
      resourceName="formations"
      columns={columns}
      extraQuery={extraQuery}
      resourceModelName="Formation"
      customActionColumn
      onDoubleClickAction={{
        action: (record) =>
          navigate(
            `${routes.CATALOG}${subRoutes.CATALOG.FORMATIONS}/show/${record._id}`
          )
      }}
    />
  );
};

ListAssociatesFormations.propTypes = {
  userID: PropTypes.string
};

ListAssociatesFormations.defaultProps = {
  userID: undefined
};
