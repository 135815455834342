import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Popover,
  Switch,
  Table,
  Tag
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseOutlined,
  ContainerOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { CirclePicker } from 'react-color';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  checkType,
  record,
  index,
  children,
  setColorPicker,
  colorPicker,
  selectType,
  ...restProps
}) => {
  const { t } = useTranslation();
  const popoverContent = (
    <CirclePicker
      color={colorPicker || '#fff'}
      onChangeComplete={(color) => {
        setColorPicker(color.hex);
      }}
    />
  );
  let inputNode;
  switch (inputType) {
    case 'text':
      inputNode =
        selectType === 'color' ? (
          <Popover content={popoverContent} title={t('categories.color')}>
            <Tag color={record.color}>#CF21DE</Tag>
          </Popover>
        ) : (
          <Input />
        );
      break;
    case 'checkbox':
      inputNode = (
        <Switch
          defaultChecked={
            checkType === 'incomes' ? record.in_incomes : record.in_expenses
          }
        />
      );

      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `${t('categories.form.please_fill')} ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CategoriesTable = ({ categories, editCategory, deleteCategory }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [colorPicker, setColorPicker] = useState('');

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      label: '',
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const setColor = async ({ color }) => {
    await form.setFieldsValue({
      color: colorPicker || color
    });
  };

  const handleVisiblility = (record) => {
    const visibility = !record.visibility;
    editCategory(record._id, { visibility });
  };

  const save = async (record) => {
    await setColor(record);
    try {
      const row = await form.validateFields();
      editCategory(record._id, row);
      setEditingKey('');
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: t('categories.form.name'),
      dataIndex: 'name',
      editable: true
    },
    {
      title: t('categories.form.color'),
      dataIndex: 'color',
      editable: true,
      render: (color) => (
        <Tag className="config-tag" color={color}>
          {color}
        </Tag>
      )
    },
    {
      title: t('categories.form.visibility'),
      dataIndex: 'visibility',
      render: (_, record) => (
        <Switch
          checked={record.visibility}
          onChange={() => handleVisiblility(record)}
        />
      )
    },
    {
      title: t(''),
      dataIndex: 'operation',
      width: '15%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => save(record)} type="add">
              {`${t('buttons.save')}`}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
              placement="topRight"
            >
              <Button type="link">
                {`${t('buttons.cancel')}`}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <>
            <Button
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              type="link"
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteCategory(record)}
              disabled={editingKey !== ''}
              placement="topRight"
            >
              <Button disabled={editingKey !== ''} type="link">
                <ContainerOutlined
                  style={{ color: 'var(--errorColor)' }}
                  type="delete"
                />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: ['in_incomes', 'in_expenses'].includes(col.dataIndex)
          ? 'checkbox'
          : 'text',
        selectType: col.dataIndex === 'color' ? 'color' : '',
        dataIndex: col.dataIndex,
        title: col.title,
        colorPicker,
        setColorPicker,
        editing: isEditing(record)
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered={false}
        dataSource={categories}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 800 }}
      />
    </Form>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  checkType: PropTypes.string.isRequired,
  record: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  setColorPicker: PropTypes.bool.isRequired,
  colorPicker: PropTypes.bool.isRequired,
  selectType: PropTypes.string.isRequired
};

CategoriesTable.propTypes = {
  categories: PropTypes.string.isRequired,
  editCategory: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired
};

export default CategoriesTable;
