import { Spin, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useFields from './fields';
import { useGenerateFormItem } from '../../../utils/GenerateFormItem';
import { ModulesList } from '../Formations/ModulesList';

export const MasterClassForm = ({ isLoading, form, image, setImage }) => {
  const { t } = useTranslation();
  const { fields } = useFields(image, setImage);
  const generateFields = useGenerateFormItem();

  return (
    <>
      <Card title={t('master-classes.form.infos')} className="formation-card">
        <Spin spinning={isLoading}>
          {fields.map((field) => generateFields('master-classes', field))}
        </Spin>
      </Card>
      <Card title={t(`master-classes.form.modules`)} className="formation-card">
        <ModulesList form={form} purpose="master-class" />
      </Card>
    </>
  );
};

MasterClassForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  form: PropTypes.shape({}).isRequired,
  image: PropTypes.string,
  setImage: PropTypes.func
};

MasterClassForm.defaultProps = {
  image: null,
  setImage: null
};
