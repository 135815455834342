import { Route, Routes } from 'react-router-dom';
import { DetailQuizz } from './DetailQuizz';
import { ShowQuizz } from './ShowQuizz';
import { ShowSession } from './ShowSession';
import { ShowSubscriber } from './ShowSubscriber';
import { TrainingSessions } from './TrainingSessions';
import { Exception } from '../../components';

export const SessionRouter = () => (
  <Routes>
    <Route path="/" element={<TrainingSessions />} />
    <Route path="show/:id" element={<ShowSession />} />
    <Route
      path="/show/:sessionID/show/subscriber/:subscriberID"
      element={<ShowSubscriber />}
    />
    <Route path="/show/surveys/session/:sessionID" element={<ShowQuizz />} />
    <Route
      path="show/surveys/contributor/:surveyID"
      element={<DetailQuizz />}
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
