import { Rate } from 'antd';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

export const AverageNote = ({ masterclassID, purpose }) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [note, setNote] = useState();

  const getAverageNote = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `master-classes/public/average-note/${masterclassID}`
      });
      setNote(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getAverageNote();
    })();
  });

  return purpose === 'star' ? (
    <Rate value={note} disabled />
  ) : (
    `${
      note === null
        ? `${t('errors.message.not_specified')}`
        : `${Math.round(note)}/5`
    }`
  );
};

AverageNote.propTypes = {
  masterclassID: PropTypes.string,
  purpose: PropTypes.string
};

AverageNote.defaultProps = {
  masterclassID: undefined,
  purpose: undefined
};
