import { Route, Routes } from 'react-router-dom';
import { SessionsContextProvider } from '../Sessions/SessionsContext';
import { Messages } from './Messages';

export const MessagesRouter = () => (
  <SessionsContextProvider>
    <Routes>
      <Route path="/" element={<Messages />} />
    </Routes>
  </SessionsContextProvider>
);
