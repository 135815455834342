import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { SatisfactionRate } from '../SatisfactionRate';
import { ChartContainer } from './ChartContainer';
import { MasterClasses } from './MasterClasses';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';

export const AdminHome = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [formationsColumnData, setFormationsColumnData] = useState();
  const [opcoData, setOpcoData] = useState();
  const [switchFormationsChart, setSwitchFormationsChart] = useState(true);
  const [masterClassesColumnData, setMasterClassesColumnData] = useState();
  const [switchMasterClassesChart, setSwitchMasterClassesChart] =
    useState(true);
  const [switchOpcoChart, setSwitchOpcoChart] = useState(true);

  const getMostandLessSoldFormations = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `formations/most-less-sold/${
          switchFormationsChart ? 'MVP' : 'LVP'
        }`
      });
      setFormationsColumnData(data);
    } catch (e) {
      message(e);
    }
  };

  const getMasterClassesColumnData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `master-classes/most-less-bought/${
          switchMasterClassesChart ? 'MVP' : 'LVP'
        }`
      });
      setMasterClassesColumnData(data);
    } catch (e) {
      message(e);
    }
  };

  const getOpcoData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `sessions/opco-most-less-financed/${
          switchOpcoChart ? 'MVP' : 'LVP'
        }`
      });
      setOpcoData(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOpcoData();
    })();
  }, [switchOpcoChart]);

  useEffect(() => {
    getMostandLessSoldFormations();
  }, [switchFormationsChart]);

  useEffect(() => {
    getMasterClassesColumnData();
  }, [switchMasterClassesChart]);

  return (
    <ContentCustom>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <SatisfactionRate />
        </Col>
        <Col xs={24} lg={12}>
          <MasterClasses />
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12}>
          <ChartContainer
            purpose="formations"
            columnData={formationsColumnData}
            switchFormationsChart={switchFormationsChart}
            setSwitchFormationsChart={setSwitchFormationsChart}
          />
        </Col>
        <Col xs={24} lg={12}>
          <ChartContainer
            purpose="masterclass"
            columnData={masterClassesColumnData}
            switchMasterClassesChart={switchMasterClassesChart}
            setSwitchMasterClassesChart={setSwitchMasterClassesChart}
          />
        </Col>
        <Col xs={24} lg={12}>
          <ChartContainer
            purpose="opcos"
            columnData={opcoData}
            switchOpcoChart={switchOpcoChart}
            setSwitchOpcoChart={setSwitchOpcoChart}
          />
        </Col>
      </Row>
    </ContentCustom>
  );
};
