import {
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, Card, Form, Input, Modal, Row, Table } from 'antd';
import { t } from 'i18next';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const ShowOrderRemarks = ({ orderData, isLoading, setIsLoading }) => {
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [isPurposePost, setIsPurposePost] = useState(true);
  const [writter, setWritter] = useState('');

  const postRemark = async (body) => {
    try {
      setIsLoading(true);
      await dispatchAPI('PATCH', {
        url: `orders/post-remark/${id}`,
        body
      });

      form.resetFields();
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  const readModal = (remark) => {
    setVisible(true);
    setWritter(
      `${remark.written_by.first_name} ${remark.written_by.last_name}`
    );
    form.setFieldsValue({
      ...remark,
      title: remark.title,
      description: remark.description
    });
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: t('orders.form.table_title'),
      dataIndex: 'title',
      key: 'title',
      width: '25%'
    },
    {
      title: t('orders.form.table_description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: t('orders.form.written_by'),
      dataIndex: 'written_by',
      key: 'written_by',
      width: '25%',
      render: (written_by) => `${written_by.first_name} ${written_by.last_name}`
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: '10%',
      render: (record) => (
        <Button
          onClick={() => {
            readModal(record);
            setIsPurposePost(false);
          }}
          type="link"
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Button>
      )
    }
  ];

  return (
    <>
      <Card title={t('orders.form.remarks')} className="formation-card">
        {!!orderData?.length && (
          <Table dataSource={orderData} columns={columns} loading={isLoading} />
        )}
        <Button
          onClick={() => {
            setVisible(!visible);
            setIsPurposePost(true);
          }}
          type="primary"
        >
          <PlusOutlined /> {t('orders.form.add_remark')}
        </Button>
      </Card>
      <Modal
        visible={visible}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        maskClosable={false}
        width={800}
        open={visible}
        footer={null}
        title={
          isPurposePost
            ? t('orders.form.add_remark')
            : `${t('orders.form.remark_written_by')} ${writter}`
        }
      >
        <Form form={form} onFinish={postRemark}>
          <Form.Item
            name="title"
            label={t('orders.form.title')}
            labelCol={{ span: 24 }}
          >
            <Input disabled={!isPurposePost} />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('orders.form.description')}
            labelCol={{ span: 24 }}
          >
            <Input style={{ height: 120 }} disabled={!isPurposePost} />
          </Form.Item>

          <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {isPurposePost ? (
              <>
                <Button type="link" onClick={handleCloseModal}>
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit" onClick={handleCloseModal}>
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </>
            ) : (
              <Button onClick={handleCloseModal}>
                <CloseOutlined /> {`${t('buttons.close')}`}
              </Button>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

ShowOrderRemarks.propTypes = {
  orderData: PropTypes.shape({
    length: PropTypes.number
  }),
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.bool
};

ShowOrderRemarks.defaultProps = {
  orderData: null,
  isLoading: false,
  setIsLoading: false
};
