import PropTypes from 'prop-types';
import { Dropdown } from 'antd';

const HeaderDropdown = ({ children, overlayMenu }) => (
  <Dropdown overlay={overlayMenu}>{children}</Dropdown>
);

HeaderDropdown.propTypes = {
  overlayMenu: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func])
    .isRequired
};

export default HeaderDropdown;
