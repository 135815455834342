import { Checkbox } from 'antd';

export const useNewsletterFields = () => [
  {
    name: ['first_name'],
    rules: [{ required: true }]
  },
  {
    name: ['last_name'],
    rules: [{ required: true }]
  },
  {
    name: ['email'],
    rules: [{ required: true }]
  },
  {
    name: ['subscribed'],
    rules: [{ required: true }],
    valuePropName: 'checked',
    input: <Checkbox />
  }
];
