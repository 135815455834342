import { Badge, Trophy } from '@carbon/icons-react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const SubscriberList = (data) => {
  const {
    formation,
    last_name,
    first_name,
    date_of_birth,
    career,
    start_date,
    end_date,
    phone_number,
    opco,
    badgesList,
    email
  } = data;

  const { t } = useTranslation();

  return [
    {
      label: 'training_sessions.form.last_name',
      span: 1,
      content: last_name || `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.first_name',
      span: 1,
      content: first_name || `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.date_of_birth',
      span: 1,
      content:
        moment(date_of_birth).format('DD/MM/YYYY') ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.phone_number',
      span: 1,
      content:
        (phone_number &&
          `${phone_number.country_code}  ${phone_number.number}`) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.carreer',
      span: 1,
      content: career || `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.email',
      span: 1,
      content: email || `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.session_name',
      span: 3,
      content:
        (
          <Col>
            <Row> {`${formation?.name}`}</Row>
            <Row>
              {`Du ${moment(start_date).format('LL')} au ${moment(
                end_date
              ).format('LL')}`}
            </Row>
          </Col>
        ) || `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.opco',
      span: 2,
      content: opco?.title || `${t('errors.message.none')}`
    },
    {
      label: 'training_sessions.form.opco_amount',
      span: 1,
      content:
        (opco?.amount && `${opco?.amount} € T.T.C.`) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.badges_obtained',
      span: 1,
      content:
        ((badgesList || []).length &&
          (badgesList || []).map((el) =>
            el?.type === 'badge' ? (
              <Badge style={{ color: el.is_acquired && 'green' }} size={40} />
            ) : (
              <Trophy style={{ color: el.is_acquired && 'green' }} size={40} />
            )
          )) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'training_sessions.form.chosen_challenge',
      span: 1,
      content: `${t('errors.message.not_specified')}`
    }
  ];
};
