import {
  ContainerOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Button, Divider, List, Popconfirm, Col } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { useErrorMessage } from '../../../utils/ErrorMessage';

export const ListSurveys = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [surveys, setSurveys] = useState([]);
  const listTypes = [
    'pre_training_model',
    'quick_satisfaction_model',
    'intervenant_model',
    'chilled_satisfaction_model'
  ];

  const getSurveys = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/surveys' });
      setSurveys(data);
    } catch (e) {
      message(e.message);
    }
  };

  const deleteSurvey = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/surveys/${id}` });
      await getSurveys();
    } catch (e) {
      message(e.message);
    }
  };

  useEffect(() => {
    getSurveys();
  }, []);

  const actionColumn = (id) => [
    <>
      <Link
        to={{
          pathname: `./edit/${id}`
        }}
      >
        <EditOutlined style={{ fontSize: 18 }} />
      </Link>

      <Divider type="vertical" />
      <Popconfirm
        title={t('datatable.column.action.delete.title')}
        okText={t('datatable.column.action.delete.ok')}
        okButtonProps={{ type: 'danger' }}
        cancelText={t('datatable.column.action.delete.cancel')}
        onConfirm={() => deleteSurvey(id)}
        icon={<WarningOutlined />}
        placement="topRight"
      >
        <ContainerOutlined
          style={{ color: 'var(--errorColor)', fontSize: 18 }}
          type="delete"
        />
      </Popconfirm>
    </>
  ];

  return (
    <>
      <PageHeaderCustom title={t(`surveys.title`)} />
      {listTypes.map((type) => (
        <ContentCustom>
          <Divider orientation="left">{t(`surveys.list.${type}`)}</Divider>
          <Col xs={24} xl={20} xxl={16}>
            {!!surveys.filter((survey) => survey.type === type).length && (
              <List
                className="survey-list"
                dataSource={surveys.filter((survey) => survey.type === type)}
                renderItem={(survey) => (
                  <List.Item
                    actions={actionColumn(survey._id)}
                    key={survey._id}
                  >
                    {survey.title}
                  </List.Item>
                )}
              />
            )}
            {((type === 'intervenant_model' &&
              !surveys.filter((survey) => survey.type === type).length) ||
              type !== 'intervenant_model') && (
              <Button
                type="dashed"
                style={{ width: '100%' }}
                onClick={() =>
                  navigate(
                    `${routes.MODELS}${subRoutes.MODELS.SURVEYS}/create/${type}`
                  )
                }
              >
                {t('surveys.form.buttons.add')}
              </Button>
            )}
          </Col>
        </ContentCustom>
      ))}
    </>
  );
};
