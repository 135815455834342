import { Route, Routes } from 'react-router-dom';
import { CreateUpdateSurvey } from './CreateUpdateSurvey';
import { ListSurveys } from './ListSurveys';
import { SurveysContextProvider } from './SurveysContext';

export const SurveysRouter = () => (
  <SurveysContextProvider>
    <Routes>
      <Route
        path="/create/:type"
        element={<CreateUpdateSurvey purpose="create" />}
      />
      <Route path="/edit/:id" element={<CreateUpdateSurvey purpose="edit" />} />
      <Route index element={<ListSurveys />} />
    </Routes>
  </SurveysContextProvider>
);
