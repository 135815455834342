import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, message, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import SkillsTable from './skillsTable';
import { PageHeaderCustom, ContentCustom } from '../../../components';

const { Search } = Input;

const SkillsSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [skills, setSkills] = useState([]);
  const [newSkills] = useState({
    label: 'label ...',
    accounting_code: 'code ...',
    in_incomes: false,
    in_expenses: false
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);

  const searchResource = async () => {
    if (searchValue) {
      const { data } = await dispatchAPI('GET', {
        url: `/skills/search/${searchValue}`
      });
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${searchValue}`
      });
      setSkills(data);
    } else {
      const { data } = await dispatchAPI('GET', {
        url: `/skills`
      });
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
      setSkills(data);
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  useEffect(() => {
    searchResource();
  }, [searchValue]);

  const addSkills = async () => {
    try {
      await dispatchAPI('POST', { url: '/skills', body: newSkills });
    } catch (e) {
      message.error(e.message);
    }
    searchResource();
  };

  const deleteSkills = async (skill) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/skills/${skill._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.skills_delete'));
    }
    searchResource();
  };

  const editSkills = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/skills/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    searchResource();
  };

  return (
    <>
      <PageHeaderCustom title={t(`skills.title`)} />
      <ContentCustom>
        <Search
          allowClear
          placeholder={t('placeholder.search')}
          defaultValue={searchValue}
          onSearch={(value) => setSearchValue(value)}
          style={{ width: '300px', marginBottom: 24 }}
        />
        {skills && !!skills.length && (
          <SkillsTable
            skills={skills}
            editSkills={editSkills}
            deleteSkills={deleteSkills}
          />
        )}
        <Button
          type="dashed"
          style={{ width: '100%', marginTop: 10 }}
          onClick={addSkills}
        >
          {t('skills.add')}
        </Button>
      </ContentCustom>
    </>
  );
};

export default SkillsSettings;
