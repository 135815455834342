import { Route, Routes } from 'react-router-dom';
import { SessionsContextProvider } from '../Sessions/SessionsContext';
import { Discussions } from './Discussions';

export const DiscussionsRouter = () => (
  <SessionsContextProvider>
    <Routes>
      <Route path="/" element={<Discussions />} />
    </Routes>
  </SessionsContextProvider>
);
