import { UserOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tag } from 'antd';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { sessionsStatus } from '../../../utils/constants/tagColors';
import { useErrorMessage } from '../../../utils/ErrorMessage';

export const NextSessions = ({ formationFilter }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [nextSessions, setNextSessions] = useState();

  const getNextSessions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `sessions/contributor-next-sessions?populate=formation&status=PUBLISHED${
          formationFilter ? `&formation=${formationFilter}` : ''
        }`
      });
      setNextSessions(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getNextSessions();
  }, [formationFilter]);

  return (
    <Card title={<p>{t('home.titles.next_sessions')}</p>} className="home-card">
      {nextSessions ? (
        nextSessions.map((session) => (
          <Card key={session._id} className="dashboard-session-card">
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: 8 }}
            >
              <Col>
                {`${moment(session.start_date).format('DD-MM-YYYY')} - 
              ${moment(session.end_date).format('DD-MM-YYYY')}`}
              </Col>
              <Col>
                <Tag
                  color={sessionsStatus[session.status]}
                  className="status-tag"
                  style={{ marginRight: 0 }}
                >
                  {session.status}
                </Tag>
              </Col>
            </Row>
            <Row justify="space-between" align="middle">
              <Col>{session.formation.name}</Col>
              <Col>
                <UserOutlined style={{ marginRight: 6 }} />
                {`${session.subscribers.length} ${
                  session.subscribers.length <= 1
                    ? t('home.form.subscriber')
                    : t('home.form.subscribers')
                }`}
              </Col>
            </Row>
          </Card>
        ))
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {t('home.form.no_next_sessions')}
        </div>
      )}
    </Card>
  );
};

NextSessions.propTypes = {
  formationFilter: PropTypes.string
};

NextSessions.defaultProps = {
  formationFilter: undefined
};
