import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDiscussionsContext } from '../../contexts/DiscussionsContext';
import { DiscussionItem } from './DiscussionItem';

export const ChapterCommentaries = ({ chapterID }) => {
  const { forceRefresh, getChapterDiscussions, chapterDiscussions } =
    useDiscussionsContext();

  useEffect(() => {
    (async () => {
      await getChapterDiscussions(chapterID);
    })();
  }, [forceRefresh, chapterID]);

  return (
    <div className="chapter-commentaries-section">
      {(chapterDiscussions || []).map((discussion) => (
        <DiscussionItem discussion={discussion} chapterID={chapterID} />
      ))}
    </div>
  );
};

ChapterCommentaries.propTypes = {
  chapterID: PropTypes.string
};

ChapterCommentaries.defaultProps = {
  chapterID: undefined
};
