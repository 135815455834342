import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ListResource } from '../../components';
import { routes } from '../../utils/constants/adminRoutes';

export const Reports = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('reports.form.user_reported'),
      key: 'user_reported',
      dataIndex: 'user_reported',
      sorter: true,
      width: '20%',
      render: (user) => `${user.first_name} ${user.last_name}`
    },
    {
      title: t('reports.form.report_author'),
      key: 'report_author',
      dataIndex: 'report_author',
      sorter: true,
      width: '20%',
      render: (user) => `${user.first_name} ${user.last_name}`
    },
    {
      title: t('reports.form.date'),
      key: 'date',
      dataIndex: 'date',
      sorter: true,
      width: '20%',
      render: (date) => moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('reports.form.motive'),
      key: 'motive',
      dataIndex: 'motive',
      sorter: true,
      width: '20%',
      render: (motive) => t(`reports.form.${motive}`)
    },
    {
      render: (record) => (
        <Link
          to={{
            pathname: `${routes.REPORTS}/show/${record._id}`
          }}
        >
          <EyeOutlined />
        </Link>
      )
    }
  ];

  return (
    <ListResource
      resourceName="reports"
      columns={columns}
      populate="user_reported,report_author,item"
      resourceModelName="Report"
      scroll={{ x: 1400 }}
      customActionColumn
      withCreateButton={false}
    />
  );
};
