import { Collapse, Typography, Card, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ShowChapters } from './showChapters';

const { Title } = Typography;
const { Panel } = Collapse;

export const ShowModules = ({ modules }) => {
  const { t } = useTranslation();

  return (
    <Card title={t('master-classes.form.modules')} className="formation-card">
      <Collapse className="module-collapse">
        {modules &&
          modules.map((module) => (
            <Panel key={module._id} header={module.title}>
              <Col>
                <Title level={5}>{t('module.form.title')}</Title>
                <span>{module.title}</span>
              </Col>
              <Col>
                <Title level={5}>{t('module.form.description')}</Title>
                <span>{module.description || '-'}</span>
              </Col>
              <ShowChapters chapters={module.chapters} />
            </Panel>
          ))}
      </Collapse>
    </Card>
  );
};

ShowModules.propTypes = {
  modules: PropTypes.arrayOf({}).isRequired
};
