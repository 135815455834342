import { useEffect, useState } from 'react';
import { CloudOutlined, SyncOutlined } from '@ant-design/icons';
import { Badge, Button, Modal, Table, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { pendingTasksCat } from '../../utils/constants/tagColors';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const OfflineTray = () => {
  const { t } = useTranslation();
  const { pendingTasksTray, setPendingTasksTray, dispatchAPI } =
    useAuthContext();
  const { message } = useErrorMessage();
  const [online, setOnline] = useState(window.navigator.onLine);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (online && pendingTasksTray && pendingTasksTray.length) {
      setIsLoading(true);
      (async () => {
        await Promise.all(
          pendingTasksTray.map(async ({ method, url, body }) => {
            try {
              await dispatchAPI(method, { url, body });
              return true;
            } catch (e) {
              message(e);
              return false;
            }
          })
        );
        setPendingTasksTray([]);
        setIsLoading(false);
      })();
    }
  }, [online]);

  useEffect(() => {
    const handleChangeNetworkStatus = () => {
      setOnline(window.navigator.onLine);
      if (!window.navigator.onLine) {
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 5000);
      }
    };
    window.addEventListener('online', handleChangeNetworkStatus);
    window.addEventListener('offline', handleChangeNetworkStatus);
    return () => {
      window.removeEventListener('online', handleChangeNetworkStatus);
      window.removeEventListener('offline', handleChangeNetworkStatus);
    };
  }, []);

  const getResourceName = (url) => {
    let resource = url;
    if (resource.startsWith('/')) {
      resource = resource.replace('/', '');
    }
    if (resource.split('/').length > 1) {
      resource = resource.split('/')[0];
      return t(`${resource}.title`);
    }
    if (resource.split('?').length > 1) {
      resource = resource.split('/')[0];
      return t(`${resource}.title`);
    }
    return t(`${resource}.title`);
  };

  return (
    <div style={{ position: 'absolute', bottom: 48, right: 16 }}>
      <Modal
        visible={modalVisible}
        title={t('offlineTray.modal.title')}
        footer={false}
        onCancel={() => setModalVisible(false)}
      >
        <Table
          dataSource={(pendingTasksTray || []).map((task) => ({
            ...task,
            key: `${task.method}${task.url}`
          }))}
          pagination={{
            hideOnSinglePage: true
          }}
          columns={[
            {
              title: t('offlineTray.table.resource'),
              dataIndex: 'url',
              render: (u) => getResourceName(u)
            },
            {
              title: t('offlineTray.table.method'),
              dataIndex: 'method',
              render: (m) => (
                <Tag color={pendingTasksCat[m]}>
                  {t(`offlineTray.table.methods.${m}`)}
                </Tag>
              )
            },
            {
              key: 'syncing',
              render: () => <SyncOutlined spin={isLoading} />
            }
          ]}
        />
      </Modal>
      {(!online || !!(pendingTasksTray || []).length) && (
        <Tooltip
          visible={tooltipVisible}
          title={t('offlineTray.tooltip')}
          placement="topRight"
        >
          <Badge
            offset={[0, 5]}
            count={
              isLoading ? (
                <SyncOutlined spin />
              ) : (
                (pendingTasksTray || []).length
              )
            }
          >
            <Button
              type="link"
              icon={
                <CloudOutlined
                  style={{
                    color: online ? 'var(--addColor)' : 'var(--secondaryColor)',
                    fontSize: 24
                  }}
                />
              }
              onClick={() => setModalVisible(true)}
            />
          </Badge>
        </Tooltip>
      )}
    </div>
  );
};
