import { DocumentAttachment } from '@carbon/icons-react';
import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Card, Form, notification, Row, Upload } from 'antd';
import { FileOutlined, SendOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useDiscussionsContext } from '../../contexts/DiscussionsContext';

const { Dragger } = Upload;

export const AnswerInbox = ({ discussionItem }) => {
  const {
    forceRefresh,
    setForceRefresh,
    purpose,
    setPurpose,
    setDiscussionItem,
    editorFocus
  } = useDiscussionsContext();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [uploadZone, setUploadZone] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const reset = () => {
    form.resetFields();
    setFileList([]);
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleAttachmentButton = () => {
    setUploadZone(!uploadZone);
  };

  const handleOk = async (values) => {
    if (values.content) {
      try {
        // JSON.stringify on values.content allows to keep empty keys which are necessary to allow the module to work properly
        const JSONContent = JSON.stringify(values.content);
        const formData = new FormData();

        if (fileList.length) {
          fileList.forEach((file) => {
            formData.append('attachments', file);
          });
        }

        formData.append(
          'values',
          JSON.stringify({
            content: JSONContent,
            reply_to:
              purpose === 'new'
                ? null
                : [discussionItem?._id, ...(discussionItem.reply_to || [])],
            chapter:
              purpose === 'new'
                ? discussionItem?.chapter?._id
                : discussionItem?.chapter,
            module:
              purpose === 'new'
                ? discussionItem?.module?._id
                : discussionItem?.module,
            session:
              purpose === 'new'
                ? discussionItem?.session?._id
                : discussionItem?.session,
            recipients:
              purpose === 'new'
                ? (discussionItem?.session?.subscribers || []).map(
                    (subscriber) => subscriber?.user?._id
                  )
                : discussionItem?.recipients
          })
        );

        await dispatchAPI('POST', {
          url: '/commentaries',
          body: formData
        });
        setEditorState(() => EditorState.createEmpty());
        if (purpose === 'reply') {
          setPurpose('new');
          setDiscussionItem({
            session: {
              _id: discussionItem?.session
            },
            chapter: { _id: discussionItem?.chapter },
            module: { _id: discussionItem?.module }
          });
        }
        notification.success({ message: 'Commentaire envoyé' });
        reset();
        setForceRefresh(!forceRefresh);
      } catch (e) {
        message(e);
      }
    } else {
      notification.error({ message: 'Veuillez renseigner le message' });
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      handleOk(values);
    } catch (e) {
      if (e.errorFields && e.errorFields.length)
        form.scrollToField(e.errorFields[0].name);
    }
  };

  const draggerProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return true;
    },
    fileList
  };

  return (
    <Card className="answer-inbox-container">
      <Form
        form={form}
        labelCol={{ span: 5 }}
        layout="vertical"
        initialValues={{
          resource_path: 'other'
        }}
      >
        {uploadZone && (
          <Form.Item name={['attachments']}>
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <FileOutlined style={{ color: 'var(--textColor)' }} />
              </p>
              <p className="ant-upload-text">{t('files.create.action')}</p>
            </Dragger>
          </Form.Item>
        )}
        <Row ref={editorFocus}>
          <Form.Item name={['content']}>
            <Editor
              toolbar={{
                options: [
                  'inline',
                  'fontSize',
                  'fontFamily',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'emoji'
                ],
                inline: {
                  options: ['bold', 'italic', 'underline']
                },
                textAlign: { inDropdown: true }
              }}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbarStyle={{ border: 0 }}
              editorStyle={{ border: '1px solid rgb(190,190,190)' }}
              toolbarCustomButtons={[
                <Button
                  onClick={handleAttachmentButton}
                  type="link"
                  style={{ fontSize: 16 }}
                >
                  <DocumentAttachment />
                </Button>
              ]}
            />
          </Form.Item>
        </Row>
        {/* End of WYSIWYG SECTION */}
        <Row justify="end">
          <Form.Item>
            <Button
              htmlType="submit"
              type="add"
              shape="circle"
              onClick={handleSubmit}
            >
              <SendOutlined />
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Card>
  );
};

AnswerInbox.propTypes = {
  discussionItem: PropTypes.shape({
    session: PropTypes.string,
    module: PropTypes.string,
    chapter: PropTypes.string,
    discussion: PropTypes.string,
    recipients: PropTypes.shape([PropTypes.string]),
    isOriginalPost: PropTypes.bool,
    subDiscussion: PropTypes.string,
    _id: PropTypes.string,
    reply_to: PropTypes.shape([])
  })
};

AnswerInbox.defaultProps = {
  discussionItem: {
    subject: null,
    session: null,
    sender: {
      _id: null
    }
  }
};
