import { Button, Collapse, Form, Input, Row, Col } from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ContentCustom } from '../../../components';
import ChaptersForm from './ChaptersForm';
import useFields from './Fields';
import { useGenerateFormItem } from '../../../utils/GenerateFormItem';
import FinalExamIcon from '../../../assets/images/icons/final-exam_module.svg';

const { Panel } = Collapse;

export const ModulesList = ({
  previousStep,
  nextStep,
  step,
  form,
  purpose
}) => {
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const { finalExamFields } = useFields();

  return (
    <>
      <Form.List
        initialValue={purpose !== 'master-class' ? [{}, {}] : [{}]}
        name="modules"
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <Collapse accordion className="module-collapse">
              {fields.map((field, index) =>
                purpose === 'master-class' || index !== fields.length - 1 ? (
                  <Panel
                    header={
                      form.getFieldValue(['modules', field.name, 'title']) ||
                      `Module ${field.name + 1}`
                    }
                    key={`module_${field.name}`}
                    extra={
                      <Button
                        disabled={form.getFieldValue(['modules']).length === 1}
                        onClick={() => remove(field.name)}
                        type="link"
                      >
                        <CloseOutlined />
                      </Button>
                    }
                  >
                    <ContentCustom>
                      <Form.Item
                        {...field}
                        name={[field.name, 'title']}
                        label={t('module.form.title')}
                      >
                        <Input />
                      </Form.Item>
                    </ContentCustom>
                    <ChaptersForm
                      fieldName={field.name}
                      form={form}
                      purpose={purpose}
                    />
                  </Panel>
                ) : (
                  <Collapse accordion className="module-collapse">
                    <Panel
                      header={
                        <Row
                          align="middle"
                          gutter={10}
                          style={{ marginLeft: -16 }}
                        >
                          <Col>
                            <img
                              src={FinalExamIcon}
                              alt={t('formations.form.final_exam')}
                            />
                          </Col>
                          <Col>{t('formations.form.final_exam')}</Col>
                        </Row>
                      }
                      key="final_exam"
                    >
                      <ContentCustom>
                        {finalExamFields(
                          [field.name, 'chapters', 0],
                          form,
                          'final_exam'
                        ).map((el) => generateFields('final_exam', el))}
                      </ContentCustom>
                    </Panel>
                  </Collapse>
                )
              )}
            </Collapse>

            <Button
              type="dashed"
              style={{ width: '100%' }}
              onClick={() => add(undefined, fields.length + 1)}
            >
              {t('module.add')}
            </Button>
            <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
      {purpose !== 'master-class' && (
        <Row justify="space-between" style={{ marginTop: 10 }}>
          <Button
            type="link"
            onClick={() => previousStep(step)}
            className="formation-step-btn"
          >
            <ArrowLeftOutlined />
            {t('formations.form.step1')}
          </Button>
          <Button
            type="link"
            onClick={() => nextStep(step)}
            className="formation-step-btn"
          >
            {t('formations.form.step3')}
            <ArrowRightOutlined />
          </Button>
        </Row>
      )}
    </>
  );
};

ModulesList.propTypes = {
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  step: PropTypes.number,
  purpose: PropTypes.string,
  form: PropTypes.func.isRequired
};

ModulesList.defaultProps = {
  previousStep: null,
  nextStep: null,
  step: 1,
  purpose: 'formation'
};
