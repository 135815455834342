import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Collapse, Divider, Popconfirm } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  DeleteOutlined,
  DownloadOutlined,
  HighlightOutlined,
  LoadingOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import {
  ContentCustom,
  PageHeaderCustom,
  Datatable
} from '../../../components';

const { Panel } = Collapse;

const iconSize = 18;

const TemplateManager = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [usedTemplates, setUsedTemplates] = useState([]);
  const [isDownloading, setIsDownloading] = useState({});
  const [isSigning, setIsSigning] = useState({});

  const deleteFile = async (id) => {
    try {
      if (
        usedTemplates.length > 0 &&
        usedTemplates.find((template) => template.value === id)
      ) {
        return message('used_file');
      }

      await dispatchAPI('DELETE', { url: `/files/${id}` });
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return null;
  };

  const getSignURL = async (fileId) => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/sign/${fileId}` });

      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsSigning({ ...isSigning, [fileId]: false });
    return null;
  };

  const handleSignFile = async (fileId) => {
    const { redirectUrl } = await getSignURL(fileId);

    if (redirectUrl) {
      window.location.replace(redirectUrl);
    } else {
      message(500);
    }
  };

  const downloadFile = async (id, name, contentType) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: contentType
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const getTemplateSetting = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?unitType=TEMPLATE'
      });
      setUsedTemplates(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getTemplateSetting();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = () => [
    {
      title: t('documents.files.name'),
      key: 'name',
      render: (value) => value.metadata.originalName
    },
    {
      title: t('documents.files.content'),
      key: 'content',
      dataIndex: 'contentType'
    }
  ];

  const actionColumn = [
    {
      key: 'action',
      render: ({ _id, contentType, metadata }) => (
        <span>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={async () => {
              if (!isDownloading[_id]) {
                setIsDownloading({ ...isDownloading, [_id]: true });
                await downloadFile(_id, metadata.originalName, contentType);
              }
            }}
          >
            {isDownloading[_id] ? <LoadingOutlined /> : <DownloadOutlined />}
          </Button>
          {contentType === 'application/pdf' && user.docusign_token && (
            <>
              <Divider type="vertical" />
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={async () => {
                  if (!isSigning[_id]) {
                    setIsSigning({ ...isSigning, [_id]: true });
                    await handleSignFile(_id);
                  }
                }}
              >
                {isSigning[_id] ? (
                  <LoadingOutlined />
                ) : (
                  <HighlightOutlined style={{ fontSize: iconSize }} />
                )}
              </Button>
            </>
          )}
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            icon={<WarningOutlined />}
            onConfirm={async () => {
              setForceRefresh(false);
              await deleteFile(_id);
            }}
          >
            <DeleteOutlined
              style={{ color: 'red', fontSize: iconSize }}
              type="delete"
            />
          </Popconfirm>
        </span>
      )
    }
  ];

  const generateCollapse = () => {
    const listTypes = [
      'formation_certificate',
      'masterclass_certificate',
      'formation',
      'survey',
      'invoice',
      'quote'
    ];

    return (
      <Collapse>
        {listTypes.map((type) => (
          <Panel header={t(`documents.models.${type}`)} key={type}>
            <Datatable
              resourceName="files"
              columns={[...columns(), ...actionColumn]}
              extraQuery={`type=${type}`}
              customActionColumn
              forceRefresh={forceRefresh}
            />
          </Panel>
        ))}
      </Collapse>
    );
  };

  return (
    <>
      <PageHeaderCustom title={t(`documents.title`)} />
      <ContentCustom isLoading={isLoading}>
        <Row justify="end" gutter={[8, 16]}>
          <Col>
            <Row align="middle">
              <Link to={`${pathname}/create`}>
                <Button type="add">
                  {`${t('buttons.create')} `}
                  <PlusOutlined />
                </Button>
              </Link>
            </Row>
          </Col>
        </Row>
        {generateCollapse()}
      </ContentCustom>
    </>
  );
};

export default TemplateManager;
