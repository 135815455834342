import { Button, Col, Row } from 'antd';
import { MailOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../utils/constants/adminRoutes';
import { ListResource } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useNewsletterColumns } from './columns';

export const Newsletter = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const columns = useNewsletterColumns();
  const [emails, setEmails] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const getSubscribersEmails = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `newsletteremails/subscribersemails?${
          selectedUsers.length >= 1
            ? `_id=${selectedUsers}`
            : 'subscribed = true'
        }`
      });
      setEmails(data);
    } catch (e) {
      message(e);
    }
  };

  const handlePasteEmails = () => {
    navigator.clipboard.writeText(emails);
  };

  useEffect(() => {
    (async () => {
      await getSubscribersEmails();
    })();
  }, [selectedUsers]);

  const extraHeaderButtons = (
    <Row>
      <Col style={{ margin: '1rem' }}>
        <Button onClick={handlePasteEmails}>
          <MailOutlined />
          {t('newsletteremails.form.paste_emails')}
        </Button>
      </Col>
      <Col style={{ margin: '1rem' }}>
        <Link to={{ pathname: `${routes.NEWSLETTER}/create` }}>
          <Button>
            <PlusOutlined />
            {t('buttons.create')}
          </Button>
        </Link>
      </Col>
    </Row>
  );

  const headers = [
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'email',
      key: 'statemailus'
    },
    {
      label: 'subscribed',
      key: 'subscribed'
    }
  ];

  return (
    <ListResource
      resourceName="newsletteremails"
      headers={headers}
      searchAddRow={false}
      columns={columns}
      extraHeader={extraHeaderButtons}
      showAction={false}
      resourceModelName="Newsletteremail"
      scroll={{ x: 1200 }}
      withUploadButton={false}
      rowSelection={{
        type: 'checkbox',
        onChange: (selectedRowKeys) => {
          setSelectedUsers(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
          disabled: record.disabled,
          name: record.name
        })
      }}
    />
  );
};
