import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';

export const RemarkModal = ({
  handleRemarkModal,
  visible,
  report,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();

  const postRemark = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `reports/remark/${report?._id}`,
        body
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  return (
    <Modal
      visible={visible}
      onOk={handleRemarkModal}
      onCancel={handleRemarkModal}
      maskClosable={false}
      width={800}
      open={visible}
      footer={null}
      title={t('reports.form.new_remark')}
    >
      <Form form={form} onFinish={postRemark}>
        <Form.Item
          name="content"
          label={t('orders.form.description')}
          labelCol={{ span: 24 }}
        >
          <Input style={{ height: 120 }} />
        </Form.Item>
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <>
            <Button type="link" onClick={handleRemarkModal}>
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="add" htmlType="submit" onClick={handleRemarkModal}>
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </>
        </Row>
      </Form>
    </Modal>
  );
};

RemarkModal.propTypes = {
  handleRemarkModal: PropTypes.func,
  visible: PropTypes.bool,
  report: PropTypes.shape({
    _id: PropTypes.string,
    notes: PropTypes.shape({
      author: PropTypes.string,
      content: PropTypes.string
    })
  }),
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.bool
};

RemarkModal.defaultProps = {
  handleRemarkModal: null,
  visible: false,
  report: null,
  forceRefresh: false,
  setForceRefresh: false
};
