import { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { outOfNavRoutes } from '../../utils/constants/adminRoutes';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const Confirmation = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [id, setId] = useState(null);
  const { message } = useErrorMessage();

  const resendConfirmationEmail = async () => {
    setIsLoading(true);
    try {
      await dispatchAPI('POST', { url: `email-confirmation/resend/${id}` });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  const confirmEmail = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatchAPI('POST', { url: `/email-confirmation/${token}` });
      setConfirmed(true);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status_code === 498)
        setId(e.response.data.description.id);
    }
    setIsLoading(false);
  }, [token]);

  useEffect(() => {
    if (token) {
      (async () => {
        await confirmEmail();
      })();
    }
  }, [confirmEmail, token]);

  return isLoading ? (
    <Spin
      spinning
      size="large"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: '-16px',
        marginTop: '-17px'
      }}
    />
  ) : (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: '-150px',
        marginTop: '-150px',
        width: 300,
        height: 300,
        textAlign: 'center'
      }}
    >
      <Typography.Title level={4}>Email confirmation</Typography.Title>
      {confirmed ? (
        <div>
          <Typography.Text>{t('emailConfirmation.confirmed')}</Typography.Text>
          <div style={{ marginTop: 8 }}>
            <Link to={outOfNavRoutes.LOGIN}>
              <Button style={{ marginTop: 8 }} type="primary">
                {t('buttons.login')}
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <Typography.Text>{t('emailConfirmation.error')}</Typography.Text>
          {id && (
            <Button
              style={{ marginTop: 8 }}
              type="primary"
              onClick={resendConfirmationEmail}
            >
              {t('buttons.resendEmail')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
