import PropTypes from 'prop-types';
import { Row, Col, Card, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckmarkOutline } from '@carbon/icons-react';
import Vimeo from '@u-wave/react-vimeo';
import { listContent } from './listContent';
import { DescriptionList } from '../../../../components';

const ChapterInfo = ({ dataToModal }) => {
  const { t } = useTranslation();

  const dataToShow = (type) => {
    switch (type) {
      case 'QUIZ':
        return (
          <div>
            {dataToModal &&
              !!dataToModal.quiz?.questions?.length &&
              dataToModal.quiz.questions.map((question) => (
                <Card key={question._id} className="chapter-card" size="small">
                  <Row>
                    <Col span={20}>
                      <div className="card-title">{question.title}</div>
                    </Col>
                    <Col span={4}>
                      <Row justify="end">
                        <Col>{question.question_mark} points</Col>
                      </Row>
                    </Col>
                  </Row>
                  <div>
                    {!!question.length &&
                      question.answer_options?.map((options) => (
                        <Card
                          key={options._id}
                          size="small"
                          className="chapter-card"
                        >
                          <Row>
                            <Col span={22}>
                              <div className="card-answer">{options.title}</div>
                            </Col>
                            <Col span={2} style={{ textAlign: 'left' }}>
                              {options.is_right ? (
                                <CheckmarkOutline size={20} />
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                        </Card>
                      ))}
                  </div>
                </Card>
              ))}
          </div>
        );
      case 'VIDEO':
        return <Vimeo video={dataToModal.video} />;
      case 'TEXT':
        return dataToModal.text;
      case 'CHALLENGE_CHOICE':
        return (
          <div>
            {dataToModal &&
              !!dataToModal.challenges?.length &&
              dataToModal.challenges.map((challenge) => (
                <Card
                  title={`Challenge ${challenge.title}`}
                  className="challenge-card"
                  key={challenge._id}
                >
                  <text>
                    {challenge.description ? challenge.description : '-'}
                  </text>
                  {challenge.image && (
                    <Avatar shape="square" src={challenge.image} size={120} />
                  )}
                </Card>
              ))}
          </div>
        );
      case 'CHALLENGE_SLIDE':
        return (
          <a
            href={dataToModal.link_google_slide}
            target="_blank"
            rel="noopener noreferrer"
          >
            {dataToModal.link_google_slide}
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <DescriptionList data={listContent(dataToModal || {}, t)} translate />
      {dataToModal && (
        <div className="modal-body">
          {dataToModal.text_before && (
            <div className="modal-text-before">{dataToModal.text_before}</div>
          )}
          {dataToShow(dataToModal.type)}
          {dataToModal.text_after && (
            <div className="modal-body-after">{dataToModal.text_after}</div>
          )}
        </div>
      )}
    </>
  );
};

ChapterInfo.propTypes = {
  dataToModal: PropTypes.element.isRequired
};

export default ChapterInfo;
