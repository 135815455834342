import { Routes, Route } from 'react-router-dom';
import Masterclasses from './Masterclasses';
import { CreateUpdateMasterClass } from './CreateUpdateMasterClass';
import { ShowMasterClass } from './ShowMasterClass';

const MasterclassRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateMasterClass purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateMasterClass purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowMasterClass />} />
    <Route index element={<Masterclasses />} />
  </Routes>
);

export default MasterclassRouter;
