import { Tag } from 'antd';
import { t } from 'i18next';
import moment from 'moment/moment';
import { reportsStatus } from '../../../utils/constants/tagColors';

export const useReportList = (data = {}) => {
  const { user_reported, report_author, status, date, motive, item } = data;

  return [
    {
      label: t('reports.form.user_reported'),
      span: 1,
      content: user_reported
        ? `${user_reported?.first_name} ${user_reported?.last_name}`
        : '-'
    },
    {
      label: t('reports.form.report_author'),
      span: 1,
      content: report_author
        ? `${report_author?.first_name} ${report_author?.last_name}`
        : '-'
    },
    {
      label: t('reports.form.status'),
      span: 1,
      content: status ? (
        <Tag color={reportsStatus[status]}>{t(`reports.form.${status}`)}</Tag>
      ) : (
        '-'
      )
    },
    {
      label: t('reports.form.date'),
      span: 1,
      content: date ? moment(date).format('DD/MM/YYYY') : '-'
    },
    {
      label: t('reports.form.hour'),
      span: 1,
      content: date ? moment(date).format('LT') : '-'
    },
    {
      label: t('reports.form.motive'),
      span: 3,
      content: motive ? t(`reports.form.${motive}`) : '-'
    },
    {
      label: t('reports.form.type'),
      span: 2,
      content: item ? t(`reports.form.${item?.ref}`) : '-'
    }
  ];
};
