import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { ChallengeCard } from './ChallengeCard';

export const ChallengesForm = ({ fieldName, chapterType }) => {
  const listChallenges = ['', '', ''];

  return (
    <div>
      <Row gutter={[24, 24]} style={{ minWidth: '70vw' }}>
        {listChallenges.map((_, index) => (
          <Col xs={24} lg={8} xxl={6}>
            <ChallengeCard
              fieldName={[...fieldName, index]}
              chapterType={chapterType}
              challengeIndex={index + 1}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

ChallengesForm.propTypes = {
  fieldName: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  chapterType: PropTypes.string.isRequired
};
