import { Badge, Button, Col, PageHeader, Row, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useDiscussionsContext } from '../../contexts/DiscussionsContext';
import { useSessionsContext } from '../Sessions/SessionsContext';
import { AnswerInbox } from './AnswerInbox';
import { DiscussionHeader } from './DiscussionHeader';
import { ChapterCommentaries } from './ChapterCommentaries';
import { SessionButton } from './SessionButton';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';

export const Discussions = () => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const {
    commentaries,
    setChapterDiscussions,
    discussionItem,
    setDiscussionItem,
    sessionStatus,
    chaptersToDisplay,
    setChaptersToDisplay,
    sessionEnums,
    isLoading
  } = useDiscussionsContext();
  const { userSessions } = useSessionsContext();
  const [chapterID, setChapterID] = useState();

  const isChapterBadgeDisplayed = (e, item) =>
    !e?.read_by?.includes(user._id) &&
    e?.sender?._id !== user._id &&
    e.chapter === item.chapter._id;

  const customStatusCondition = (session) => {
    switch (sessionStatus) {
      case 'ALL':
        return sessionEnums.status.includes(session.status);
      default:
        return session?.status === sessionStatus;
    }
  };

  const sessionsToShow = userSessions?.filter((session) =>
    commentaries?.some(
      (commentary) =>
        commentary.session._id === session._id && customStatusCondition(session)
    )
  );

  const handleDiscussions = (session) => {
    if (!chaptersToDisplay) {
      const chapters = [];
      session.formation.modules.filter((sessionModule) =>
        sessionModule.chapters.some((chapter) =>
          commentaries.some((com) =>
            chapter._id === com.chapter
              ? chapters.push({ chapter, sessionModule, session })
              : null
          )
        )
      );
      if (commentaries.some((com) => !com.chapter && !com.module)) {
        chapters.push({
          chapter: {
            title: t('discussions.form.main_discussion')
          },
          session
        });
      }
      setChaptersToDisplay(chapters);
    } else {
      setDiscussionItem();
      setChaptersToDisplay();
      setChapterDiscussions();
    }
  };

  const handleCommentaries = (item) => {
    if (discussionItem) {
      setDiscussionItem();
      setChapterID();
    } else {
      setChapterID(item.chapter._id);
      setDiscussionItem(item);
    }
  };

  useEffect(() => {
    setDiscussionItem();
    setChapterID();
    setChaptersToDisplay();
  }, []);

  return (
    <>
      <PageHeader title={t('discussions.title.discussions')} />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <DiscussionHeader />
          <Row className="inbox-wrapper discussion-wrapper">
            <Col
              style={{ display: 'flex', flexDirection: 'column' }}
              xs={24}
              md={6}
              xl={4}
              className="sessions-column"
            >
              {(sessionsToShow || []).map((session) => (
                <SessionButton
                  session={session}
                  handleDiscussions={handleDiscussions}
                  key={session._id}
                />
              ))}
            </Col>
            {chaptersToDisplay && (
              <Col
                style={{ display: 'flex', flexDirection: 'column' }}
                xs={24}
                md={6}
                xl={4}
                className="trainees-column"
              >
                {(chaptersToDisplay || []).map((item) => (
                  <Button
                    key={item.chapter._id}
                    onClick={() => handleCommentaries(item)}
                    type="link"
                    className="inbox-trainee-btn"
                  >
                    <Row>
                      <Col span={3}>
                        {commentaries.some((e) =>
                          isChapterBadgeDisplayed(e, item)
                        ) && <Badge status="warning" />}
                      </Col>
                      <Col
                        span={21}
                        style={{
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          justifyContent: 'center'
                        }}
                      >
                        <h4>{item?.chapter?.title}</h4>
                        <h5>{item?.sessionModule?.title}</h5>
                      </Col>
                    </Row>
                  </Button>
                ))}
              </Col>
            )}

            {discussionItem && (
              <Col xs={24} md={10} xl={16}>
                <AnswerInbox discussionItem={discussionItem} />
                <ChapterCommentaries chapterID={chapterID} />
              </Col>
            )}
          </Row>
        </Skeleton>
      </ContentCustom>
    </>
  );
};
