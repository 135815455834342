import {
  CheckSquareOutlined,
  DeleteOutlined,
  EyeOutlined,
  LockOutlined,
  UnlockOutlined
} from '@ant-design/icons';
import { Button, Rate, Tag } from 'antd';
import { t } from 'i18next';
import { reviewsStatus } from '../../../utils/constants/tagColors';

export const reviewsList = (patchReview, handleModal) => [
  {
    title: t('master-classes.form.user'),
    key: 'user',
    dataIndex: 'user',
    width: '10%',
    render: (user) => `${user?.first_name} ${user?.last_name}`
  },
  {
    title: t('master-classes.form.review'),
    key: 'comment',
    dataIndex: 'comment',
    width: 'auto'
  },
  {
    title: t('master-classes.form.note'),
    key: 'note',
    dataIndex: 'note',
    width: '15%',
    render: (note) => <Rate value={note} disabled />
  },
  {
    title: t('master-classes.form.status'),
    key: 'status',
    dataIndex: 'status',
    width: '10%',
    render: (status) => (
      <Tag color={reviewsStatus[status]}>
        {t(`master-classes.form.${status}`)}
      </Tag>
    )
  },
  {
    title: '',
    key: 'action',
    width: '15%',
    render: (review) =>
      review && (
        <>
          {review.status === 'PENDING_VALIDATION' && (
            <Button
              type="link"
              onClick={() => patchReview(review._id, 'AVAILABLE')}
            >
              <CheckSquareOutlined style={{ fontSize: 18 }} />
            </Button>
          )}
          <Button type="link" onClick={() => handleModal(review)}>
            <EyeOutlined style={{ fontSize: 18 }} />
          </Button>
          {review.status !== 'BLOCKED' && review.status !== 'ARCHIVED' && (
            <Button
              type="link"
              onClick={() => patchReview(review._id, 'BLOCKED')}
            >
              <LockOutlined style={{ fontSize: 18, color: 'red' }} />
            </Button>
          )}
          {review.status === 'BLOCKED' && (
            <Button
              type="link"
              danger
              onClick={() => patchReview(review?._id, 'AVAILABLE')}
            >
              <UnlockOutlined style={{ fontSize: 18 }} />
            </Button>
          )}
          {review.status === 'BLOCKED' && (
            <Button
              type="link"
              danger
              onClick={() => patchReview(review?._id, 'ARCHIVED')}
            >
              <DeleteOutlined style={{ fontSize: 18 }} />
            </Button>
          )}
        </>
      )
  }
];
