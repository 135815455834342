import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Badge, Button, Layout, Menu } from 'antd';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';
import { outOfNavRoutes } from '../../../utils/constants/adminRoutes';
import { Notifications } from '../../../routes/Notifications/Notifications';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { Header } = Layout;

const StyledHeader = styled.div`
  background: var(--clientColor);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  padding: 0;
  z-index: 8;
  width: 100%;
  position: fixed;
`;

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-items: center;
`;

const StyledDropdownContainer = styled.span`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0 12px;
  transition: all 0.3s;
  :hover {
    background: var(--contrastBackground);
  }
  .ant-btn-link {
    height: fit-content;
  }
`;

const StyledFullNameSpan = styled.span`
  color: var(--textColor);
  font-weight: 600;
`;

const StyledRoleP = styled.p`
  font-size: 10px;
  color: var(--textColorSecondary);
  margin-bottom: 0;
`;

const TriggerCollapse = styled.div`
  color: var(--textColor);
  font-size: 20px;
  padding: 0 24px;
  cursor: pointer;
  margin-left: ${(props) => props.marginleft};
  z-index: 10000;
  :hover {
    color: var(--primaryColor);
  }
`;

const HeaderLayout = ({
  collapseSider,
  collapsed,
  collapseWidth,
  themeLogo
}) => {
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [marginTrigger, setMarginTrigger] = useState('0px');
  const { message } = useErrorMessage();
  const [notifications, setNotifications] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);

  const getNotifications = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `notifications?item.ref!=Commentary&populate=item.type`
      });
      setNotifications(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getNotifications();
    })();
  }, [forceRefresh]);

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      <>
        {window.innerWidth <= 992 && user && (
          <Menu.Item key="user">
            <Button
              style={{
                width: '100%',
                height: 50
              }}
              type="link"
            >
              <StyledFullNameSpan>
                {`${user.first_name} ${user.last_name}`}
              </StyledFullNameSpan>
              <StyledRoleP>
                {t(`users.tags.${user.role.split(':')[1]}`)}
              </StyledRoleP>
            </Button>
          </Menu.Item>
        )}
        {['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user?.role) &&
          notifications.length > 0 && (
            <Menu.Item key="notifications">
              <Badge
                count={notifications?.length}
                status="warning"
                style={{ marginBottom: 3 }}
              />
              <Notifications
                notifications={notifications}
                forceRefresh={forceRefresh}
                setForceRefresh={setForceRefresh}
              />
            </Menu.Item>
          )}

        <Menu.Item key="profile">
          <Link to={{ pathname: `${outOfNavRoutes.PROFILE}` }}>
            <UserOutlined
              style={{
                fontSize: 16,
                marginRight: 4
              }}
            />
            {` ${t('header.menu.user.profile')}`}
          </Link>
        </Menu.Item>
        <Menu.Item key="logout">
          <LogoutOutlined
            style={{
              fontSize: 16,
              marginRight: 4
            }}
          />
          {` ${t('header.menu.user.logout')}`}
        </Menu.Item>
      </>
    </Menu>
  );

  useEffect(() => {
    if (collapsed) {
      if (collapseWidth === 0) {
        setMarginTrigger('0px');
      } else {
        setMarginTrigger('80px');
      }
    } else {
      setMarginTrigger('256px');
    }
  }, [collapsed, collapseWidth]);

  return (
    <StyledHeader as={Header}>
      {window.innerWidth < 992 && (
        <TriggerCollapse
          as={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
          marginleft={marginTrigger}
          onClick={() => collapseSider(!collapsed)}
        />
      )}
      {themeLogo && (
        <img
          style={{
            width: 100,
            maxHeight: 50,
            position: 'absolute',
            top: 10,
            left: '50%',
            marginLeft: '-50px'
          }}
          src={themeLogo}
          alt="Company logo"
        />
      )}
      <StyledContainer>
        <HeaderDropdown overlayMenu={profileMenu}>
          {window.innerWidth <= 992 ? (
            <StyledDropdownContainer>
              <UserOutlined />
            </StyledDropdownContainer>
          ) : (
            <StyledDropdownContainer>
              <Avatar
                size="medium"
                icon={user?.photo ? '' : <UserOutlined />}
                src={user?.photo ? user.photo : ''}
              >
                {`${user?.first_name[0]}${user?.last_name[0]}`}
              </Avatar>
              <Button type="link">
                <StyledFullNameSpan>
                  {`${user?.first_name} ${user?.last_name}`}
                </StyledFullNameSpan>
                <StyledRoleP>
                  {t(`users.tags.${user?.role.split(':')[1]}`)}
                </StyledRoleP>
              </Button>
            </StyledDropdownContainer>
          )}
        </HeaderDropdown>
      </StyledContainer>
    </StyledHeader>
  );
};

HeaderLayout.propTypes = {
  collapseSider: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  collapseWidth: PropTypes.number.isRequired,
  themeLogo: PropTypes.string
};

HeaderLayout.defaultProps = {
  themeLogo: null
};

export default HeaderLayout;
