import { FileOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, notification, Select, Upload } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DocumentAttachment } from '@carbon/icons-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useSocketContext } from '../../contexts/SocketContext';
import { useSessionsContext } from '../Sessions/SessionsContext';
import { useMessagesContext } from '../../contexts/MessagesContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const { Dragger } = Upload;

export const NewMessageModal = () => {
  const { userSessions, setTraineesFilter } = useSessionsContext();
  const { message } = useErrorMessage();
  const { socket } = useSocketContext();
  const {
    users,
    setSessionsFilter,
    sessionsFilter,
    forceRefresh,
    setForceRefresh
  } = useMessagesContext();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadZone, setUploadZone] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const showModal = () => {
    setVisible(!visible);
  };

  const handleCancel = () => {
    setVisible(!visible);
  };

  const reset = () => {
    setConfirmLoading(false);
    form.resetFields();
    setFileList([]);
  };

  const handleAttachmentButton = () => {
    setUploadZone(!uploadZone);
  };

  const handleOk = async (values) => {
    try {
      setConfirmLoading(true);
      // JSON.stringify on values.content allows to keep empty keys which are necessary to allow the module to work properly
      const JSONContent = JSON.stringify(values.content);

      const formData = new FormData();

      if (fileList.length) {
        fileList.forEach((file) => {
          formData.append('attachments', file);
        });
      }

      formData.append(
        'values',
        JSON.stringify({
          content: JSONContent,
          thread: values.thread,
          session: values.session,
          recipient: values.recipient
        })
      );

      await dispatchAPI('POST', {
        url: '/messages/modal',
        body: formData
      });
      if (socket) {
        socket.emit('newMessage', { recipient: values.recipient });
      }
      notification.success({ message: 'Message envoyé.' });
      setEditorState(() => EditorState.createEmpty());
      reset();
      setVisible(false);
      setConfirmLoading(false);
      setForceRefresh(!forceRefresh);
    } catch (e) {
      setConfirmLoading(false);
      message(e);
    }
  };

  const draggerProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return true;
    },
    fileList
  };

  return (
    <>
      <Button type="add" onClick={showModal}>
        <PlusOutlined />
        {`${t('buttons.create')}`}
        &nbsp;
      </Button>
      <Modal
        title={t('inbox.modal.new_message')}
        visible={visible}
        okText={t('inbox.modal.send')}
        confirmLoading={confirmLoading}
        className="message-modal"
        onOk={async () => {
          try {
            const values = await form.validateFields();
            handleOk(values);
          } catch (e) {
            if (e.errorFields && e.errorFields.length)
              form.scrollToField(e.errorFields[0].name);
          }
        }}
        onCancel={handleCancel}
        bodyStyle={{ padding: 20, maxHeight: 640, overflow: 'scroll' }}
        width={1000}
      >
        <Form
          form={form}
          labelCol={{ span: 5 }}
          layout="vertical"
          initialValues={{
            resource_path: 'other'
          }}
        >
          <Form.Item
            label={t('inbox.modal.select_formation_session')}
            rules={[{ required: true }]}
            name={['session']}
          >
            <Select
              style={{ width: 400, maxWidth: '100%' }}
              onSelect={(value) => setSessionsFilter(value)}
              allowClear
            >
              {userSessions?.map(
                (session) =>
                  session.status !== 'PENDING' && (
                    <Select.Option value={session._id} key={session._id}>
                      {`${session?.formation?.name} - Du ${moment(
                        session?.start_date
                      ).format('DD-MM-YYYY')} au ${moment(
                        session?.end_date
                      ).format('DD-MM-YYYY')}`}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('inbox.modal.select_trainee')}
            rules={[{ required: true }]}
            name={['recipient']}
          >
            <Select
              style={{ width: 400, maxWidth: '100%' }}
              onSelect={(value) => setTraineesFilter(value)}
              allowClear
              disabled={!sessionsFilter}
            >
              {(users || []).map((trainee) => (
                <Select.Option value={trainee?.user?._id} key={trainee._id}>
                  {sessionsFilter
                    ? `${trainee?.user?.first_name} ${trainee?.user?.last_name}`
                    : `${trainee.first_name} ${trainee.last_name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('inbox.modal.subject')} name={['thread']}>
            <Input style={{ width: 400, maxWidth: '100%' }} />
          </Form.Item>

          {uploadZone && (
            <Form.Item name={['attachments']}>
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <FileOutlined style={{ color: 'var(--textColor)' }} />
                </p>
                <p className="ant-upload-text">{t('files.create.action')}</p>
              </Dragger>
            </Form.Item>
          )}
          <Form.Item
            label={t('inbox.modal.your_message')}
            rules={[{ required: true }]}
            name={['content']}
          >
            <Editor
              toolbar={{
                options: [
                  'inline',
                  'fontSize',
                  'fontFamily',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'emoji'
                ],
                inline: {
                  options: ['bold', 'italic', 'underline']
                },
                textAlign: { inDropdown: true }
              }}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbarStyle={{ border: 0 }}
              editorStyle={{ border: '1px solid rgb(190,190,190)' }}
              toolbarCustomButtons={[
                <Button onClick={handleAttachmentButton} type="link">
                  <DocumentAttachment />
                </Button>
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
