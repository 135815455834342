import { EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Divider, Row, Skeleton, Tabs } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { EvaluationProcess } from './EvaluationProcess';
import { HomeworksDeposits } from './List/HomeworksDepositsList';
import { SubscriberList } from './List/SubscriberShowList';
import { SubscriberSurveyAnswers } from './SubscriberSurveyAnswers';

const { TabPane } = Tabs;

export const ShowSubscriber = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const [sessionData, setSessionData] = useState([]);
  const [subscriberData, setSubscriberData] = useState([]);
  const [opco, setOpco] = useState();
  const { dispatchAPI, user } = useAuthContext();
  const [badges, setBadges] = useState([]);
  const { sessionID, subscriberID } = useParams();

  const getSubscriberData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `users/${subscriberID}`
      });
      setSubscriberData(data);
    } catch (e) {
      message(e);
    }
  };

  const getBadgesList = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `formations/${sessionData?.formation?._id}/badges/${subscriberID}`
      });
      setBadges(data);
    } catch (e) {
      message(e);
    }
  };

  const getSubscriber = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${sessionID}?populate=formation,contributor,subscribers.user`
      });
      setSessionData(data);
    } catch (e) {
      message(e);
    }
  };

  const getUserOpco = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/subscriber-data/${sessionID}/${subscriberID}`
      });
      setOpco(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getSubscriberData();
    getSubscriber();
    getUserOpco();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (sessionData.formation) {
      getBadgesList();
    }
  }, [sessionData]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('training_sessions.form.learner')} - ${
          subscriberData?.first_name
        } ${subscriberData?.last_name}`}
        extra={
          user.role === 'users:CONTRIBUTOR' && (
            <Button type="primary">
              {`${t('buttons.send_message')} `}
              <EditOutlined />
            </Button>
          )
        }
      />
      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Col xs={24} md={6} xl={4} xxl={3}>
            <Skeleton
              loading={isLoading}
              title={0}
              paragraph={0}
              avatar={{ size: 64 }}
              active
            >
              <Avatar
                size={120}
                src={
                  subscriberData && subscriberData?.photo
                    ? subscriberData?.photo
                    : ``
                }
              />
            </Skeleton>
          </Col>
          <Col xs={24} md={18} xl={20} xxl={21}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                data={SubscriberList(
                  {
                    ...sessionData,
                    ...subscriberData,
                    ...opco,
                    badgesList: badges
                  } || {},
                  t
                )}
                translate
              />
            </Skeleton>
          </Col>
        </Row>
        <Divider />
        <Tabs defaultActiveKey="1" className="user-tabs">
          {['admins:ADMIN', 'admins:SUPER-ADMIN'].includes(user.role) && (
            <TabPane
              tab={t('training_sessions.tabs.evaluation_process')}
              key="1"
            >
              <EvaluationProcess />
            </TabPane>
          )}
          <TabPane tab={t('training_sessions.tabs.quizz_answers')} key="2">
            <SubscriberSurveyAnswers formation={sessionData?.formation} />
          </TabPane>
          <TabPane tab={t('training_sessions.tabs.dropped_files')} key="3">
            <HomeworksDeposits sessionData={sessionData} />
          </TabPane>
        </Tabs>
      </ContentCustom>
    </>
  );
};
