import { useCallback, useState, useEffect } from 'react';
import { Select, Upload, Input, Radio, Form, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { PictureOutlined } from '@ant-design/icons/lib';
import { useAuthContext } from '../../../contexts/AuthContext';
import TextModal from './TextModal';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ChallengesForm } from './ChallengesForm';
import { useFormationsContext } from './FormationsContext';
import { EditQuizModal } from './EditQuizModal';

const { Dragger } = Upload;
const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [skills, setSkills] = useState([]);
  const [categories, setCategories] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [enums, setEnums] = useState({});
  const [contributors, setContributors] = useState();
  const { images } = useFormationsContext();

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const getCategories = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'categories' });
      setCategories(data);
    } catch (e) {
      message(e.message);
    }
  };

  const onSelectCategory = (value) => {
    const list = [...selectedCategory];
    list.push(value);
    setSelectedCategory(list);
  };

  const onDeselectCategory = (value) => {
    setSelectedCategory(selectedCategory.filter((sk) => sk !== value));
  };

  const getSkills = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'skills' });
      setSkills(data);
    } catch (e) {
      message(e.message);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/formations/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getContributors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?role=users:CONTRIBUTOR'
      });
      setContributors(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getContributors();
      await getCategories();
      await getSkills();
      await getEnums();
    })();
  }, []);

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    ...(['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user?.role)
      ? [
          {
            name: ['trainer'],
            rules: [{ required: true }],
            input: (
              <Select>
                {(contributors || []).map((contributor) => (
                  <Select.Option key={contributor._id} value={contributor._id}>
                    {`${contributor.first_name} ${contributor.last_name}`}
                  </Select.Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['categories'],
      input: (
        <Select
          loading={isFieldsLoading}
          style={{ width: '100%', textAlign: 'left', borderRadius: 5 }}
          onSelect={onSelectCategory}
          value={categories}
          options={categories.map((categorie) => ({
            label: categorie.name,
            value: categorie._id
          }))}
          mode="multiple"
          onDeselect={onDeselectCategory}
        />
      ),
      rules: [{ required: true }]
    },
    {
      name: ['number_of_hours'],
      rules: [{ required: true }]
    },
    {
      name: ['cpf'],
      input: (
        <Radio.Group>
          <Radio value>{t('formations.form.yes')}</Radio>
          <Radio value={false}>{t('formations.form.no')}</Radio>
        </Radio.Group>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['number_max_learner'],
      rules: [{ required: true }]
    },
    {
      name: ['description'],
      input: <Input.TextArea />
    },
    {
      name: ['image'],
      input: (
        <Dragger {...draggerProps}>
          {images?.image ? (
            <img
              src={images.image}
              alt="imageFormation"
              style={{ width: '60%' }}
            />
          ) : (
            <>
              <p className="ant-upload-drag-icon">
                <PictureOutlined style={{ color: 'var(--textColor)' }} />
              </p>
              <p className="ant-upload-text">{t('files.create.action')}</p>
            </>
          )}
        </Dragger>
      )
    }
  ];

  const educational_objective = [
    {
      name: ['skills'],
      input: (
        <Input.Group compact>
          <Form.Item name={['skills']}>
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="children"
              /*   filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                } */
              loading={isFieldsLoading}
              style={{ width: 380, textAlign: 'left', borderRadius: 5 }}
              options={skills.map((skill) => ({
                label: skill.name,
                value: skill._id
              }))}
            />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  const leanerProfileFields = [
    {
      name: ['learner_profile', 'learner_prerequisite'],
      input: <Input.TextArea />
    },
    {
      name: ['learner_profile', 'learner_public'],
      input: <Input.TextArea />
    }
  ];

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getCategories();
    await getSkills();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const moduleFields = [
    {
      label: 'title',
      name: ['modules', 'title'],
      input: <Input />
    }
  ];

  const finalExamFields = (fieldName, form, purpose) => [
    {
      noLabel: true,
      name: ['edit'],
      input: (
        <EditQuizModal fieldName={fieldName} form={form} purpose={purpose} />
      )
    },
    {
      noLabel: true,
      name: ['text_before'],
      input: <TextModal purpose="text_before" fieldName={fieldName} />
    },
    {
      noLabel: true,
      name: ['text_after'],
      input: <TextModal purpose="text_after" fieldName={fieldName} />
    }
  ];

  const homeworkDepositsFields = (fieldName) => [
    {
      label: t('chapter.form.deadline_deposits'),
      name: ['deadline_deposits'],
      input: (
        <Select>
          {(enums?.deadline_deposits || []).map((deadline) => (
            <Option key={deadline} value={deadline}>
              {t(`chapter.tags.${deadline}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: t('chapter.form.badge'),
      name: ['badge'],
      input: <Checkbox />
    },
    {
      noLabel: true,
      name: ['text_before'],
      input: <TextModal purpose="text_before" fieldName={fieldName} />
    },
    {
      noLabel: true,
      name: ['text_after'],
      input: <TextModal purpose="text_after" fieldName={fieldName} />
    }
  ];

  const quizzFields = (fieldName, _, form, moduleIndex) => [
    {
      label: t('chapter.form.badge'),
      name: ['badge'],
      input: <Checkbox />
    },
    {
      noLabel: true,
      name: ['editModel'],
      input: (
        <EditQuizModal
          fieldName={fieldName}
          form={form}
          moduleIndex={moduleIndex}
        />
      )
    },
    {
      noLabel: true,
      name: ['text_before'],
      input: <TextModal purpose="text_before" fieldName={fieldName} />
    },
    {
      noLabel: true,
      name: ['text_after'],
      input: <TextModal purpose="text_after" fieldName={fieldName} />
    }
  ];

  const textFields = (fieldName) => [
    {
      noLabel: true,
      name: ['text'],
      input: <TextModal purpose="text" fieldName={fieldName} />
    }
  ];

  const videoFields = (_, purpose) => [
    {
      label: t('chapter.form.description'),
      name: ['description'],
      input: <Input.TextArea />
    },
    ...(purpose === 'master-class'
      ? [
          {
            label: t('chapter.form.duration'),
            name: ['duration'],
            input: (
              <Input
                type="number"
                addonAfter={t('master-classes.form.minutes')}
              />
            )
          }
        ]
      : []),
    {
      label: t('chapter.form.video'),
      name: ['video'],
      input: <Input />
    }
  ];

  const challengeSlideFields = (fieldName) => [
    {
      label: 'link_google_slide',
      name: ['link_google_slide'],
      input: <Input />
    },
    {
      noLabel: true,
      name: ['text_before'],
      input: <TextModal purpose="text_before" fieldName={fieldName} />
    },
    {
      noLabel: true,
      name: ['text_after'],
      input: <TextModal purpose="text_after" fieldName={fieldName} />
    }
  ];

  const challengesFields = (fieldName, chapterType) => [
    {
      label: t('chapter.form.video'),
      name: ['video'],
      input: <Input />
    },
    {
      noLabel: true,
      name: ['challenges'],
      input: (
        <ChallengesForm
          fieldName={[...fieldName, 'challenges']}
          chapterType={chapterType}
        />
      )
    }
  ];

  return {
    fields,
    isFieldsLoading,
    leanerProfileFields,
    educational_objective,
    moduleFields,
    finalExamFields,
    homeworkDepositsFields,
    quizzFields,
    textFields,
    videoFields,
    challengeSlideFields,
    challengesFields,
    fileList
  };
};

export default useFields;
