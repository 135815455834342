import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Input, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import moment from 'moment';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const UserNotes = ({ user, forceRefresh, setForceRefresh }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [visible, setVisible] = useState();
  const [form] = Form.useForm();

  const handleCloseModal = () => {
    setVisible(!visible);
  };

  const postNote = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `users/add_note/${user._id}`,
        body
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      <Card
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{t('users.form.notes')}</div>
            <Button onClick={() => setVisible(!visible)}>
              <PlusOutlined />
              {t('users.form.add_note')}
            </Button>
          </div>
        }
      >
        {(user.notes || []).map((note) => (
          <Card>
            <Row style={{ display: 'flex', gap: '1rem' }}>
              <Col>{`${note.author.first_name} ${note.author.last_name}`}</Col>
              <Col>{moment(note.date).format('DD/MM/YYYY')}</Col>
            </Row>
            <Row>{note.content}</Row>
          </Card>
        ))}
      </Card>
      <Modal
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        maskClosable={false}
        width="70%"
        visible={visible}
        footer={null}
        className="session-modal"
        title={t('users.form.add_note')}
      >
        <Form onFinish={postNote} form={form}>
          <Form.Item name={['content']}>
            <Input />
          </Form.Item>
          <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <>
              <Button type="link" onClick={handleCloseModal}>
                {`${t('buttons.cancel')} `}
                <CloseOutlined />
              </Button>
              <Button type="add" htmlType="submit" onClick={handleCloseModal}>
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

UserNotes.propTypes = {
  user: PropTypes.shape({
    notes: PropTypes.shape({}),
    _id: PropTypes.string
  }),
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.bool
};

UserNotes.defaultProps = {
  user: null,
  forceRefresh: false,
  setForceRefresh: false
};
