import { Button, Card, Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useFormationsContext } from './FormationsContext';
import { ContentCustom } from '../../../components';
import { ModalEditEvaluation } from './ModalEditEvaluation';

const { Option } = Select;

export const EvaluationsList = ({ form }) => {
  const { t } = useTranslation();
  const { previousStep, step, formation, setFormation, surveys } =
    useFormationsContext();
  const quizzesTypes = [
    'pre_training',
    'quick_satisfaction',
    'chilled_satisfaction'
  ];

  const handleChangeQuiz = (type) => {
    const temp = { ...formation };
    temp.quizzes[type] = undefined;
    setFormation(temp);
  };

  const onSelectQuiz = (value, type) => {
    const temp = { ...formation };
    temp.quizzes[type] = surveys.find((el) => el._id === value);
    setFormation(temp);
  };

  return (
    <>
      <ContentCustom>
        {quizzesTypes &&
          quizzesTypes.length &&
          quizzesTypes.map((type) => {
            const evaluation = formation && formation.quizzes[type];
            const models = surveys.filter((el) => el.type === `${type}_model`);
            return (
              <Card
                className="formation-card"
                key={type}
                title={t(`formations.form.${type}`)}
              >
                <Row justify="space_between">
                  <Col span={20}>
                    {evaluation && evaluation.title}
                    {!evaluation && !!models.length && (
                      <Select onSelect={(value) => onSelectQuiz(value, type)}>
                        {models.map((model) => (
                          <Option value={model._id} key={model._id}>
                            {model.title}
                          </Option>
                        ))}
                      </Select>
                    )}
                    {!evaluation &&
                      !models.length &&
                      t('formations.form.no_evaluation_model')}
                  </Col>
                  <Col>
                    {evaluation && (
                      <>
                        <ModalEditEvaluation
                          form={form}
                          fieldName={['quizzes', type]}
                        />
                        <Button
                          onClick={() => handleChangeQuiz(type)}
                          type="link"
                          icon={<CloseOutlined />}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </Card>
            );
          })}
      </ContentCustom>
      <Row justify="start" style={{ marginTop: 10 }}>
        <Button
          type="link"
          onClick={() => previousStep(step)}
          className="formation-step-btn"
        >
          <ArrowLeftOutlined />
          {t('formations.form.step2')}
        </Button>
      </Row>
    </>
  );
};

EvaluationsList.propTypes = {
  form: PropTypes.shape({}).isRequired
};
