import { Routes, Route } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/adminRoutes';
import Formations from './Formations/Formations';
import ListSkillsProfile from './SkillsProfile/ListSkillsProfile';
import ShowFormation from './Formations/ShowFormation';
import { CreateUpdateFormation } from './Formations/CreateUpdateFormation';
import { FormationsContextProvider } from './Formations/FormationsContext';
import { ShowSkillsProfile } from './SkillsProfile/ShowSkillsProfile';
import { CreateUpdateSkillsProfile } from './SkillsProfile/CreateUpdateSkillsProfile';
import MasterclassRouter from './Masterclasses/MasterclassRouter';

const CatalogRouter = () => (
  <FormationsContextProvider>
    <Routes>
      <Route
        path={`${subRoutes.CATALOG.MASTERCLASSES}/*`}
        element={<MasterclassRouter />}
      />
      <Route
        path={`${subRoutes.CATALOG.FORMATIONS}`}
        element={<Formations />}
      />
      <Route
        path={`${subRoutes.CATALOG.FORMATIONS}/show/:id`}
        element={<ShowFormation />}
      />
      <Route
        path={`${subRoutes.CATALOG.FORMATIONS}/create`}
        element={<CreateUpdateFormation purpose="create" />}
      />
      <Route
        path={`${subRoutes.CATALOG.FORMATIONS}/edit/:id`}
        element={<CreateUpdateFormation purpose="edit" />}
      />
      <Route
        path={`${subRoutes.CATALOG.SKILLS_PROFILES}`}
        element={<ListSkillsProfile />}
      />
      <Route
        path={`${subRoutes.CATALOG.SKILLS_PROFILES}/show/:id`}
        element={<ShowSkillsProfile />}
      />
      <Route
        path={`${subRoutes.CATALOG.SKILLS_PROFILES}/edit/:id`}
        element={<CreateUpdateSkillsProfile purpose="edit" />}
      />
      <Route
        path={`${subRoutes.CATALOG.SKILLS_PROFILES}/create`}
        element={<CreateUpdateSkillsProfile purpose="create" />}
      />
    </Routes>
  </FormationsContextProvider>
);

export default CatalogRouter;
