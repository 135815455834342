import { Card, Col, Row, Skeleton, Timeline } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const StatusHistory = ({ orderData, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Card title={t('orders.form.history')} className="formation-card">
      <Skeleton active loading={isLoading} paragraph={{ rows: 3 }}>
        <Timeline>
          {orderData?.status_history.map((item) => (
            <Timeline.Item>
              <Row justify="space-between">
                <Col xs={16}>
                  {item.title === 'NEED_HANDLING'
                    ? t('orders.form.order_transmitted')
                    : `${t('orders.form.noticed_as')} ${t(
                        `orders.status.${item.title}`
                      )} ${t('orders.form.by')} ${
                        item?.modified_by?.first_name
                      } ${item?.modified_by?.last_name} `}
                </Col>
                <Col xs={7} offset={1}>
                  <Row>
                    {moment(
                      orderData && orderData?.status_history[1]?.date
                    ).format('l')}
                  </Row>
                  <Row>
                    {moment(orderData?.status_history[1]?.date).format('LT')}
                  </Row>
                </Col>
              </Row>
            </Timeline.Item>
          ))}
        </Timeline>
      </Skeleton>
    </Card>
  );
};

StatusHistory.propTypes = {
  orderData: PropTypes.shape({
    status_history: PropTypes.shape([
      {
        date: PropTypes.string,
        modified_by: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string
        })
      }
    ]),
    date: PropTypes.string,
    current_status: PropTypes.string
  }),
  isLoading: PropTypes.bool
};

StatusHistory.defaultProps = {
  orderData: null,
  isLoading: false
};
