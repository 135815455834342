import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tag } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { Datatable } from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { ordersStatus } from '../../utils/constants/tagColors';

const ListOrders = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('orders.form.number'),
      key: 'number',
      dataIndex: 'number'
    },
    {
      title: t('orders.form.status'),
      key: 'current_status',
      dataIndex: 'current_status',
      render: (status) => (
        <Tag color={ordersStatus[status]}>{t(`orders.status.${status}`)}</Tag>
      )
    },
    {
      title: t('orders.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('orders.form.totalCost'),
      key: 'total_cost',
      dataIndex: 'total_cost',
      render: (total_cost) => `${total_cost} €`
    },
    {
      title: t('orders.form.adress'),
      key: 'billing_adress',
      dataIndex: 'billing_adress',
      render: (address) =>
        `${address.street} ${address.postal_code} ${address.city}`
    },
    {
      render: (record) => (
        <Link
          to={{
            pathname: `${routes.ORDERS}/show/${record._id}`
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];

  return (
    <Datatable
      resourceName="orders"
      columns={columns}
      customActionColumn
      extraQuery={`customer=${id}`}
      resourceModelName="Order"
      editAction={false}
      deleteAction={false}
      populate="articles"
      onDoubleClickAction={{
        action: (record) => navigate(`${routes.ORDERS}/show/${record._id}`)
      }}
    />
  );
};

export default ListOrders;
