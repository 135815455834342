import { Switch, Tag } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const newsList = (data, refresh, setRefresh) => {
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { name, categories, updated_at } = data;
  const { t } = useTranslation();

  const updateSkillProfile = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `skills_profile/${id}`,
        body: { ...data, visibility: !data.visibility }
      });
      setRefresh(!refresh);
    } catch (e) {
      message(e);
    }
  };

  const switchVisiblity = async () => {
    try {
      await updateSkillProfile();
    } catch (e) {
      message(e);
    }
  };

  return [
    {
      label: 'skills_profile.show.name',
      span: 2,
      content: name || `${t('errors.message.not_specified')}`
    },
    {
      label: 'skills_profile.show.categories',
      span: 1,
      content:
        (categories &&
          !!categories.length &&
          categories.map((tag) => (
            <span>
              <Tag
                key={tag._id}
                className="course-category"
                style={{ color: tag.color }}
              >
                {tag.name}
              </Tag>
            </span>
          ))) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'skills_profile.show.visibility',
      span: 2,
      content:
        (
          <Switch
            checked={data && data.visibility}
            onChange={switchVisiblity}
          />
        ) || `${t('errors.message.not_specified')}`
    },
    {
      label: 'skills_profile.show.last_modif',
      span: 1,
      content:
        (updated_at && moment(updated_at).format('DD/MM/YYYY')) ||
        `${t('errors.message.not_specified')}`
    }
  ];
};

export default newsList;
