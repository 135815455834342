import { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Tag } from 'antd';
import {
  EditOutlined,
  ContainerOutlined,
  WarningOutlined
} from '@ant-design/icons';
import {
  Bullhorn,
  Add,
  Download,
  Checkmark,
  Locked,
  Unlocked
} from '@carbon/icons-react';
import moment from 'moment/moment';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { AddEditSessionModal } from '../../../routes/Sessions/AddEditSessionModal';
import { useSessionsContext } from '../../../routes/Sessions/SessionsContext';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';

const ShowCourseHeader = ({ course, courseType }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { visible, setVisible } = useSessionsContext();
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const deleteCourse = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/${courseType}/${id}`
      });
    } catch (e) {
      message(e);
    }
  };

  const updateCourseStatus = async (status) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/${courseType}/patch-status/${id}`,
        body: {
          ...course,
          status,
          ...(course?.status === 'NOT_PUBLISHED'
            ? { publication_date: moment() }
            : {})
        }
      });
    } catch (e) {
      message(e);
    }
  };

  const handleUpdateStatus = async (status) => {
    await updateCourseStatus(status);
    navigate(0);
  };

  const handleCloseModal = () => {
    setVisible(false);
    setEnd(null);
    setStart(null);
  };

  const parseHeader = (admin) => {
    if (admin) {
      if (course?.status === 'WAITING_VALIDATION') {
        return (
          <Button
            type="primary"
            onClick={() => handleUpdateStatus('PUBLISHED')}
          >
            <Checkmark />
            {t('buttons.validate')}
          </Button>
        );
      }
      if (course?.status === 'PUBLISHED') {
        return (
          <Button
            type="primary"
            onClick={() => handleUpdateStatus('NOT_PUBLISHED')}
          >
            <Locked />
            {t('buttons.unpublish')}
          </Button>
        );
      }
      if (course?.status === 'CLOSED') {
        return (
          <Button
            type="primary"
            onClick={() => handleUpdateStatus('PUBLISHED')}
          >
            <Unlocked />
            {t('buttons.publish')}
          </Button>
        );
      }
      if (course?.status === 'NOT_PUBLISHED') {
        return (
          <Button
            type="primary"
            onClick={() => handleUpdateStatus('PUBLISHED')}
          >
            <Unlocked />
            {t('buttons.publish')}
          </Button>
        );
      }
      return null;
    }
    if (course?.status === 'DRAFT') {
      return (
        <Button
          type="primary"
          onClick={() => handleUpdateStatus('WAITING_VALIDATION')}
        >
          <Bullhorn />
          {t('buttons.ask_for_validation')}
        </Button>
      );
    }
    return null;
  };

  const deleteButton = () => {
    if (course?.status === 'WAITING_VALIDATION' || course?.status === 'DRAFT') {
      return (
        <Popconfirm
          title={t('datatable.column.action.archive.title')}
          okText={t('datatable.column.action.archive.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.archive.cancel')}
          onConfirm={deleteCourse}
          icon={<WarningOutlined />}
        >
          <Button type="link">
            <ContainerOutlined />
            {`${t('buttons.delete')} `}
          </Button>
        </Popconfirm>
      );
    }
    if (user.role.split(':')[0] === 'admins') {
      return (
        <Popconfirm
          title={t('datatable.column.action.archive.title')}
          okText={t('datatable.column.action.archive.ok')}
          okButtonProps={{ type: 'primary' }}
          cancelText={t('datatable.column.action.archive.cancel')}
          onConfirm={() => handleUpdateStatus('ARCHIVED')}
          icon={<WarningOutlined />}
        >
          <Button type="link">
            <ContainerOutlined />
            {`${t('buttons.archive')} `}
          </Button>
        </Popconfirm>
      );
    }

    return null;
  };

  const editButton = () => {
    if (
      user?.role.split(':')[0] === 'admins' ||
      course?.status === 'DRAFT' ||
      course?.status === 'WAITING_VALIDATION'
    ) {
      return (
        <Link
          to={{
            pathname: `${routes.CATALOG}${
              courseType === 'formations'
                ? subRoutes.CATALOG.FORMATIONS
                : subRoutes.CATALOG.MASTERCLASSES
            }/edit/${id}`
          }}
        >
          <Button type="primary">
            <EditOutlined />
            {`${t('buttons.edit')} `}
          </Button>
        </Link>
      );
    }
    return null;
  };

  return course?.status !== 'ARCHIVED' ? (
    <>
      {courseType === 'formations' && (
        <>
          {course?.status === 'PUBLISHED' && (
            <Button type="primary" onClick={() => setVisible(true)}>
              <Add />
              {t('buttons.new_session')}
            </Button>
          )}
          <Button type="primary">
            <Download />
            {t('buttons.download_content')}
          </Button>
          <AddEditSessionModal
            handleCloseModal={handleCloseModal}
            visible={visible}
            setVisible={setVisible}
            sessionID={null}
            start={start}
            end={end}
            setStart={setStart}
            setEnd={setEnd}
            purpose="add"
          />
        </>
      )}
      {user.role.split(':')[0] === 'admins' ? (
        <>{parseHeader(true)}</>
      ) : (
        <>{parseHeader(false)}</>
      )}
      {editButton()}
      {deleteButton()}
    </>
  ) : (
    <Tag color="#727272">{t(`formations.tags.${course?.status}`)}</Tag>
  );
};

ShowCourseHeader.propTypes = {
  course: PropTypes.element.isRequired,
  courseType: PropTypes.string.isRequired
};

export default ShowCourseHeader;
