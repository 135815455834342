import { Avatar, Tag } from 'antd';

const newsList = (data, t) => {
  const {
    name,
    cpf,
    categories,
    trainer,
    number_of_hours,
    number_of_certified,
    description,
    image,
    note
  } = data;

  return [
    {
      label: 'formations.form.name',
      span: 2,
      content: name || `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.categories',
      span: 1,
      content:
        (categories &&
          !!categories.length &&
          categories.map((tag) => (
            <Tag
              key={tag._id}
              className="course-category"
              style={{ color: tag.color, marginBottom: 4 }}
            >
              {tag.name}
            </Tag>
          ))) ||
        `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.contributor',
      span: 1,
      content:
        trainer && trainer.first_name && trainer.last_name ? (
          <>
            {trainer.first_name} {trainer.last_name}
          </>
        ) : (
          `${t('errors.message.not_specified')}`
        )
    },
    {
      label: 'formations.form.notemoyenne',
      span: 1,
      content: (note && `${note}/10`) || `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.cpf',
      span: 1,
      content:
        t(`formations.form.${cpf}`) || `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.nunberOfHours',
      span: 1,
      content: number_of_hours || `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.numberOfCertified',
      span: 2,
      content: number_of_certified || `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.description',
      span: 3,
      content: description || `${t('errors.message.not_specified')}`
    },
    {
      label: 'formations.form.picture',
      span: 1,
      content:
        (image && <Avatar shape="square" src={image} size={120} />) ||
        `${t('errors.message.not_specified')}`
    }
  ];
};

export default newsList;
