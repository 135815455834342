import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Input, Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { ChoiceGridHeader } from './ChoiceGridHeader';
import { useSurveysContext } from './SurveysContext';

export const ChoiceGridTable = ({
  fieldName,
  extraCardButtons,
  moveQuestion,
  removeQuestion,
  form
}) => {
  const { t } = useTranslation();
  const { forceFormDataRefresh } = useSurveysContext();

  let formData;
  let colsLength;

  const renderRadio = () => {
    formData = form.getFieldsValue();

    colsLength =
      formData?.questions && formData?.questions.length > 0
        ? formData.questions[fieldName].answer_options.length
        : 0;

    const elements = [];
    for (let i = 0; i < colsLength; i += 1) {
      elements.push(i);
    }
    return elements.map((e) => <Radio key={e} disabled />);
  };

  useEffect(() => {
    (() => {
      renderRadio();
    })();
  }, [forceFormDataRefresh]);

  return (
    <Card
      className="question-card"
      style={{ marginBottom: 16 }}
      title={
        <Row justify="space-between" align="middle">
          <Col xs={24} lg={3} xxl={5}>
            <div>
              {extraCardButtons(moveQuestion, removeQuestion, fieldName)}
            </div>
          </Col>
          <Col xs={24} lg={21} xxl={19}>
            <Form.List name={[fieldName, 'answer_options']}>
              {(fields, { add, remove }) => (
                <Row justify="space-between" align="middle" gutter={[0, 8]}>
                  <Col xs={24} lg={6} xl={5}>
                    <Button
                      type="link"
                      onClick={() => add({ title: 'Nouvelle option' })}
                    >
                      <PlusOutlined />
                      {t('surveys.form.buttons.add_option')}
                    </Button>
                  </Col>
                  <Col xs={24} lg={18} xl={19}>
                    <Row align="middle" justify="end">
                      {fields.map(({ fieldKey, name, ...restField }) => (
                        <ChoiceGridHeader
                          fieldKey={fieldKey}
                          name={name}
                          remove={remove}
                          restField={restField}
                        />
                      ))}
                    </Row>
                  </Col>
                </Row>
              )}
            </Form.List>
          </Col>
        </Row>
      }
    >
      <Form.List name={[fieldName, 'questions_list']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map(({ fieldKey, name, ...restField }) => (
              <Row align="middle">
                <Col xs={24} lg={8} xxl={10}>
                  <Row align="middle" gutter={8}>
                    <Col xs={24} md={12} xl={16}>
                      <Form.Item
                        {...restField}
                        name={[name, 'title']}
                        fieldKey={[fieldKey]}
                      >
                        <Input
                          bordered={false}
                          suffix={
                            <Button
                              type="link"
                              icon={<EditOutlined />}
                              style={{ padding: 4 }}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} xl={8}>
                      {extraCardButtons(move, remove, fieldName, name)}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={16} xxl={14}>
                  <Radio.Group
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      width: '100%',
                      gap: '35%',
                      paddingLeft: '5%'
                    }}
                  >
                    {renderRadio()}
                  </Radio.Group>
                </Col>
              </Row>
            ))}
            <Row>
              <Col>
                <Button
                  type="link"
                  onClick={() => add({ title: 'Nouvelle question' })}
                >
                  <PlusOutlined />
                  {t('surveys.form.buttons.add_answer')}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </Card>
  );
};

ChoiceGridTable.propTypes = {
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func
  }),
  fieldName: PropTypes.string.isRequired,
  extraCardButtons: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  moveQuestion: PropTypes.func.isRequired
};

ChoiceGridTable.defaultProps = {
  form: null
};
