// Main nav menuItems
export const routes = {
  HOME: '/',
  TRAINING_SESSIONS: '/sessions',
  CATALOG: '/catalog',
  INBOX: '/inbox',
  DISCUSS: '/discussions'
};

// Main nav subMenuItems
export const subRoutes = {
  CATALOG: {
    YOUR_FORMATIONS: '/formations',
    YOUR_MASTERCLASSES: '/master-classes'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {};
